import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import merge from 'lodash/merge'
import { useCallback, useEffect, useRef } from 'react'

const defaultOptions = {
  duration: 1000,
  enable: true,
  timeout: {
    callback: () => {
      throw new Error('Timeout exceeded in useInterval')
    },
  },
}

const useInterval = (intervalFunction: any, options: any) => {
  const { duration, timeout, enable } = merge({}, defaultOptions, options)
  const { limit: timeoutLimit, callback: timeoutCallback } = timeout || {}

  const interval = useRef()
  const startTime = useRef()

  const intervalCallback = useCallback(() => {
    if (!timeoutLimit) {
      intervalFunction()

      return
    }

    const currentTime = new Date()
    // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    const secondsDifference = differenceInMilliseconds(currentTime, startTime.current)

    if (secondsDifference > timeoutLimit) {
      clearTimeout(interval.current)
      timeoutCallback()

      return
    }

    intervalFunction()
  }, [intervalFunction, timeoutCallback, timeoutLimit])

  useEffect(() => {
    if (!enable) return undefined

    // @ts-expect-error TS(2322) FIXME: Type 'Date' is not assignable to type 'undefined'.
    startTime.current = new Date()
    const newInterval = setInterval(intervalCallback, duration)
    // @ts-expect-error TS(2322) FIXME: Type 'Timer' is not assignable to type 'undefined'... Remove this comment to see the full error message
    interval.current = newInterval

    return () => clearInterval(newInterval)
  }, [duration, intervalCallback, enable])
}

export default useInterval
