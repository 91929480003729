import React from 'react'
import { Container } from 'react-bootstrap'

export interface Props {
  container?: boolean
  children: React.ReactNode
}

const PageWrapper = ({ children, container, ...otherProps }: Props) =>
  container ? (
    <Container as="main" {...otherProps}>
      {children}
    </Container>
  ) : (
    <main {...otherProps}>{children}</main>
  )

PageWrapper.defaultProps = {
  container: true,
}

const MemoizedPageWrapper = React.memo(PageWrapper)

export default MemoizedPageWrapper
