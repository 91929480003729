import Icon from '@nord/ui/src/components/Icon'
import Text from '@nord/ui/src/components/Text'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { valueToDate } from '@nord/ui/src/utilities/formatDate'
import { formatDistance } from 'date-fns'
import { da } from 'date-fns/locale'
import React from 'react'
import Button from 'react-bootstrap/Button'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Clock from 'react-feather/dist/icons/clock'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import PortfolioTitle from '../../../../components/PortfolioTitle'

type Props = {
  startPath: string
}

const SuitabilityUpdateContent = ({ startPath }: Props) => {
  // @ts-expect-error TS(2339) FIXME: Property 'suitabilityTestSucceededAt' does not exi... Remove this comment to see the full error message
  const { suitabilityTestSucceededAt } = useObjectSelector(selectCurrentPortfolio)

  const currentDate = new Date()
  const lastUpdatedDate = suitabilityTestSucceededAt && valueToDate(suitabilityTestSucceededAt)

  const lastUpdatedDistance = formatDistance(lastUpdatedDate, currentDate, {
    locale: da,
  })

  return (
    <>
      <Text as="h3" weight="bold" size="xxxl">
        Opdatering af din risikoprofil
      </Text>
      <PortfolioTitle />
      <Text as="p">
        Det er nu {lastUpdatedDistance} siden, at du sidst opdaterede dine oplysninger og at vi
        vurderede din risikoprofil. Vi sikrer os løbende, at din investering passer til dig og
        derfor har vi brug for, at du opdaterer dine oplysninger. Vi opfordrer dig til også selv at
        opdatere dine oplysninger, hvis der sker væsentlige ændringer i din økonomi, tidshorisont
        eller risikovillighed - ellers kontakter vi dig typisk hver 18. måned.
      </Text>
      <Text as="p" weight="bold" className="d-flex flex-align-items">
        <Icon size={1.5} icon={Clock} className="mr-2" />
        Det tager ca. 5 minutter - klik herunder for at starte.
      </Text>
      <div className="mt-2 mb-5">
        <Button as={Link} to={startPath}>
          Start
        </Button>
      </div>
    </>
  )
}

export default SuitabilityUpdateContent
