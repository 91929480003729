import styleConfiguration from '@nord/ui/src/configuration/styleConfiguration'
import React from 'react'
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill'
import { Pie, PieChart, Cell } from 'recharts'

const { blues } = styleConfiguration

type Props = {
  data: {
    value?: number
    category?: string
  }[]
}

const PortfolioAllocationChart = ({ data }: Props) => {
  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 100,
  })

  return (
    <div className="d-flex justify-content-center" ref={ref}>
      <PieChart width={width} height={width}>
        {/* @ts-expect-error TS(2741) FIXME: Property 'dataKey' is missing in type '{ children:... Remove this comment to see the full error message */}
        <Pie data={data} isAnimationActive={false} startAngle={-270}>
          {data.map((entry, index) => (
            <Cell fill={blues[(index + 1) * 100]} stroke={blues[(index + 1) * 100]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  )
}

export default PortfolioAllocationChart
