// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'domp... Remove this comment to see the full error message
import DOMPurify from 'dompurify'
import React from 'react'

export interface Props {
  as?: React.ReactElement
  children: string
}

const InnerHtml = ({ children, as: Component, ...otherProps }: Props) => {
  const innerHTML = { __html: DOMPurify.sanitize(children) }

  // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
  return <Component {...otherProps} dangerouslySetInnerHTML={innerHTML} />
}

InnerHtml.defaultProps = {
  as: 'div',
}

export default InnerHtml
