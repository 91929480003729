import { useCallback, useRef, useState } from 'react'

const useUncontrollableProp = (propValue: any, defaultValue = undefined, handler = undefined) => {
  const wasControlledRef = useRef(propValue !== undefined)
  const [stateValue, setStateValue] = useState(defaultValue)

  const isControlled = propValue !== undefined
  const wasControlled = wasControlledRef.current

  wasControlledRef.current = isControlled

  /**
   * If a prop switches from controlled to Uncontrolled
   * reset its value to the defaultValue
   */
  if (!isControlled && wasControlled && stateValue !== defaultValue) {
    setStateValue(defaultValue)
  }

  const currentValue = isControlled ? propValue : stateValue
  const handleChangeValue = useCallback(
    (value: any, ...args: any[]) => {
      // @ts-expect-error TS(2349) FIXME: This expression is not callable.
      if (handler) handler(value, ...args)
      setStateValue(value)
    },
    [handler],
  )

  return [currentValue, handleChangeValue, isControlled]
}

export default useUncontrollableProp
