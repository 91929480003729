const chainEventHandler =
  (...handlers: any[]) =>
  (event: any) =>
    handlers.reduce((previousEvent, handler) => {
      if (handler === undefined) return previousEvent

      if (previousEvent && previousEvent.defaultPrevented) return previousEvent

      const nextEvent = handler(previousEvent)
      if (nextEvent === undefined) return previousEvent

      return nextEvent
    }, event)

export default chainEventHandler
