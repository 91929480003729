import Item from './Item'
import List from './List'
import Wrapper from './Wrapper'

const Sortable = {
  Item,
  List,
  Wrapper,
}

export default Sortable
