import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { initialUserLoad } from '../../../../store/current/user'

const useInitialUserLoad = ({
  shouldAwaitInitialUserLoad,
  loadPortfolios,
  setPortfolioId,
}: any) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (shouldAwaitInitialUserLoad) return

    // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
    dispatch(initialUserLoad({ loadPortfolios, setPortfolioId }))
  }, [dispatch, loadPortfolios, setPortfolioId, shouldAwaitInitialUserLoad])
}

export default useInitialUserLoad
