import classNames from 'classnames'
import React from 'react'
import ReactSelect from 'react-select'

export interface Props {
  component?: React.ReactElement
  className?: string
  isValid?: boolean
  isInvalid?: boolean
}

const StyledReactSelect = ({
  className,
  component: Component,
  isValid,
  isInvalid,
  ...otherProps
}: Props) => (
  // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
  <Component
    className={classNames('custom-react-select', className, {
      'is-valid': isValid,
      'is-invalid': isInvalid,
    })}
    classNamePrefix="custom-react-select"
    {...otherProps}
  />
)

StyledReactSelect.defaultProps = {
  className: undefined,
  component: ReactSelect,
  isInvalid: undefined,
  isValid: undefined,
}

export default StyledReactSelect
