import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import styles from './NavigationContainer.module.scss'

export interface Props {
  children?: React.ReactNode
  className?: string
}

const NavigationContainer = ({ children, className }: Props) => (
  <Row>
    <Col xs={12}>
      <div className={classNames(styles.navigationContainer, className)}>{children}</div>
    </Col>
  </Row>
)

NavigationContainer.defaultProps = {
  children: undefined,
  className: undefined,
}

NavigationContainer.displayName = 'PaginatedSection.NavigationContainer'

export default NavigationContainer
