import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import styleConfiguration from '../../configuration/styleConfiguration'
import extractProps from '../../utilities/extractProps'
import Card from '../Card'

import LoadingIndicator from './LoadingIndicator'

const { breakpointKeys: colPropKeys } = styleConfiguration

export interface Props {
  children?: React.ReactNode
  loading?: boolean
}

const SectionContainer = ({ children, loading, ...otherProps }: Props) => {
  const [colProps, cardBodyProps] = extractProps(otherProps, colPropKeys)

  return (
    <Row>
      <Col
        xs={12}
        md={{ offset: 1, span: 10 }}
        lg={{ offset: 2, span: 8 }}
        xl={{ offset: 3, span: 6 }}
        className="px-2 px-sm-3"
        {...colProps}
      >
        <Card>
          <Card.Body data-cy="section-body" {...cardBodyProps}>
            {loading ? <LoadingIndicator /> : children}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

SectionContainer.defaultProps = {
  children: undefined,
  loading: undefined,
}

export default SectionContainer
