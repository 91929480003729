import axios from 'axios'

import { getConfig, environment } from '../configuration'
import {
  formatRequestData,
  formatResponseData,
  formatQueryParams,
} from '../hooks/useApi/formatData'

// If the request takes longer than 30 seconds, the request will be aborted. - Only in development enviroments.
const developmentEnvTimeout = 30000
// If the request takes longer than 35 seconds, the request will be aborted.
const defaultTimeout = 35000

const { isDevelopment } = environment

const timeout = isDevelopment ? developmentEnvTimeout : defaultTimeout

const backendDomain = getConfig('domains.backend')

const defaultOptions = {
  baseURL: backendDomain,
  timeout,
  method: 'GET',
  // @ts-expect-error TS(2488) FIXME: Type 'AxiosRequestTransformer | AxiosRequestTransf... Remove this comment to see the full error message
  transformRequest: [formatRequestData, ...axios.defaults.transformRequest],
  // @ts-expect-error TS(2488) FIXME: Type 'AxiosResponseTransformer | AxiosResponseTran... Remove this comment to see the full error message
  transformResponse: [...axios.defaults.transformResponse, formatResponseData],
  paramsSerializer: formatQueryParams,
}

export const enhanceApiClient = (apiClient: any) => {
  apiClient.interceptors.request.use((config: any) => {
    config.errorContext = new Error('Thrown at:')

    return config
  })

  apiClient.interceptors.response.use(null, async (error: any) => {
    const originalStackTrace = error.config?.errorContext?.stack

    if (originalStackTrace) {
      error.stack = `${error.stack}\n${originalStackTrace}`
    }

    throw error
  })
}

const getApiClient = (options = {}) => {
  const apiClient = axios.create({ ...defaultOptions, ...options })

  enhanceApiClient(apiClient)

  return apiClient
}

export default getApiClient
