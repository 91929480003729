import BooleanInput from '@nord/ui/src/components/BooleanInput'
import CurrencyInput from '@nord/ui/src/components/CurrencyInput'
import Form from '@nord/ui/src/components/Form'
import Transition from '@nord/ui/src/components/Transition'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'

const CarAssetField = () => {
  const {
    values: { debt, investableAssets },
  } = useFormikContext<any>()

  const [hasCarDebt, setHasCarDebt] = useState(false)

  const handleCheckedChange = (value: any) => setHasCarDebt(value)

  return (
    <Transition.Expand in={debt > investableAssets}>
      <Form.FieldGroup name="hasCarDebt" label={false}>
        <Form.Field
          // @ts-expect-error TS(2739) FIXME: Type '{ ({ id, name, label, type, onChange, onValu... Remove this comment to see the full error message
          inputComponent={BooleanInput}
          onCheckedChange={handleCheckedChange}
          label="Er billån en del af din gæld?"
          asFormControl
        />
      </Form.FieldGroup>
      <Form.FieldGroup name="carAsset">
        {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ maskOptions, value, ...otherProps }: Cu... Remove this comment to see the full error message */}
        <Form.Field inputComponent={CurrencyInput} disabled={!hasCarDebt} />
      </Form.FieldGroup>
    </Transition.Expand>
  )
}

export default CarAssetField
