import Container from './Container'
import Navigation from './Navigation'
import NextButton from './NextButton'
import PreviousButton from './PreviousButton'

export default Object.assign(Navigation, {
  Container,
  Navigation,
  NextButton,
  PreviousButton,
})
