import React from 'react'

export interface Props {
  children: React.ReactNode
  plugins?: (
    | {
        component?: React.ReactElement
        props?: any
      }
    | React.ReactElement
  )[]
  pluginsProps?: any
}

const ApplicationWrapper = ({ children, plugins, pluginsProps }: Props) => {
  let content = children
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  plugins.reverse().forEach((PluginComponent) => {
    // @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type 'Rea... Remove this comment to see the full error message
    const pluginName = PluginComponent.displayName.replace('ApplicationWrapper.Plugins.', '')

    const props = pluginsProps && pluginsProps[pluginName]

    // @ts-expect-error TS(2604) FIXME: JSX element type 'PluginComponent' does not have a... Remove this comment to see the full error message
    content = <PluginComponent {...props}>{content}</PluginComponent>
  })

  return content
}

ApplicationWrapper.defaultProps = {
  plugins: [],
}

export default ApplicationWrapper
