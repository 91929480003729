import { Formik, Form as FormikForm } from 'formik'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import uniq from 'lodash/uniq'
import React, { useMemo, useState } from 'react'
import { Form as BootstrapForm } from 'react-bootstrap'
import {
  BooleanSchema,
  DateSchema,
  NumberSchema,
  ArraySchema,
  ObjectSchema,
  StringSchema,
} from 'yup'

import useDeepCompareMemoize from '../../hooks/useDeepCompareMemoize'
import extractProps, { htmlElementPropKeys } from '../../utilities/extractProps'

import Control from './Control'
import ErrorAlert from './ErrorAlert'
import ErrorMessage from './ErrorMessage'
import Field from './Field'
import FieldGroup from './FieldGroup'
import Fields from './Fields'
import FormContext from './FormContext'
import Group from './Group'
import SubmitButton from './SubmitButton'
import { formatErrorsForFormik } from './utilities'

const htmlFormPropKeys = [
  ...htmlElementPropKeys,
  'acceptCharset',
  'action',
  'autocomplete',
  'enctype',
  'method',
  'name',
  'novalidate',
  'rel',
  'target',
]

const bootstrapFormPropKeys = ['inline', 'validated', 'bsPrefix']
const formPropKeys = [...htmlFormPropKeys, ...bootstrapFormPropKeys]

FormikForm.displayName = 'Form.FormikForm'
BootstrapForm.displayName = 'Form.BootstrapForm'

export const useFieldsList = ({ labelDescriptions, validationSchema, fields: fieldsProp }: any) =>
  useMemo(() => {
    if (fieldsProp) return fieldsProp

    const { fields: validationFields } = validationSchema || {}
    let fields: any = []
    if (labelDescriptions) fields = fields.concat(Object.keys(labelDescriptions))
    if (validationFields) fields = fields.concat(Object.keys(validationFields))

    return uniq(fields)
  }, [fieldsProp, labelDescriptions, validationSchema])

const getInitialValue = (initialValue: any, validationField: any) => {
  if (!isNil(initialValue) && initialValue !== '') return initialValue
  if (!validationField) return ''

  switch (validationField.constructor) {
    case BooleanSchema:
      if (isNull(initialValue) || initialValue === '') return ''

      return false
    case ArraySchema:
      return []
    case ObjectSchema:
      return {}
    case StringSchema:
    case DateSchema:
    case NumberSchema:
    default:
      return ''
  }
}

export const getInitialValues = ({
  initialValues,
  fields,
  // @ts-expect-error TS(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
  validationSchema: { fields: validationFields } = {},
}: any) =>
  fields.reduce((result: any, field: any) => {
    const { [field]: initialValue } = initialValues || {}
    const { [field]: validationField } = validationFields || {}

    result[field] = getInitialValue(initialValue, validationField)

    return result
  }, {})

export const useInitialValues = ({
  autoInitialValues = true,
  initialValues,
  fields,
  validationSchema,
}: any) =>
  useMemo(() => {
    if (!autoInitialValues) return initialValues

    return getInitialValues({ validationSchema, fields, initialValues })
  }, [autoInitialValues, fields, initialValues, validationSchema])

export interface FormProps {
  autoInitialValues?: boolean
  disableDefaultErrorAlert?: boolean
  children: React.ReactNode
  fields?: string[]
  initialValues?: any
  labelDescriptions?: any
  onSubmit: (...args: any[]) => any
  validateBackendErrors?: boolean
  validationSchema?: any
}

const Form = ({
  autoInitialValues,
  children,
  initialValues,
  labelDescriptions,
  validationSchema,
  fields: fieldsProp,
  onSubmit,
  validateBackendErrors,
  disableDefaultErrorAlert,
  ...otherProps
}: FormProps) => {
  const [error, setError] = useState()
  const [formProps, formikProps] = extractProps(otherProps, formPropKeys)
  const fields = useFieldsList({
    fields: fieldsProp,
    labelDescriptions,
    validationSchema,
  })

  // Initial values can be initialized on render. The fields and validationSchema props should not.
  const memoizedInitialValues = useDeepCompareMemoize(initialValues)
  const allFields = useMemo(
    () => uniq([...fields, ...Object.keys(memoizedInitialValues || {})]),
    [fields, memoizedInitialValues],
  )

  const finalInitialValues = useInitialValues({
    autoInitialValues,
    validationSchema,
    initialValues: memoizedInitialValues,
    fields: allFields,
  })

  let content
  if (isFunction(children))
    content = (formik: any) => (
      <BootstrapForm {...formProps} as={FormikForm}>
        {children(formik)}
      </BootstrapForm>
    )
  else
    content = (
      <BootstrapForm {...formProps} as={FormikForm}>
        {children}
      </BootstrapForm>
    )

  const context = useMemo(
    () => ({
      labelDescriptions: labelDescriptions || {},
      error,
    }),
    [labelDescriptions, error],
  )

  const handleSubmit = async (values: any, formik: any) => {
    const result = await onSubmit(values, formik)

    if (isEmpty(result)) return result
    if (!validateBackendErrors) return result

    const {
      status,
      data: { errors, error: backendError },
      showResponseError = false,
    } = result

    if (isEmpty(errors) && !backendError) return result

    const shouldShowError = status === 422 || showResponseError

    if (!shouldShowError) return result

    if (backendError) {
      setError(backendError)

      return result
    }

    const { setErrors } = formik
    const formattedErrors = formatErrorsForFormik(errors)

    setErrors(formattedErrors)

    return result
  }

  return (
    <FormContext.Provider value={context}>
      <Formik
        initialValues={finalInitialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        {...formikProps}
      >
        <>
          {!disableDefaultErrorAlert && <ErrorAlert />}
          {content}
        </>
      </Formik>
    </FormContext.Provider>
  )
}

Object.assign(Form, BootstrapForm)

Form.displayName = 'Form'
/* eslint-enable react/forbid-prop-types */

Form.defaultProps = {
  autoInitialValues: true,
  disableDefaultErrorAlert: false,
  fields: undefined,
  initialValues: undefined,
  labelDescriptions: undefined,
  validateBackendErrors: true,
  validationSchema: undefined,
}

Form.ErrorAlert = ErrorAlert
Form.ErrorMessage = ErrorMessage
Form.Field = Field
Form.Fields = Fields
Form.Control = Control
Form.FieldGroup = FieldGroup
Form.Group = Group
Form.SubmitButton = SubmitButton

export * from './Fields'
export default Form
