import classNames from 'classnames'
import React from 'react'

import type { bootstrapSize } from '../../utilities/propTypes'
import { bootstrapVariant } from '../../utilities/propTypes'
import Circle from '../Circle'

import styles from './CardCircle.module.scss'

export interface Props {
  children?: React.ReactNode
  className?: string
  filled?: boolean
  // @ts-expect-error TS(2749) FIXME: 'bootstrapSize' refers to a value, but is being us... Remove this comment to see the full error message
  size?: bootstrapSize
  variant?: any // TODO: bootstrapVariant()
}

const CardCircle = React.forwardRef<any, Props>(({ children, className, ...otherProps }, ref) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; filled?: boolean | un... Remove this comment to see the full error message
  <Circle className={classNames(styles.cardCircle, className)} ref={ref} {...otherProps}>
    {children}
  </Circle>
))

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
CardCircle.defaultProps = {
  children: undefined,
  className: undefined,
  filled: true,
  size: 'lg',
  variant: 'dark',
}

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
CardCircle.displayName = 'Card.Circle'

// @ts-expect-error TS(2339) FIXME: Property 'Image' does not exist on type '(props: P... Remove this comment to see the full error message
CardCircle.Image = Circle.Image

export default CardCircle
