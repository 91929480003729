import classNames from 'classnames'
import React from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'

import styles from './AnswerField.module.scss'

export interface Props {
  answer: string
  index: number
  onAnswer: (...args: any[]) => any
  selected: boolean
}

const AnswerField = ({ answer, selected, onAnswer, index }: Props) => {
  const handleClick = () => onAnswer(answer, index)

  return (
    <Col xs={12} lg={6} className="p-1" data-cy="answer">
      <Button
        // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'.
        tabIndex="0"
        variant="primary"
        value={answer}
        onClick={handleClick}
        className={classNames(
          styles.answerButton,
          'text-base',
          'py-4',
          'border',
          'shadow',
          'rounded',
          'w-100',
          'h-100',
          {
            'btn-filled': selected,
          },
        )}
      >
        {answer}
      </Button>
    </Col>
  )
}

export default AnswerField
