import toString from 'lodash/toString'
import React, { useMemo } from 'react'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import type { MaskedInputProps } from './MaskedInput'
import MaskedInput from './MaskedInput'

const defaultMaskOptions = {
  prefix: '',
  suffix: ' kr.',
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  requireDecimal: false,
}

const createMask = (options: any) =>
  createNumberMask({
    ...defaultMaskOptions,
    ...options,
  })

const baseFormatter = (maskOptions: any) => (value: any) => {
  let formattedValue = toString(value)

  if (!formattedValue.length) return ''

  formattedValue = formattedValue.replace('.', maskOptions.decimalSymbol)
  formattedValue = `${maskOptions.prefix}${formattedValue}${maskOptions.suffix}`

  return formattedValue
}

const baseParser =
  ({ thousandsSeparatorSymbol, prefix, suffix, decimalSymbol }: any) =>
  (inputValue: any) => {
    if (!inputValue) return ''

    const parsedValue = inputValue
      .replace(new RegExp(`\\${thousandsSeparatorSymbol}`, 'g'), '')
      .replace(prefix, '')
      .replace(suffix, '')
      .replace(decimalSymbol, '.')

    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    return parseFloat(parsedValue, 10)
  }

export interface CurrencyInputProps extends MaskedInputProps {
  maskOptions?: {
    prefix?: string
    suffix?: string
    includeThousandsSeparator?: boolean
    thousandsSeparatorSymbol?: string
    allowDecimal?: boolean
    decimalSymbol?: string
    decimalLimit?: number
    integerLimit?: number
    requireDecimal?: boolean
    allowNegative?: boolean
    allowLeadingZeroes?: boolean
  }
  value?: string | number
}

const CurrencyInput = ({ maskOptions, value, ...otherProps }: CurrencyInputProps) => {
  const formatter = useMemo(
    () => baseFormatter(maskOptions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(maskOptions)],
  )

  const parser = useMemo(
    () => baseParser(maskOptions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(maskOptions)],
  )

  return (
    <MaskedInput
      // inputMode="numeric"
      {...otherProps}
      createMask={createMask}
      formatter={formatter}
      maskOptions={maskOptions}
      value={value && value.toString()}
      parser={parser}
    />
  )
}

CurrencyInput.defaultProps = {
  maskOptions: defaultMaskOptions,
  value: undefined,
}

export default CurrencyInput
