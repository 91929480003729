import AlertModal from '@nord/ui/src/components/AlertModal'
import LoadingButton from '@nord/ui/src/components/LoadingButton'
import { useFormikContext } from 'formik'
import React from 'react'

type Props = {
  isSubmitting: boolean
  onSubmit: (...args: any[]) => any
}

const DebtModal = ({ onSubmit, isSubmitting, ...otherProps }: Props) => {
  const {
    values: { investableAssets, debt, carAsset },
  } = useFormikContext<any>()

  const handleAccept = () => onSubmit({ investableAssets, debt, carAsset })

  return (
    <AlertModal
      onAccept={handleAccept}
      title="Desværre har du for meget gæld"
      text="Vi har vurderet din økonomi. På vegne af dine oplysninger har vi desværre vurderet, at du ikke er egnet til at investere hos os på nuværende tidspunkt."
      // @ts-expect-error TS(2739) FIXME: Type '{ ({ icon, loading, disabled, children, ...b... Remove this comment to see the full error message
      acceptButtonComponent={LoadingButton}
      acceptButtonProps={{ loading: isSubmitting }}
      acceptable
      {...otherProps}
    />
  )
}

export default DebtModal
