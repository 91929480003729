import Icon from '@nord/ui/src/components/Icon'
import Text from '@nord/ui/src/components/Text'
import React from 'react'
import Button from 'react-bootstrap/Button'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Clock from 'react-feather/dist/icons/clock'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import PortfolioTitle from '../../../../components/PortfolioTitle'

import styles from './index.module.scss'

type Props = {
  startPath: string
  firstName: string
}

const RebalancingContent = ({ firstName, startPath }: Props) => (
  <>
    <Text as="h3" weight="bold" size="xxxl">
      Hej {firstName}! Din portefølje bør rebalanceres
    </Text>
    <PortfolioTitle />
    <Text as="p">
      For at kunne vurdere, om en rebalancering giver mening for dig på nuværende tidspunkt, skal vi
      først lave et tjek om din økonomi eller risikoprofil har ændret sig, siden du sidst opdaterede
      dine økonomiske oplysninger hos os.
    </Text>
    <Text as="p" weight="bold" className="d-flex flex-align-items">
      <Icon size={1.5} icon={Clock} className="mr-2" />
      Det tager ca. 5 minutter - klik herunder for at starte.
    </Text>
    <Button as={Link} to={startPath}>
      Start
    </Button>
    <hr />
    <div className="d-flex flex-column mt-5">
      <Text as="p">
        Du kan lære mere om hvorfor vi rebalancerer din portefølje i videoen nedenfor eller i vores
        blogindlæg:{' '}
        <Text
          as="a"
          href="https://blog.nord.investments/hvorfor-rebalancere-sin-portefolje/"
          target="_blank"
        >
          Hvorfor rebalancere sin portefølje?
        </Text>
      </Text>
      <div className={styles.videoWrapper}>
        <iframe
          title="Rebalancering"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/JMM440m97cc?si=cb2gmjFMhA6nkmiU"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  </>
)

export default RebalancingContent
