import React, { useContext } from 'react'
import Form from 'react-bootstrap/Form'

import Loader from '../Loader'

import FormContext from './FormContext'

export interface Props {
  component?: React.ReactElement
  name: string
  label?: string | any // TODO: PropTypes.oneOf([false])
  loading?: boolean
}

const FieldLabel = ({ name, label, loading, component: Component }: Props) => {
  // @ts-expect-error TS(2339) FIXME: Property 'labelDescriptions' does not exist on typ... Remove this comment to see the full error message
  const { labelDescriptions } = useContext(FormContext) || {}

  if (label === false) return null

  const fieldLabel = label || (labelDescriptions || {})[name]

  if (!fieldLabel) return null

  return (
    // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
    <Component>
      {loading ? (
        <span className="d-flex flex-row">
          {fieldLabel}
          <Loader className="pl-2" />
        </span>
      ) : (
        fieldLabel
      )}
    </Component>
  )
}

FieldLabel.defaultProps = {
  component: Form.Label,
  label: undefined,
  loading: false,
}

export default FieldLabel
