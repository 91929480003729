import get from 'lodash/get'
import isArray from 'lodash/isArray'
import pickBy from 'lodash/pickBy'
import startsWith from 'lodash/startsWith'

export const sumPageCounts = (array: any) =>
  (array || []).reduce((sum: any, currentCount: any) => {
    if (isArray(currentCount)) return sum + sumPageCounts(currentCount)

    return sum + (currentCount || 1)
  }, 0)

const cumulatedSums = (initialSum: any, array: any, before = false) => {
  let sum = initialSum

  return (array || []).map((currentCount: any) => {
    if (isArray(currentCount)) return cumulatedSums(sum, currentCount, before)
    const previousSum = sum
    sum += currentCount || 1

    if (before) return previousSum

    return sum
  }, 0)
}

export const calculateBeforeCounts = (pageCounts: any) => cumulatedSums(0, pageCounts, true)
export const calculateAfterCounts = (pageCounts: any) => cumulatedSums(0, pageCounts, false)

export const getPageKey = (pageIndex: any, parentKey: any) => {
  if (!parentKey) return `${pageIndex}`

  return `${parentKey}.${pageIndex}`
}

export const calculatePaginationData = ({ pageIndex, pageCount, ...extra }: any) => ({
  index: pageIndex,
  number: pageIndex + 1,
  count: pageCount,
  first: pageIndex === 0,
  last: pageIndex === pageCount - 1,
  ...extra,
})

// @ts-expect-error TS(7023) FIXME: 'getFirstValue' implicitly has return type 'any' b... Remove this comment to see the full error message
const getFirstValue = (firstValue: any) => {
  if (isArray(firstValue)) return getFirstValue(firstValue[0])

  return firstValue
}

const getAbsoluteIndex = (
  { index, forwards }: any,
  { _beforeCounts, _afterCounts, _parentKey }: any,
) => {
  const pageKey = getPageKey(index, _parentKey)
  const beforeCount = get(_beforeCounts, _parentKey)
  const ancestor = isArray(beforeCount) || beforeCount === undefined

  if (!ancestor) return beforeCount + index

  const backwards = forwards === false
  const pageCounts = backwards ? _afterCounts : _beforeCounts
  const previousCount = getFirstValue(get(pageCounts, pageKey))

  if (previousCount === undefined) return index
  if (backwards) return previousCount - 1

  return previousCount
}

const isPublicProperty = (_value: any, property: any) => !startsWith(property, '_')

export const calculateAbsolutePaginationData = (pagination: any, absolutePagination: any) => {
  const absoluteIndex = getAbsoluteIndex(pagination, absolutePagination)
  const { count: absoluteCount } = absolutePagination

  return {
    ...pagination,
    absolute: {
      ...pickBy(
        {
          ...absolutePagination,
          ...calculatePaginationData({
            pageIndex: absoluteIndex,
            pageCount: absoluteCount,
          }),
        },
        isPublicProperty,
      ),
    },
  }
}

export const getPaginationProperties = (pagination: any) => {
  const filteredProperties = pickBy(pagination, isPublicProperty)
  const { absolute } = pagination
  if (!absolute) return filteredProperties

  return calculateAbsolutePaginationData(filteredProperties, absolute)
}
