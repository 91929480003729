import { debounce } from 'lodash'
import { useMemo } from 'react'

import useAutoUpdateRef from './useAutoUpdateRef'

const useDebouncedCallback = (callback: any, delay: any, options: any) => {
  const callbackRef = useAutoUpdateRef(callback)
  const debouncedResolve = useMemo(() => {
    const resolvePromise = (resolve: any) => resolve()

    return debounce(resolvePromise, delay, options)
  }, [delay, options])

  const asyncCallback = useMemo(() => {
    const cancelDebounce = debouncedResolve.cancel
    let cancel = cancelDebounce

    const asyncFunc = async (...args: any[]) => {
      await new Promise((resolve, reject) => {
        debouncedResolve(resolve)

        cancel = () => {
          cancelDebounce()
          reject()
        }
      })

      return callbackRef.current(...args)
    }

    asyncFunc.cancel = cancel

    return asyncFunc
  }, [callbackRef, debouncedResolve])

  return asyncCallback
}

export default useDebouncedCallback
