import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { InputGroup } from 'react-bootstrap'

import CountrySelect from './CountrySelect'
import styles from './withCountrySelect.module.scss'

const formatOption = (item: any) => ({
  value: `+${item.phone}`,
  label: `${item.country} (+${item.phone})`,
  displayValue: `+${item.phone}`,
  code: item.code,
})

const withCountrySelect = (WrappedComponent: any, defaultProps = {}) => {
  const WrapperComponent = ({
    onChange,
    onNumberChange,
    onCodeChange,
    onPrefixChange,
    onValueChange,
    value,
    defaultItem,
    ...otherProps
  }: any) => {
    const defaultSelected = defaultItem ? formatOption(defaultItem) : undefined
    const previousValue = useRef(value)

    const [internalValue, setInternalValue] = useState({
      value: '',
      prefix: (defaultSelected && defaultSelected.value) || '',
      code: (defaultSelected && defaultSelected.code) || '',
    })

    const useInternalState = value !== undefined

    useEffect(() => {
      if (JSON.stringify(value) !== JSON.stringify(previousValue.current)) {
        const { prefix, code, value: inputValue } = value || {}
        previousValue.current = value

        setInternalValue({ prefix, code, value: inputValue })
      }
    }, [value])

    const handleSelect = useCallback(
      ({ code, value: prefix }: any) => {
        const newInternalValue = { ...internalValue, prefix, code }
        setInternalValue(newInternalValue)

        if (onPrefixChange) onPrefixChange(prefix)
        if (onCodeChange) onCodeChange(code)
        if (onValueChange) onValueChange(newInternalValue)

        return newInternalValue
      },
      [internalValue, onCodeChange, onPrefixChange, onValueChange],
    )

    const handleInputValueChange = useCallback(
      (newValue: any) => {
        const newInternalValue = { ...internalValue, value: newValue }
        setInternalValue(newInternalValue)

        if (onNumberChange) onNumberChange(newValue)
        if (onValueChange) onValueChange(newInternalValue)

        return newInternalValue
      },
      [internalValue, onNumberChange, onValueChange],
    )

    return (
      <InputGroup>
        <InputGroup.Prepend>
          <CountrySelect
            // @ts-expect-error TS(2322) FIXME: Type '{ className: string; onSelect: ({ code, valu... Remove this comment to see the full error message
            className={styles.phonePrefixSelect}
            onSelect={handleSelect}
            value={useInternalState ? internalValue.prefix : undefined}
            defaultSelected={defaultSelected}
            optionFormat={formatOption}
            styles={{
              menu: (menuStyles: any) => ({
                ...menuStyles,
                minWidth: 320,
              }),
            }}
          />
        </InputGroup.Prepend>
        <WrappedComponent
          onValueChange={handleInputValueChange}
          value={useInternalState ? internalValue.value : undefined}
          {...otherProps}
        />
      </InputGroup>
    )
  }

  WrapperComponent.displayName = `withCountrySelect(${
    WrappedComponent.displayName || WrappedComponent.name
  })`

  WrapperComponent.propTypes = {
    defaultItem: PropTypes.shape({
      phone: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func,
    onCodeChange: PropTypes.func,
    onNumberChange: PropTypes.func,
    onPrefixChange: PropTypes.func,
    onValueChange: PropTypes.func,
    value: PropTypes.shape({
      value: PropTypes.string,
      prefix: PropTypes.string,
    }),
  }

  WrapperComponent.defaultProps = {
    defaultItem: undefined,
    onChange: undefined,
    onCodeChange: undefined,
    onNumberChange: undefined,
    onPrefixChange: undefined,
    onValueChange: undefined,
    value: undefined,
    ...defaultProps,
  }

  return WrapperComponent
}

export default withCountrySelect
