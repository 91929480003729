import { useField } from 'formik'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import ErrorMessage from './ErrorMessage'
import FieldLabel from './FieldLabel'
import Group from './Group'

export interface Props {
  children: React.ReactNode
  column?: boolean
  error?: string
  label?: string | any // TODO: PropTypes.oneOf([false])
  loading?: boolean
  help?: React.ReactNode
  isInvalid?: boolean
  name?: string
}

const FieldGroup = ({
  name,
  error: errorProp,
  isInvalid: isInvalidProp,
  label,
  help,
  column,
  children,
  loading,
  ...otherProps
}: Props) => {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  const [_field, meta] = useField(name)
  const { error: formikError, touched } = meta
  const child = React.Children.only(children)
  const isInvalid = isInvalidProp !== undefined ? isInvalidProp : touched && !!formikError

  return (
    // @ts-expect-error TS(2322) FIXME: Type 'BsPrefixRefForwardingComponent<"div", ColPro... Remove this comment to see the full error message
    <Group controlId={name} as={column ? Col : undefined} {...otherProps} data-cy={`input-${name}`}>
      {/* @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
      <FieldLabel name={name} loading={loading} label={label} />
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      {React.cloneElement(child, { ...child.props, name, isInvalid })}
      {help && <Form.Text>{help}</Form.Text>}
      <ErrorMessage error={errorProp} name={name} />
    </Group>
  )
}

FieldGroup.displayName = 'Form.FieldGroup'

FieldGroup.defaultProps = {
  column: false,
  error: undefined,
  help: undefined,
  isInvalid: undefined,
  label: undefined,
  loading: false,
  name: undefined,
}

export default FieldGroup
