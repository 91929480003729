import AlertModal from '@nord/ui/src/components/AlertModal'
import Card from '@nord/ui/src/components/Card'
import Text from '@nord/ui/src/components/Text'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import {
  selectCurrentPortfolio,
  updateCurrentPortfolio,
} from '@nord/ui/src/store/current/portfolioId'
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useDispatch } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import ChartOverview from '../../../../components/ChartOverview'
import Page from '../../../../components/Page'
import usePortfolioId from '../../../../hooks/usePortfolioId'

const Rebalacing = () => {
  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => setShowModal(false)
  const handleOpenModal = () => setShowModal(true)

  const portfolioId = usePortfolioId()
  const history = useHistory()
  const dispatch = useDispatch()

  // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
  const { riskScore } = useObjectSelector(selectCurrentPortfolio)

  const handleApprovedRebalancing = async () => {
    await dispatch(
      updateCurrentPortfolio({
        rebalancingAccepted: true,
      }),
    )

    history.push(`/portefoeljer/${portfolioId}/rebalancer/godkendt`)
  }

  const handleRejectedRebalacing = async () => {
    await dispatch(
      updateCurrentPortfolio({
        rebalancingAccepted: false,
      }),
    )
    history.push(`/portefoeljer/${portfolioId}/rebalancer/afvist`)
  }

  const isAllStocks = riskScore === 100

  const text = [
    'Siden du startede din investering, er der sket ændringer på de ﬁnansielle markeder.',
    isAllStocks
      ? 'Ved 100% aktier bliver der rebalanceret imellem de enkelte aktiefonde - selvom du altså kun har aktier i porteføljen, rebalanceres der stadigvæk for dig.'
      : 'Fordelingen mellem dine aktier og obligationer har ændret sig. For dig betyder det, at din risikoproﬁl afviger fra den oprindelige.',
    'Derfor anbefaler vi en rebalancering af din portefølje.',
  ].join(' ')

  return (
    <Page>
      <Card>
        <Card.Body>
          <Text as="h3" align="center">
            Din porteføjle bør rebalanceres
          </Text>
          <Text as="p" align="center" className="px-lg-4">
            {text}
          </Text>
          <ChartOverview />
          <Text as="p" align="center" className="pt-4 px-lg-4">
            Ønsker du at vi rebalancerer din portefølje?
          </Text>
          <div className="d-flex justify-content-center mt-5 children-mx-3">
            <Button onClick={handleRejectedRebalacing} variant="danger">
              Nej
            </Button>
            <Button onClick={handleOpenModal}>Ja</Button>
          </div>
        </Card.Body>
      </Card>
      <AlertModal
        title="Videreformidling af order"
        text={`Du accepterer samtidigt, at NORD.investments må videregive dit samtykke til rebalancering i henhold til risikoprofil ${riskScore}. Der er en omkostning involveret, men denne omkostning er inklusiv i de totale omkostninger. `}
        onAccept={handleApprovedRebalancing}
        onHide={handleCloseModal}
        show={showModal}
        acceptable
      >
        Dette vil først blive videreformidlet, når du har accepteret.
      </AlertModal>
    </Page>
  )
}

export default Rebalacing
