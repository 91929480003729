import * as Sentry from '@sentry/react'

export const setupScope = ({ firstName, lastName, email, id }: any) => {
  const username = `${firstName} ${lastName}`

  Sentry.configureScope((scope) => {
    scope.setUser({ email, id, username })
  })
}

export const clearScope = () =>
  Sentry.configureScope((scope) => {
    scope.setUser(null)
  })

export const captureException = (error: any) => {
  Sentry.captureException(error)
}

export const captureMessage = (message: any) => {
  Sentry.captureMessage(message)
}

export const withScope = (info: any, callback: any) => {
  Sentry.withScope((scope) => {
    Object.keys(info).forEach((key) => {
      scope.setExtra(key, info[key])
    })
    callback(scope)
  })
}

export const captureWithScope = (info: any, error: any) =>
  withScope(info, () => captureException(error))
