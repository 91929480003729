import { getConfig } from '@nord/ui/src/configuration'
import React from 'react'
import Button from 'react-bootstrap/Button'

const webappLink = getConfig('domains.app')

const MyAccountButton = ({ ...props }) => (
  <Button href={webappLink} {...props}>
    Gå til min konto
  </Button>
)

export default MyAccountButton
