export const getAttributesFromApiResponse = (action: any) => {
  const {
    payload: { data, ...otherPayload },
  } = action

  return data || otherPayload
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const updateFromApiResponse = (state, action) => ({
  ...state,
  ...getAttributesFromApiResponse(action),
})

export const updateSingleRecordFromApiResponse = (state: any, action: any) => {
  const attributes = getAttributesFromApiResponse(action)
  const { id } = attributes

  const portfolioToUpdate = state[id]

  return { ...state, [id]: { ...portfolioToUpdate, ...attributes } }
}
