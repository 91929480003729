import Nested from '../Nested'
import QueryParam from '../QueryParam'
import Wrapper from '../Wrapper'

import Navigation from './Navigation'
import Page from './Page'
import withPaginatedForm from './withPaginatedForm'

const Form = {
  Wrapper: Object.assign(withPaginatedForm(Wrapper), {
    WithNested: Object.assign(withPaginatedForm(Nested.PagesWrapper), {
      WithQueryParam: withPaginatedForm(Nested.PagesWrapper.WithQueryParam),
    }),
    WithQueryParam: withPaginatedForm(QueryParam.Wrapper),
  }),
  Navigation,
  Page,
}

export { default as withPaginatedForm } from './withPaginatedForm'

export default Form
