// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React, { useEffect } from 'react'

import { environment } from '../../../configuration/config'
import loadGoogleTagManager from '../../../utilities/loadGoogleTagManager'
import setupABTest from '../../../utilities/setupABTest'

const { isDevelopment } = environment

export interface Props {
  children: React.ReactNode
}

const CookieConsent = ({ children }: Props) => {
  useEffect(() => {
    if (!isDevelopment) {
      loadGoogleTagManager()
      setupABTest()
    }
  }, [])

  return children
}

CookieConsent.defaultProps = {
  onAccept: undefined,
}

CookieConsent.displayName = 'ApplicationWrapper.Plugins.CookieConsent'

export default CookieConsent
