import { useMemo } from 'react'

import useInternalContext from './useInternalContext'

const usePageTransition = (index: any) => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property '_enterAnimation' does not exist on type ... Remove this comment to see the full error message
    _enterAnimation: enterAnimation,
    // @ts-expect-error TS(2339) FIXME: Property '_exitAnimation' does not exist on type '... Remove this comment to see the full error message
    _exitAnimation: exitAnimation,
    // @ts-expect-error TS(2339) FIXME: Property '_pageIndex' does not exist on type 'unkn... Remove this comment to see the full error message
    _pageIndex: pageIndex,
    // @ts-expect-error TS(2339) FIXME: Property '_parentActive' does not exist on type 'u... Remove this comment to see the full error message
    _parentActive: parentActive,
  } = useInternalContext()

  return useMemo(() => {
    const forwards = pageIndex.next > pageIndex.current || pageIndex.current > pageIndex.previous

    const active = index === pageIndex.current && parentActive
    const exit = index === pageIndex.previous && parentActive
    const enter =
      !parentActive || exit
        ? false
        : pageIndex.previous !== undefined && index === pageIndex.current

    const direction = forwards ? 'forwards' : 'backwards'
    let animationRef

    if (enter && enterAnimation) animationRef = (element: any) => enterAnimation(element, direction)
    if (exit && exitAnimation) animationRef = (element: any) => exitAnimation(element, direction)

    return {
      animationRef,
      active,
      exit,
      enter,
    }
  }, [enterAnimation, exitAnimation, index, pageIndex, parentActive])
}

export default usePageTransition
