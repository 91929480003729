import classNames from 'classnames'
import isNumber from 'lodash/isNumber'
import React from 'react'

import styles from './index.module.scss'

const Icon = ({ className, icon: IconComponent, size, spin, ...otherProps }: Props) => {
  // @ts-expect-error TS(2363) FIXME: The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message
  const formattedSize = isNumber(size) ? `${size * styles.sizeRatio}em` : size

  if (IconComponent === undefined) return null

  return (
    // @ts-expect-error TS(2604) FIXME: JSX element type 'IconComponent' does not have any... Remove this comment to see the full error message
    <IconComponent
      size={formattedSize}
      className={classNames(className, styles.icon, 'icon', {
        [`${styles.spin}`]: spin,
      })}
      {...otherProps}
    />
  )
}

export interface Props {
  className?: string
  icon: React.ReactElement
  size?: string | number
  spin?: boolean
}

Icon.defaultProps = {
  className: undefined,
  size: 1,
  spin: false,
}

export default Icon
