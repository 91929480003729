import isString from 'lodash/isString'
import React, { useMemo } from 'react'
import FormControl from 'react-bootstrap/FormControl'

import chainEventHandler from '../../utilities/chainEventHandler'

export interface Props {
  as?: React.ReactElement | string
  component?: React.ReactElement
  onChange?: (...args: any[]) => any
  onValueChange?: (...args: any[]) => any
}

const Control = React.forwardRef<any, Props>(
  ({ component, onChange, onValueChange, as, ...otherProps }, ref) => {
    const Component = component || FormControl
    const customComponent = !!component || (!!as && !isString(as))

    const handleChangeValue = useMemo(() => {
      if (customComponent) return onChange

      return chainEventHandler(onChange, (event: any) => {
        const {
          target: { value },
        } = event

        if (onValueChange) return onValueChange(value, event)

        return value
      })
    }, [customComponent, onChange, onValueChange])

    // @ts-expect-error TS(2339) FIXME: Property 'onValueChange' does not exist on type '{... Remove this comment to see the full error message
    if (customComponent) otherProps.onValueChange = onValueChange

    // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
    return <Component onChange={handleChangeValue} ref={ref} as={as} {...otherProps} />
  },
)

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
Control.displayName = 'Form.Control'

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
Control.defaultProps = {
  as: undefined,
  component: undefined,
  onChange: undefined,
  onValueChange: undefined,
}

export default Control
