export const getValues = (newData: any) => newData.map(({ value }: any) => value)

export const getLabel = (newData: any, value: any) => {
  // @ts-expect-error TS(7031) FIXME: Binding element 'defaultValue' implicitly has an '... Remove this comment to see the full error message
  const { label } = newData.find(({ value: defaultValue }) => defaultValue === value)

  return label
}

export const getHint = (newData: any, value: any) => {
  // @ts-expect-error TS(7031) FIXME: Binding element 'defaultValue' implicitly has an '... Remove this comment to see the full error message
  const { hint } = newData.find(({ value: defaultValue }) => defaultValue === value)

  return hint
}
