import { CancelToken } from 'axios'
import { useMemo, useContext, useRef } from 'react'

import ReduxProviderContext from '../../components/ApplicationWrapper/plugins/ReduxProvider/ReduxProviderContext'
import ErrorContext from '../../components/ErrorBoundary/ErrorContext'
import { getConfig } from '../../configuration'
import useDeepCompareCallback from '../useDeepCompareCallback'

import handleError from './handleError'
import useApiClient from './useApiClient'

const backendDomain = getConfig('domains.backend')

const useApi = (path: any, options = {}) => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'baseURL' does not exist on type '{}'.
    baseURL = backendDomain,
    // @ts-expect-error TS(2339) FIXME: Property 'method' does not exist on type '{}'.
    method,
    // @ts-expect-error TS(2339) FIXME: Property 'withCredentials' does not exist on type ... Remove this comment to see the full error message
    withCredentials,
    // @ts-expect-error TS(2339) FIXME: Property 'errorHandling' does not exist on type '{... Remove this comment to see the full error message
    errorHandling,
    ...defaultOptions
  } = options

  const apiClient = useApiClient(baseURL)
  // @ts-expect-error TS(2339) FIXME: Property 'onError' does not exist on type 'unknown... Remove this comment to see the full error message
  const { onError: onRequestError } = useContext(ErrorContext) || {}
  // @ts-expect-error TS(2339) FIXME: Property 'onUnauthorizedError' does not exist on t... Remove this comment to see the full error message
  const { onUnauthorizedError } = useContext(ReduxProviderContext) || {}
  const defaultOptionsRef = useRef(defaultOptions)

  const onError = useDeepCompareCallback(
    (error: any) =>
      handleError(error, {
        onRequestError,
        onUnauthorizedError,
        ...errorHandling,
      }),
    [errorHandling, onRequestError, onUnauthorizedError],
  )

  const callApi = useMemo(() => {
    // @ts-expect-error TS(2693) FIXME: 'CancelToken' only refers to a type, but is being ... Remove this comment to see the full error message
    const cancelSource = CancelToken.source()

    const result = async (requestData: any, requestOptions = {}) => {
      // @ts-expect-error TS(2339) FIXME: Property 'pathParams' does not exist on type '{}'.
      const { pathParams, ...extraRequestOptions } = requestOptions
      let finalPath = path
      if (pathParams) {
        Object.entries(pathParams).forEach(([pathParam, value]) => {
          finalPath = finalPath.replace(`:${pathParam}`, value)
        })
      }

      const finalOptions = {
        ...defaultOptionsRef.current,
        method,
        withCredentials,
        url: finalPath,
        cancelToken: cancelSource.token,
        ...extraRequestOptions,
      }

      if (['PUT', 'POST', 'PATCH'].includes(method)) {
        // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{ method: ... Remove this comment to see the full error message
        finalOptions.data = requestData
      } else {
        // @ts-expect-error TS(2339) FIXME: Property 'params' does not exist on type '{ method... Remove this comment to see the full error message
        finalOptions.params = requestData
      }

      try {
        const response = await apiClient.request(finalOptions)

        return {
          ...response,
          success: true,
        }
      } catch (error) {
        return onError(error)
      }
    }

    result.cancel = cancelSource.cancel

    return result
  }, [path, method, withCredentials, apiClient, onError])

  return callApi
}

export default useApi
