import useIntercom from '../../../hooks/useIntercom'

export interface Props {
  children: React.ReactNode
}

const Intercom = ({ children }: Props) => {
  useIntercom()

  return children
}

Intercom.displayName = 'ApplicationWrapper.Plugins.Intercom'

export default Intercom
