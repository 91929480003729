import React from 'react'
import { components } from 'react-select'

import FlagIcon from './FlagIcon'

export interface Props {
  children: React.ReactNode
  data: {
    value: string
    code?: string
  }
}

const Option = ({ children, data, ...otherProps }: Props) => {
  const { value, code } = data

  return (
    // @ts-expect-error TS(2740) FIXME: Type '{ children: (Element | ReactNode)[]; data: {... Remove this comment to see the full error message
    <components.Option data={data} {...otherProps}>
      <FlagIcon countryCode={code || value} /> {children}
    </components.Option>
  )
}

export default Option
