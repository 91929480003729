const rvhRegex = /(\d+(\.\d*)?)rvh(?!\w)/
export const containsRvh = (propertyValue: any) => rvhRegex.test(propertyValue)

const replaceRvhWithPx = (propertyStringValue: any, windowHeight: any) =>
  propertyStringValue.replace(
    rvhRegex,
    (_: any, rvh: any) => `${(windowHeight * parseFloat(rvh)) / 100}px`,
  )

const convertStyle = (style: any, windowHeight: any) =>
  Object.entries(style).reduce((result, [attribute, value]) => {
    // if a value contains no rvh unit, it's used as is, otherwise converted
    // to px; 1rvh = (window.innerHeight / 100)px
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    result[attribute] = containsRvh(value) ? replaceRvhWithPx(value, windowHeight) : value

    return result
  }, {})

export default convertStyle
