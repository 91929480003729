const chainFunctions =
  (...functions: any[]) =>
  (value: any) =>
    functions.reduce((previousValue, currentFunction) => {
      if (currentFunction === undefined) return previousValue

      return currentFunction(previousValue)
    }, value)

export default chainFunctions
