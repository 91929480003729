import classNames from 'classnames'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import LoaderIcon from 'react-feather/dist/icons/loader'

import extractProps from '../utilities/extractProps'

import Icon from './Icon'
import styles from './Loader.module.scss'

const iconPropKeys = ['size', 'spin', 'icon']

export interface Props {
  centeredInParent?: boolean
  children?: React.ReactNode
  className?: string
}

const Loader = ({ centeredInParent, className, children, ...otherProps }: Props) => {
  const [iconProps, containerProps] = extractProps(otherProps, iconPropKeys)

  return (
    <div
      className={classNames(className, {
        [styles.centeredLoader]: centeredInParent,
      })}
      {...containerProps}
    >
      {children && <div>{children}</div>}
      <Icon icon={LoaderIcon} spin {...iconProps} />
    </div>
  )
}

Loader.defaultProps = {
  centeredInParent: false,
  children: undefined,
  className: undefined,
}

export default Loader
