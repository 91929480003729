import { format, toDate, isDate, parseISO, fromUnixTime } from 'date-fns'
import { da } from 'date-fns/locale'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'

export const valueToDate = (value: any) => {
  if (isDate(value)) return value
  if (isString(value)) return parseISO(value)
  if (isNumber(value)) return fromUnixTime(value)

  return toDate(value)
}

const formatDate = (value: any, displayFormat = 'dd.MM.yyyy') =>
  format(valueToDate(value), displayFormat, { locale: da })

export const formatTime = (value: any, displayFormat = 'HH:mm') =>
  format(valueToDate(value), displayFormat, { locale: da })

export const formatDateTime = (value: any, displayFormat = 'dd.MM.yyyy, HH:mm') =>
  format(valueToDate(value), displayFormat, { locale: da })

export default formatDate
