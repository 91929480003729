import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import mapValues from 'lodash/mapValues'
import upperFirst from 'lodash/upperFirst'

export const formatErrorsForFormik = (errors: any) =>
  mapValues(errors, (error) =>
    error.map((lowerCaseError: any) => upperFirst(lowerCaseError)).join('. '),
  )

export const formatErrorsForAlert = (errors: any) => {
  if (isString(errors)) return `${upperFirst(errors)}.`
  if (isArray(errors)) return errors.map((error) => `${upperFirst(error)}. `).join('')

  return null
}
