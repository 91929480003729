import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Minus from 'react-feather/dist/icons/minus'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Plus from 'react-feather/dist/icons/plus'

import useBreakpointHandler from '../../../hooks/useBreakpointHandler'
import useElementSize from '../../../hooks/useElementSize'
import IconButton from '../../IconButton'
import Sortable from '../../Sortable'

import Item from './Item'
import SortingContext from './SortingContext'

const handleStopPropagation = (event: any) => event.stopPropagation()

const TwoColSorting = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'items' does not exist on type 'unknown'.
  const { items, setItems, isInvalid } = useContext(SortingContext)

  const [elementRef, size] = useElementSize()
  const largeAndUp = useBreakpointHandler('lg')

  // @ts-expect-error TS(2339) FIXME: Property 'height' does not exist on type '{}'.
  const { height } = size

  const [listHeight, setListHeight] = useState()

  useEffect(() => {
    if (!height) return
    if (!largeAndUp) return
    if (listHeight) return

    setListHeight(height)
  }, [height, largeAndUp, listHeight])

  const handleAdd = (newItem: any) => {
    const { unordered, ordered } = items

    const newUnordered = unordered.filter((item: any) => item !== newItem)
    const newOrdered = ordered
    newOrdered.push(newItem)

    setItems({
      unordered: newUnordered,
      ordered: newOrdered,
    })
  }

  const handleRemove = (newItem: any) => {
    const { unordered, ordered } = items

    const newOrdered = ordered.filter((item: any) => item !== newItem)
    const newUnordered = unordered
    newUnordered.push(newItem)

    setItems({
      unordered: newUnordered,
      ordered: newOrdered,
    })
  }

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{}' is not assignable to type 'LegacyRef<HTM... Remove this comment to see the full error message
    <div ref={elementRef}>
      <Sortable.Wrapper items={items} onOrderChange={setItems}>
        <Sortable.List
          id="unordered"
          items={items.unordered}
          placeholder="Tak for besvarelsen!"
          // @ts-expect-error TS(2322) FIXME: Type '{ children: any; id: string; items: any; pla... Remove this comment to see the full error message
          style={{ minHeight: listHeight }}
        >
          {items.unordered.map((item: any) => (
            <Sortable.Item id={item} key={item} className="d-flex flex-row justify-content-between">
              <Item value={item} />
              <IconButton
                variant="link"
                icon={Plus}
                className="btn-anchor text-dark pl-3"
                onClick={() => handleAdd(item)}
                onPointerDown={handleStopPropagation}
                onKeyDown={handleStopPropagation}
                data-cy={`add-${item}`}
              />
            </Sortable.Item>
          ))}
        </Sortable.List>
        <Sortable.List
          id="ordered"
          items={items.ordered}
          placeholder="Træk (eller brug +) til at sortere din præferencer fra vigtigst til mindst vigtig"
          className={classNames({ 'border-danger': isInvalid })}
        >
          {items.ordered.map((item: any) => (
            <Sortable.Item id={item} key={item} className="d-flex flex-row justify-content-between">
              <Item value={item} />
              <IconButton
                variant="link"
                icon={Minus}
                className="btn-anchor text-dark pl-3"
                onClick={() => handleRemove(item)}
                onPointerDown={handleStopPropagation}
                onKeyDown={handleStopPropagation}
                data-cy={`remove-${item}`}
              />
            </Sortable.Item>
          ))}
        </Sortable.List>
      </Sortable.Wrapper>
    </div>
  )
}

export default TwoColSorting
