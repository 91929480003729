import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'
import React from 'react'
import Card from 'react-bootstrap/Card'

import useToggleHtmlClass from '../../hooks/useToggleHtmlClass'

import styles from './Item.module.scss'

export interface Props {
  children: React.ReactNode
  className?: string
  id: string | number
}

const Item = ({ children, id, className, ...otherProps }: Props) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  useToggleHtmlClass(styles.draggingHtml, isDragging)

  return (
    <Card
      ref={setNodeRef}
      className={classNames('shadow', styles.itemCard, {
        [styles.draggingItem]: isDragging,
      })}
      style={style}
      {...attributes}
      {...listeners}
      {...otherProps}
    >
      <Card.Body className={classNames('p-3', styles.itemCard, className)}>{children}</Card.Body>
    </Card>
  )
}

Item.defaultProps = {
  className: undefined,
}

export default Item
