import Card from '@nord/ui/src/components/Card'
import Text from '@nord/ui/src/components/Text'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import React from 'react'
import { useSelector } from 'react-redux'

import LoadingPage from '../../../../components/LoadingPage'
import Page from '../../../../components/Page'

import RebalancingContent from './RebalancingContent'
import SuitabilityMandatoryUpdateContent from './SuitabilityMandatoryUpdateContent'
import SuitabilityUpdateContent from './SuitabilityUpdateContent'

const Start = () => {
  const { firstName } = useSelector(selectCurrentUser)
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'investmentProfileId' does not exist on t... Remove this comment to see the full error message
    investmentProfileId,
    // @ts-expect-error TS(2339) FIXME: Property 'suitabilityFlow' does not exist on type ... Remove this comment to see the full error message
    suitabilityFlow,
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: portfolioId,
  } = useObjectSelector(selectCurrentPortfolio)

  if (!firstName || !portfolioId) return <LoadingPage />

  const startPath = investmentProfileId
    ? `/portefoeljer/${portfolioId}/egnethedstest/start`
    : `/portefoeljer/${portfolioId}/egnethedstest/mangler`

  let content
  if (suitabilityFlow === 'rebalancing') {
    content = <RebalancingContent firstName={firstName} startPath={startPath} />
  } else if (suitabilityFlow === 'must_update_suitability_test') {
    content = <SuitabilityMandatoryUpdateContent startPath={startPath} />
  } else if (suitabilityFlow === 'suitability_update') {
    content = <SuitabilityUpdateContent startPath={startPath} />
  }

  return (
    <Page>
      <Card>
        <Card.Body>{content}</Card.Body>
      </Card>
    </Page>
  )
}

export default Start
