import { configureStore, combineReducers } from '@reduxjs/toolkit'

import { getConfig } from '../configuration'

import { errorReducer } from './api/errorSlice'
import createCurrentReducer from './current'

const debug = getConfig('debug')

const devToolsOptions = debug
  ? {
      trace: true,
      traceLimit: 20,
    }
  : false

const createStore = ({ extraReducers, ...options }: any) => {
  const { current: extraCurrentReducer, ...otherExtraReducers } = extraReducers || {}

  return configureStore({
    reducer: combineReducers({
      current: createCurrentReducer(extraCurrentReducer),
      error: errorReducer,
      ...otherExtraReducers,
    }),
    devTools: devToolsOptions,
    ...options,
  })
}

export default createStore
