import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'
import React, { useRef, useState, useCallback, useMemo } from 'react'

import { withQueryParam } from '../QueryParam'
import { sumPageCounts, calculateBeforeCounts, calculateAfterCounts } from '../utilities'

import AbsoluteWrapper from './AbsoluteWrapper'

const calculateNestedPageCounts = (
  currentNestedPageCounts: any,
  nestedIndex: any,
  newNestedPageCount: any,
) => {
  const currentNestedPageCount = get(currentNestedPageCounts, nestedIndex)

  if (isArray(currentNestedPageCount) || isEqual(currentNestedPageCount, newNestedPageCount))
    return currentNestedPageCounts

  const newNestedPageCounts = cloneDeep(currentNestedPageCounts)

  set(newNestedPageCounts, nestedIndex, newNestedPageCount)

  return newNestedPageCounts
}

export interface Props {
  children?: any // TODO: childrenOf(PropTypes.node)
  onBack?: (...args: any[]) => any
  onComplete?: (...args: any[]) => any
  onPageChange?: (...args: any[]) => any
}

const Wrapper = ({ children, ...otherProps }: Props) => {
  const nestedPageCountsRef = useRef([])
  const [nestedPageCounts, setNestedPageCounts] = useState([])

  const handleChangeAbsoluteCount = useCallback((nestedIndex: any, newNestedPageCount: any) => {
    nestedPageCountsRef.current = calculateNestedPageCounts(
      nestedPageCountsRef.current,
      nestedIndex,
      newNestedPageCount,
    )

    setNestedPageCounts(nestedPageCountsRef.current)
  }, [])

  const absolute = useMemo(
    () => ({
      count: sumPageCounts(nestedPageCounts),
      _onChangeAbsoluteCount: handleChangeAbsoluteCount,
      _beforeCounts: calculateBeforeCounts(nestedPageCounts),
      _afterCounts: calculateAfterCounts(nestedPageCounts),
      _pageCounts: nestedPageCounts,
    }),
    [handleChangeAbsoluteCount, nestedPageCounts],
  )

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: any; absolutePagination: { count... Remove this comment to see the full error message
    <AbsoluteWrapper navigationComponent={false} {...otherProps} absolutePagination={absolute}>
      {children}
    </AbsoluteWrapper>
  )
}

Wrapper.defaultProps = {
  children: undefined,
  onBack: undefined,
  onComplete: undefined,
  onPageChange: undefined,
}

Wrapper.displayName = 'Paginator.Nested.Wrapper'

export default Object.assign(Wrapper, {
  WithQueryParam: withQueryParam(Wrapper),
})
