import Sustainability from '@nord/ui/src/components/Sustainability'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import Card from 'react-bootstrap/Card'

import Page from '../../../../components/Page'

export interface WrapperProps {
  children: React.ReactNode
}

const Wrapper = ({ children }: WrapperProps) => {
  // @ts-expect-error TS(2339) FIXME: Property 'sustainabilityPreferencesOrder' does not... Remove this comment to see the full error message
  const { sustainabilityPreferencesOrder } = useObjectSelector(selectCurrentUser)

  const userWithoutPreferences = isEmpty(sustainabilityPreferencesOrder)

  const pageProps = {}

  // @ts-expect-error TS(2339) FIXME: Property 'xl' does not exist on type '{}'.
  if (userWithoutPreferences) pageProps.xl = { span: 8, offset: 2 }

  return (
    <Page {...pageProps}>
      <Card>
        <Card.Body>{children}</Card.Body>
      </Card>
    </Page>
  )
}

export interface SustainabilityPreferencesSortingProps {
  onComplete: (...args: any[]) => any
  onSustainabilityPreferencesOrderChange: (...args: any[]) => any
}

const SustainabilityPreferencesSorting = ({
  onComplete,
  onSustainabilityPreferencesOrderChange,
}: SustainabilityPreferencesSortingProps) => {
  const handleSubmit = (newSustainabilityPreferencesOrder: any) => {
    onSustainabilityPreferencesOrderChange(newSustainabilityPreferencesOrder)
    onComplete()
  }

  return (
    <Sustainability.Sorting
      // @ts-expect-error TS(2322) FIXME: Type '({ children }: WrapperProps) => JSX.Element'... Remove this comment to see the full error message
      wrapperComponent={Wrapper}
      onSubmit={handleSubmit}
      submitButtonText="Fortsæt"
      withSubmitButton
    />
  )
}

export default SustainabilityPreferencesSorting
