import { createSelector } from '@reduxjs/toolkit'

import createDeepEqualSelector from '../../../utilities/createDeepEqualSelector'

export const selectPortfoliosData = (state: any) => state.current.portfolios

export const selectPortfolios = createSelector(selectPortfoliosData, (portfoliosData) =>
  Object.values(portfoliosData),
)

export const selectPortfolioIds = createDeepEqualSelector(
  (state: any) => Object.keys(selectPortfoliosData(state)).map(Number),
  (portfolioIds: any) => portfolioIds,
)

export const selectPortfolioById = (id: any) => (state: any) => state.current.portfolios[id]
