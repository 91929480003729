import { useEffect, useCallback } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import useInitial from '../../../hooks/useInitial'
import useQuery from '../../../hooks/useQuery'

const rewriteQueryParam = (search: any, pageNumber = undefined) => {
  if (search && search.includes('page=') && pageNumber === undefined)
    return search.replace(/page=\d+/, '')
  if (pageNumber === undefined) return search

  if (search && search.includes('page=')) return search.replace(/page=\d+/, `page=${pageNumber}`)
  if (search) return `${search}&page=${pageNumber}`

  return `?page=${pageNumber}`
}

const usePageQueryParam = (options = {}) => {
  // @ts-expect-error TS(2339) FIXME: Property 'history' does not exist on type '{}'.
  const { history: historyOption, useAbsolute, useOnLoad } = options
  const routerHistory = useHistory()
  const history = historyOption || routerHistory
  const pageQuery = useQuery('page')
  const pageQueryNumber = parseInt(pageQuery, 10) || undefined
  const initialPageNumber = useInitial(pageQueryNumber)

  useEffect(() => {
    if (useOnLoad) return

    const {
      location: { search, pathname },
    } = window
    const newSearch = rewriteQueryParam(search)

    history.replace({
      pathname,
      search: newSearch,
    })
  }, [history, useOnLoad])

  const handlePageChange = useCallback(
    (currentPagination: any) => {
      const { absolute, ...pagination } = currentPagination
      const { number: pageNumber } = useAbsolute ? absolute : pagination
      const {
        location: { search, pathname },
      } = window
      const newSearch = rewriteQueryParam(search, pageNumber)

      history.push({
        pathname,
        search: newSearch,
      })
    },
    [history, useAbsolute],
  )

  return [handlePageChange, useOnLoad ? initialPageNumber : undefined]
}

export default usePageQueryParam
