import Card from '@nord/ui/src/components/Card'
import LoginForm from '@nord/ui/src/components/LoginForm'
import React from 'react'

import Page from '../../../components/Page'

const LoginPage = () => (
  <Page>
    <Card>
      <Card.Body>
        <LoginForm />
      </Card.Body>
    </Card>
  </Page>
)

export default LoginPage
