import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import styles from './DescriptionList.module.scss'

export interface ListItemProps {
  label: React.ReactNode
  value: React.ReactNode
}

const ListItem = ({ label, value, ...props }: ListItemProps) => (
  <>
    <Col as="dt" {...props}>
      {label}
    </Col>
    <Col as="dd">{value}</Col>
  </>
)

ListItem.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  xs: 6,
}

export interface DescriptionListProps {
  children?: React.ReactNode
  className?: string
}

const DescriptionList = ({ children, className }: DescriptionListProps) => (
  <Row as="dl" className={classNames(styles.descriptionList, 'text-left', className)}>
    {children}
  </Row>
)

DescriptionList.defaultProps = {
  children: undefined,
  className: undefined,
}

DescriptionList.Item = ListItem

export default DescriptionList
