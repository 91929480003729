import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

type Props = {
  children?: React.ReactNode
}

const Page = ({ children, ...otherProps }: Props) => (
  <Row>
    <Col
      xs={12}
      md={{ offset: 1, span: 10 }}
      lg={{ offset: 2, span: 8 }}
      xl={{ offset: 3, span: 6 }}
      className="px-2 px-sm-3"
      {...otherProps}
    >
      {children}
    </Col>
  </Row>
)

Page.defaultProps = {
  children: undefined,
}

export default Page
