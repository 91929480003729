import React from 'react'

import extractProps from '../../../utilities/extractProps'

import Buttons from './Buttons'
import Container from './Container'

const buttonPropKeys = ['nextButton', 'previousButton', 'showOnFirst', 'showOnLast']

const Navigation = ({ ...otherProps }) => {
  const [buttonProps, containerProps] = extractProps(otherProps, buttonPropKeys)

  return (
    <Container {...containerProps}>
      <Buttons {...buttonProps} />
    </Container>
  )
}

Navigation.displayName = 'Paginator.Navigation'

export default Navigation
