import { yupToFormErrors } from 'formik'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { object } from 'yup'

import useAutoUpdateRef from '../../../hooks/useAutoUpdateRef'
import { useFieldsList, getInitialValues } from '../../Form'
import Page from '../Page'

import FormNavigation from './Navigation'
import PaginatedFormContext from './PaginatedFormContext'
import FormWrapperContainer from './WrapperContainer'

const withPaginatedForm = (WrappedComponent: any) => {
  const WrapperComponent = ({
    containerComponent,
    validateOnComplete,
    loading,
    onFinalSubmit,
    initialValues: initialValuesProp,
    enableReinitialize,
    ...otherProps
  }: any) => {
    const [formProps, setFormProps] = useState([])
    const [initialValues, setInitialValues] = useState()
    const formPropsRef = useAutoUpdateRef(formProps)

    const combinedFormProps = useMemo(
      () =>
        formProps.reduce(
          // @ts-expect-error TS(2322) FIXME: Type '{}' is not assignable to type 'never'.
          (currentFormProps, { validationSchema, labelDescriptions, fields } = {}) => ({
            validationSchema: currentFormProps.validationSchema.concat(validationSchema),
            labelDescriptions: {
              ...currentFormProps.labelDescriptions,
              // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
              ...labelDescriptions,
            },
            fields: currentFormProps.fields.concat(fields),
          }),
          {
            validationSchema: object(),
            labelDescriptions: {},
            fields: [],
          },
        ),
      [formProps],
    )

    const fields = useFieldsList(combinedFormProps)
    const previousFieldsRef = useRef(fields)

    useEffect(() => {
      if (isEmpty(fields)) return
      if (!enableReinitialize && isEqual(fields, previousFieldsRef.current)) return

      const calculatedInitialValues = getInitialValues({
        fields,
        validationSchema: combinedFormProps.validationSchema,
        initialValues: initialValuesProp,
      })

      previousFieldsRef.current = fields
      setInitialValues(calculatedInitialValues)
    }, [
      fields,
      enableReinitialize,
      initialValues,
      combinedFormProps.validationSchema,
      initialValuesProp,
    ])

    const handleChangeFormProps = useCallback(
      (pageIndex: any, pageFormProps: any) => {
        if (isEqual(pageFormProps, formPropsRef.current[pageIndex])) return

        const newFormProps = [...formPropsRef.current]
        newFormProps[pageIndex] = pageFormProps

        formPropsRef.current = newFormProps

        // @ts-expect-error TS(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
        setFormProps(newFormProps)
      },
      [formPropsRef],
    )

    const handleSubmitWithCompleteValidation = useCallback(
      async (_newValues: any, formik: any, allNewValues: any) => {
        try {
          formPropsRef.current.forEach((pageFormProps: any, currentPageIndex: any) => {
            const { validationSchema } = pageFormProps || {}
            if (!validationSchema) return

            try {
              validationSchema.validateSync(allNewValues, {
                abortEarly: false,
              })
            } catch (error) {
              // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
              error.pageIndex = currentPageIndex

              throw error
            }
          })
        } catch (error) {
          const { setErrors, setTouched } = formik

          const formErrors = yupToFormErrors(error)
          const currentFields = Object.keys(formErrors)
          const formTouched = currentFields.reduce((result, field) => {
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            result[field] = true

            return result
          }, {})
          setTouched(formTouched, false)
          setErrors(formErrors)

          return Promise.reject(error)
        }

        return Promise.resolve()
      },
      [formPropsRef],
    )

    const handleFinalSubmit = useCallback(
      async (newValues: any, formik: any, allNewValues: any) => {
        if (validateOnComplete)
          await handleSubmitWithCompleteValidation(newValues, formik, allNewValues)
        if (onFinalSubmit) await onFinalSubmit(newValues, formik, allNewValues)
      },
      [handleSubmitWithCompleteValidation, onFinalSubmit, validateOnComplete],
    )

    const context = useMemo(
      () => ({
        formProps,
        validateOnComplete,
        onChangeFormProps: handleChangeFormProps,
        onFinalSubmit: handleFinalSubmit,
      }),
      [formProps, handleChangeFormProps, handleFinalSubmit, validateOnComplete],
    )

    // @ts-expect-error TS(2322) FIXME: Type '{ loading: true; }' is not assignable to typ... Remove this comment to see the full error message
    if (loading) otherProps.pages = [<Page loading />]

    return (
      // @ts-expect-error TS(2322) FIXME: Type '{ formProps: never[]; validateOnComplete: an... Remove this comment to see the full error message
      <PaginatedFormContext.Provider value={context}>
        <WrappedComponent
          navigationComponent={FormNavigation}
          containerComponent={FormWrapperContainer}
          externalContainerComponent={containerComponent}
          loading={loading}
          initialValues={initialValues}
          enableReinitialize
          {...otherProps}
        />
      </PaginatedFormContext.Provider>
    )
  }

  WrapperComponent.displayName = `withPaginatedForm(${
    WrappedComponent.displayName || WrappedComponent.name
  })`

  WrapperComponent.propTypes = {
    containerComponent: PropTypes.elementType,
    enableReinitialize: PropTypes.bool,
    initialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    loading: PropTypes.bool,
    onFinalSubmit: PropTypes.func,
    validateOnComplete: PropTypes.bool,
  }

  WrapperComponent.defaultProps = {
    containerComponent: undefined,
    enableReinitialize: false,
    initialValues: undefined,
    loading: undefined,
    onFinalSubmit: undefined,
    validateOnComplete: true,
  }

  return WrapperComponent
}

export default withPaginatedForm
