import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import useApi, { useApiRequest } from '../../../hooks/useApi'
import useObjectSelector from '../../../hooks/useObjectSelector'
import useUncontrollableProp from '../../../hooks/useUncontrollableProp'
import { selectCurrentUser } from '../../../store/current/user'
import BaffleButton from '../../BaffleButton'
import Loader from '../../Loader'
import Text from '../../Text'

import OneColSorting from './OneColSorting'
import SortingContext from './SortingContext'
import TwoColSorting from './TwoColSorting'
import { getValues } from './utilities'

const Header = () => (
  <Row>
    <Col xs={12}>
      <Text as="h4" align="center">
        Bæredygtighedspræferencer
      </Text>
    </Col>
  </Row>
)

export interface SortingProps {
  error?: string
  headerComponent?: React.ReactElement
  items?: any
  onOrderChange?: (...args: any[]) => any
  onSubmit?: (...args: any[]) => any
  submitButtonProps?: any
  submitButtonText?: string
  withSubmitButton?: boolean
  wrapperComponent?: React.ReactElement
}

const Sorting = ({
  error,
  headerComponent: HeaderComponent,
  items,
  onOrderChange,
  onSubmit,
  submitButtonText,
  submitButtonProps,
  withSubmitButton,
  wrapperComponent: WrapperComponent,
  ...otherProps
}: SortingProps) => {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
  const [internalItems, onInternalOrderChange] = useUncontrollableProp(items, false, onOrderChange)
  const [isInvalid, setIsInvalid] = useState(false)

  // @ts-expect-error TS(2339) FIXME: Property 'sustainabilityPreferencesOrder' does not... Remove this comment to see the full error message
  const { sustainabilityPreferencesOrder } = useObjectSelector(selectCurrentUser)

  const fetchSustainabilityPreferences = useApi(
    '/reference_data/users/sustainability_preferences_order',
    {
      method: 'GET',
    },
  )

  const { data: sustainabilityPreferences, loading } = useApiRequest(
    fetchSustainabilityPreferences,
    {
      autoCall: true,
    },
  )

  useEffect(() => {
    if (loading) return

    if (isEmpty(sustainabilityPreferencesOrder)) {
      const defaultOptions = getValues(sustainabilityPreferences)

      onInternalOrderChange({ unordered: defaultOptions, ordered: [] })

      return
    }

    onInternalOrderChange({ ordered: sustainabilityPreferencesOrder })
  }, [loading, sustainabilityPreferences, sustainabilityPreferencesOrder, onInternalOrderChange])

  const internalIsInvalid = !!error || isInvalid
  const internalErrorMessage = error || 'Du skal prioritere alle seks miljømål'

  const context = useMemo(
    () => ({
      isInvalid: internalIsInvalid,
      items: internalItems,
      setItems: onInternalOrderChange,
      sustainabilityPreferences,
    }),
    [onInternalOrderChange, internalIsInvalid, internalItems, sustainabilityPreferences],
  )

  useEffect(() => {
    if (!isEmpty(sustainabilityPreferencesOrder)) return
    if (!internalItems) return
    if (internalItems.ordered.length !== 6) return

    setIsInvalid(false)
  }, [internalItems, sustainabilityPreferencesOrder])

  const handleSubmit = async () => {
    const newSustainabilityPreferencesOrder = internalItems.ordered

    if (newSustainabilityPreferencesOrder.length !== 6) {
      setIsInvalid(true)

      return
    }

    if (onSubmit) onSubmit(newSustainabilityPreferencesOrder)
  }

  const userWithoutPreferences = isEmpty(sustainabilityPreferencesOrder)

  let content

  if (loading || !internalItems) content = <Loader className="text-center font-size-lg" />
  // @ts-expect-error TS(2322) FIXME: Type '{ isInvalid: boolean; }' is not assignable t... Remove this comment to see the full error message
  else if (userWithoutPreferences) content = <TwoColSorting isInvalid={internalIsInvalid} />
  else content = <OneColSorting />

  return (
    // @ts-expect-error TS(2604) FIXME: JSX element type 'WrapperComponent' does not have ... Remove this comment to see the full error message
    <WrapperComponent {...otherProps}>
      {/* @ts-expect-error TS(2604) FIXME: JSX element type 'HeaderComponent' does not have a... Remove this comment to see the full error message */}
      <HeaderComponent />
      <Row>
        <Col xs={12}>
          <Text as="p" variant="secondary">
            Prioritér nedenstående miljømål i forhold til hvor vigtigt det enkelte mål er for din
            investering med det vigtigste mål øverst
          </Text>
          {userWithoutPreferences && (
            <Text as="p" align="center" variant="secondary" className="d-lg-none">
              Træk (eller brug +) til at sortere din præferencer fra vigtigst til mindst vigtig
            </Text>
          )}
        </Col>
        {internalIsInvalid && (
          <Col xs={12}>
            <Text
              as="p"
              align="center"
              variant="danger"
              data-cy="sustainabilityPreferencesOrderError"
            >
              {internalErrorMessage}
            </Text>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12}>
          <SortingContext.Provider value={context}>{content}</SortingContext.Provider>
        </Col>
      </Row>
      {withSubmitButton && (
        <Row>
          <Col xs={12} className="d-flex justify-content-center my-3">
            <BaffleButton onClick={handleSubmit} {...submitButtonProps}>
              {submitButtonText}
            </BaffleButton>
          </Col>
        </Row>
      )}
    </WrapperComponent>
  )
}

Sorting.defaultProps = {
  error: undefined,
  headerComponent: Header,
  items: undefined,
  onOrderChange: undefined,
  onSubmit: undefined,
  submitButtonProps: {},
  submitButtonText: 'Godkend',
  withSubmitButton: false,
  wrapperComponent: React.Fragment,
}

export default Sorting
