import { useMemo } from 'react'

import getApiClient from '../../utilities/getApiClient'

const useApiClient = (baseURL: any) => {
  const apiClient = useMemo(() => getApiClient({ baseURL }), [baseURL])

  return apiClient
}

export default useApiClient
