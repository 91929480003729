import classNames from 'classnames'
import isFunction from 'lodash/isFunction'
import React from 'react'

import PageContainer from './PageContainer'
import usePageTransition from './usePageTransition'
import usePaginationContext from './usePaginationContext'

export interface PageProps {
  active?: boolean
  alwaysRender?: boolean
  animationRef?: any // TODO: refProp()
  children?: React.ReactNode
  containerComponent?: React.ComponentType<any> | false
  exit?: boolean
  pageIndex?: number
  render?: any // TODO: requireOneOf(PropTypes.func, 'children')
}

const Page = ({
  render,
  children,
  alwaysRender,
  pageIndex,
  containerComponent: ContainerComponent,
  ...otherProps
}: PageProps) => {
  const { animationRef, active, exit } = usePageTransition(pageIndex)
  const pagination = usePaginationContext()
  const shouldRender = active || exit || alwaysRender
  const childrenIsFunction = isFunction(children)

  let content = null
  if (childrenIsFunction) content = children(pagination)
  else if (children) content = children
  else if (render) content = render(pagination)

  if (ContainerComponent)
    content = <ContainerComponent {...otherProps}>{content}</ContainerComponent>

  return (
    <div
      ref={animationRef}
      className={classNames({
        'd-none': !active,
      })}
    >
      {shouldRender && content}
    </div>
  )
}

Page.defaultProps = {
  active: false,
  alwaysRender: false,
  containerComponent: PageContainer,
  exit: false,
}

Page.displayName = 'Paginator.Page'

export default Page
