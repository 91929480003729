import React from 'react'
import { Provider } from 'react-redux'

import createStore from '../../../../store'

import InnerReduxProxy from './InnerReduxProxy'

export interface Props {
  children: React.ReactNode
  extraReducers?: {
    [key: string]: (...args: any[]) => any
  }
}

const ReduxProvider = ({ children, extraReducers, ...otherProps }: Props) => (
  <Provider store={createStore({ extraReducers })}>
    <InnerReduxProxy {...otherProps}>{children}</InnerReduxProxy>
  </Provider>
)

ReduxProvider.defaultProps = {
  extraReducers: undefined,
}

ReduxProvider.displayName = 'ApplicationWrapper.Plugins.ReduxProvider'

export default ReduxProvider
