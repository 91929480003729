import anime from 'animejs'
import React, { useRef, useImperativeHandle } from 'react'

const xDiff = 200
const duration = 300
const buildEnterAnimation = (forwards = true) => ({
  translateX: forwards ? [xDiff, 0] : [-xDiff, 0],
  opacity: [0, 1],
  easing: 'easeOutQuad',
  duration,
})

const buildExitAnimation = (forwards = true) => ({
  translateX: forwards ? -xDiff : xDiff,
  opacity: 0,
  easing: 'easeInQuad',
  duration,
})

const enterAnimationOptions = {
  forwards: buildEnterAnimation(true),
  backwards: buildEnterAnimation(false),
}

const exitAnimationOptions = {
  forwards: buildExitAnimation(true),
  backwards: buildExitAnimation(false),
}

const startAnimation = (element: any, currentElementRef: any, options: any) => {
  if (!element) return
  if (element === currentElementRef.current) return
  currentElementRef.current = element

  const newAnimation = anime({
    targets: element,
    ...options,
  })
  newAnimation.play()
}

export interface TransitionWrapperProps {
  children: React.ReactElement | null
  afterPageChange?(): void
  beforePageChange?(): void
  onTransitionChange(): void
}

const TransitionWrapper = (
  { children, onTransitionChange, beforePageChange, afterPageChange }: TransitionWrapperProps,
  ref: React.ForwardedRef<unknown>,
) => {
  const enterAnimatedElementRef = useRef()
  const exitAnimatedElementRef = useRef()

  useImperativeHandle(ref, () => ({
    enterAnimation: (element: any, direction: any) => {
      startAnimation(element, enterAnimatedElementRef, {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ...enterAnimationOptions[direction],
        complete: (currentAnimation: any) => {
          currentAnimation.animatables.forEach((animatable: any) => {
            animatable.target.removeAttribute('style')
          })

          if (afterPageChange) afterPageChange()
        },
      })
    },
    exitAnimation: (element: any, direction: any) => {
      startAnimation(element, exitAnimatedElementRef, {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ...exitAnimationOptions[direction],
        begin: () => {
          if (beforePageChange) beforePageChange()
        },
        complete: () => {
          onTransitionChange()
        },
      })
    },
  }))

  return children
}

TransitionWrapper.displayName = 'Paginator.TransitionWrapper'

export default React.forwardRef(TransitionWrapper)
