import React from 'react'

import Container, { type ContainerProps } from './Container'
import Link from './Link'
import Logo from './Logo'
import StickyContainer, { type StickyContainerProps } from './StickyContainer'

export type HeaderProps =
  | ({ sticky: true } & StickyContainerProps)
  | ({ sticky?: false } & ContainerProps)

const Header = ({ children, sticky, ...otherProps }: HeaderProps) => {
  if (sticky) return <StickyContainer {...otherProps}>{children}</StickyContainer>

  return <Container {...otherProps}>{children}</Container>
}

Header.Logo = Logo
Header.Link = Link

export default Header
