import Card from '@nord/ui/src/components/Card'
import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import React from 'react'

import MyAccountButton from '../../../../components/MyAccountButton'
import Page from '../../../../components/Page'
import PortfolioTitle from '../../../../components/PortfolioTitle'
import usePortfolioId from '../../../../hooks/usePortfolioId'

const webappLink = getConfig('domains.app')

const SuitabilityUpdateCompleted = () => {
  const portfolioId = usePortfolioId()

  return (
    <Page>
      <Card>
        <Card.Body>
          <Text as="h3" size="xxxl" weight="bold">
            Dine oplysninger er nu opdateret
          </Text>
          <PortfolioTitle />
          <Text as="p">Der er ikke nogen væsentlige ændringer i vores risikovurdering.</Text>
          <Text as="p">
            Hvis der sker væsentlige ændringer i din økonomi, tidshorisont eller risikovillighed, så
            anbefaler vi, at du igen opdaterer din. Du kan altid finde et link til det i vores{' '}
            <Text as="a" href={webappLink}>
              app
            </Text>{' '}
            under{' '}
            <Text as="a" href={`${webappLink}/portefoeljer/${portfolioId}`}>
              "Mere"
            </Text>
            .
          </Text>
          <div className="mt-5">
            <MyAccountButton />
          </div>
        </Card.Body>
      </Card>
    </Page>
  )
}

export default SuitabilityUpdateCompleted
