import React from 'react'
import FormControl from 'react-bootstrap/FormControl'

import chainEventHandler from '../utilities/chainEventHandler'

import withCountrySelect from './withCountrySelect'

export interface Props {
  onChange?: (...args: any[]) => any
  onValueChange?: (...args: any[]) => any
}

const PhoneInput = ({ onChange, onValueChange, ...otherProps }: Props) => {
  const handleChange = chainEventHandler((event: any) => {
    if (onValueChange) onValueChange(event.target.value)
  }, onChange)

  return <FormControl onChange={handleChange} type="tel" {...otherProps} />
}

PhoneInput.defaultProps = {
  onChange: undefined,
  onValueChange: undefined,
}

PhoneInput.WithCountrySelect = withCountrySelect(PhoneInput, {
  defaultItem: { phone: '45', country: 'Danmark', code: 'DK' },
})

export default PhoneInput
