import React from 'react'

import BaffleButton from '../../BaffleButton'
import useInternalContext from '../useInternalContext'

export interface Props {
  children?: React.ReactNode
}

const PreviousButton = ({ children, ...otherProps }: Props) => {
  // @ts-expect-error TS(2339) FIXME: Property 'onPrevious' does not exist on type 'unkn... Remove this comment to see the full error message
  const { onPrevious } = useInternalContext()

  return (
    <BaffleButton
      onClick={onPrevious}
      variant="link"
      className="text-secondary"
      data-cy="previous-button"
      {...otherProps}
    >
      {children}
    </BaffleButton>
  )
}

PreviousButton.defaultProps = {
  children: 'Tilbage',
}

PreviousButton.displayName = 'Paginator.Navigation.PreviousButton'

export default PreviousButton
