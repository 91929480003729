import React from 'react'

import Loader from '../Loader'

export interface Props {
  children?: React.ReactNode
}

const LoadingIndicator = ({ children, ...otherProps }: Props) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ className: string; size: number; spin: tru... Remove this comment to see the full error message
  <Loader className="mt-3 text-center" size={2} spin {...otherProps} />
)

LoadingIndicator.defaultProps = {
  children: undefined,
}

LoadingIndicator.displayName = 'SectionContainer.LoadingIndicator'

export default LoadingIndicator
