import React from 'react'

import usePaginationContext from '../usePaginationContext'

import NextButton from './NextButton'
import PreviousButton from './PreviousButton'

export interface Props {
  nextButton?: React.ReactNode
  previousButton?: React.ReactNode
  showOnLast?: boolean
  showOnFirst?: boolean
}

const Buttons = ({ nextButton, previousButton, showOnFirst, showOnLast }: Props) => {
  const { first, last } = usePaginationContext()

  return (
    <>
      {previousButton !== false &&
        (!first || showOnFirst) &&
        (previousButton || <PreviousButton />)}
      {nextButton !== false && (!last || showOnLast) && (nextButton || <NextButton />)}
    </>
  )
}

Buttons.defaultProps = {
  nextButton: undefined,
  previousButton: undefined,
  showOnFirst: false,
  showOnLast: false,
}

Buttons.displayName = 'Paginator.Navigation.Buttons'

export default Buttons
