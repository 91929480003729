import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowLeft from 'react-feather/dist/icons/arrow-left'

import useApi, { useApiRequest } from '../../hooks/useApi'
import useQuery from '../../hooks/useQuery'
import Form from '../Form'
import IconButtonLink from '../IconButtonLink'
import Text from '../Text'

import validationSchema from './validationSchema'

const ForgotPasswordForm = ({ ...otherProps }) => {
  const [alert, setAlert] = useState({})
  const { email: emailParam, redirectTo } = useQuery()
  const initialValues = { username: emailParam }

  const resetPassword = useApi('/users/password', {
    method: 'POST',
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const { request: callResetPassword } = useApiRequest(resetPassword)

  const handleSubmit = async ({ username: email }: { username: string }) => {
    setAlert({})
    const { success } = await callResetPassword({ user: { email } })

    setAlert({
      variant: success ? 'success' : 'danger',
      message: success
        ? `Vi har sendt en email til ${email}, med et link til at
          nulstille din adgangskode.`
        : `Der findes ingen bruger med denne email: ${email}.`,
    })
  }

  return (
    <>
      <Text as="h2" align="center" size="xl" className="mb-5">
        Har du glemt din adgangskode?
      </Text>
      <Text as="p" variant="secondary" align="center">
        Angiv din email, og vælg "nulstil din adgangskode", så sender vi dig et link til at oprette
        en ny.
      </Text>
      {!isEmpty(alert) && (
        // @ts-expect-error TS(2339) FIXME: Property 'variant' does not exist on type '{}'.
        <Alert variant={alert.variant} className="text-center">
          {/* @ts-expect-error TS(2339) FIXME: Property 'message' does not exist on type '{}'. */}
          {alert.message}
        </Alert>
      )}
      <Form
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; validationSchema: Obj... Remove this comment to see the full error message
        validateOnMount
        {...otherProps}
      >
        <Form.FieldGroup label="Email" name="username">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ autoFocus: true; }' is not assignable to t... Remove this comment to see the full error message */}
          <Form.Field autoFocus />
        </Form.FieldGroup>
        <div className="d-flex align-items-center flex-column-reverse justify-content-lg-between flex-lg-row">
          <IconButtonLink
            variant="link"
            iconPlacement="first"
            icon={ArrowLeft}
            className="pl-lg-0"
            to={{
              pathname: '/brugere/logind',
              search: `?${queryString.stringify({
                // eslint-disable-next-line camelcase
                redirect_to: redirectTo,
                email: emailParam,
              })}`,
            }}
          >
            Log ind
          </IconButtonLink>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; disable... Remove this comment to see the full error message */}
          <Form.SubmitButton variant="primary" disableOnInvalid>
            Nulstil din adgangskode
          </Form.SubmitButton>
        </div>
      </Form>
    </>
  )
}

export default ForgotPasswordForm
