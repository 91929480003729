import classNames from 'classnames'
import React, { useState } from 'react'

import chainEventHandler from '../utilities/chainEventHandler'
import { requireAtLeastOneOf } from '../utilities/propTypes'

const DEFAULT_CUSTOM = true
const DEFAULT_LABEL = 'Vælg fil...'

export interface Props {
  className?: string
  custom?: boolean
  id?: string
  label?: string
  name?: any // TODO: requireAtLeastOneOf(PropTypes.string, 'id')
  onChange?: (...args: any[]) => any
  onValueChange?: (...args: any[]) => any
  upload: (...args: any[]) => any
}

const FileInput = ({
  onValueChange,
  onChange,
  upload,
  label,
  id,
  name,
  custom,
  className,
  ...otherProps
}: Props) => {
  const [value, setValue] = useState(null)

  const handleChange = chainEventHandler((event: any) => {
    const { files } = event.target
    const filesArray = Array.from(files)

    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    const newValue = filesArray.map((file) => file.name).join(', ')
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setValue(newValue)

    if (onValueChange) onValueChange(files)
    if (upload) filesArray.forEach((file) => upload(file))
  }, onChange)

  const inputContent = (
    <input
      type="file"
      className={classNames(className, { 'custom-file-input': custom })}
      id={id || name}
      name={name}
      onChange={handleChange}
      {...otherProps}
    />
  )

  if (custom) {
    return (
      <div className="custom-file">
        {inputContent}
        <label className="custom-file-label" htmlFor={id || name}>
          {value || label}
        </label>
      </div>
    )
  }

  return inputContent
}

FileInput.defaultProps = {
  className: '',
  custom: DEFAULT_CUSTOM,
  id: undefined,
  label: DEFAULT_LABEL,
  name: undefined,
  onChange: undefined,
  onValueChange: undefined,
}

export default FileInput
