import React from 'react'

const DEFAULT_COLOR = 'currentColor'
const DEFAULT_SIZE = 24

export interface Props {
  color?: string
  size?: string | number
}

const CircleNotch = ({ color, size, ...otherProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...otherProps}
  >
    <path d="M20.49 15a9 9 0 1 1-2.12-9.36L20.49 9" />
  </svg>
)

CircleNotch.defaultProps = {
  color: DEFAULT_COLOR,
  size: DEFAULT_SIZE,
}

export default CircleNotch
