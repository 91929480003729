import Text from '@nord/ui/src/components/Text'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import classNames from 'classnames'
import React from 'react'

const PortfolioTitle = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'unknown'.
  const { title, description, customTitle } = useObjectSelector(selectCurrentPortfolio)

  return (
    <>
      <Text
        as="h5"
        weight="bold"
        className={classNames({
          'pb-3': !customTitle,
        })}
        size="xxl"
      >
        {title}
      </Text>
      {customTitle && (
        <Text as="h6" weight="bold" variant="secondary" className="pb-3">
          {description}
        </Text>
      )}
    </>
  )
}

export default PortfolioTitle
