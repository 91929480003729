import { createSlice } from '@reduxjs/toolkit'
import groupBy from 'lodash/groupBy'
import head from 'lodash/head'
import mapValues from 'lodash/mapValues'

import { updateSingleRecordFromApiResponse } from '../../helpers'
import { signOutUser } from '../user/actions'
import { userSignedOut } from '../user/slice'

import { fetchPortfolios, updatePortfolio } from './actions'

export const initialState = {}

export const portfoliosSlice = createSlice({
  name: 'current.portfolios',
  initialState,
  reducers: {
    changePortfolio: (state, { payload: { portfolioId, attributes } }) =>
      updateSingleRecordFromApiResponse(state, {
        payload: { id: portfolioId, ...attributes },
      }),
  },
  extraReducers: {
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [fetchPortfolios.fulfilled]: (_state: any, { payload: data }) => {
      const formattedData = mapValues(groupBy(data, 'id'), head)

      return formattedData
    },
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [updatePortfolio.fulfilled]: updateSingleRecordFromApiResponse,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [signOutUser.fulfilled]: () => initialState,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [userSignedOut]: () => initialState,
  },
})

export const { changePortfolio } = portfoliosSlice.actions

export default portfoliosSlice
