import React, { useContext, useEffect, useMemo } from 'react'

import Context from '../Context'
import Page from '../Page'
import { withQueryParam } from '../QueryParam'
import { getPageKey } from '../utilities'

import AbsoluteWrapper from './AbsoluteWrapper'

export interface Props {
  active: boolean
  children?: React.ReactNode
  hasNested?: boolean
  pageIndex: number
}

const PagesWrapper = ({ children, pageIndex, hasNested, active, ...otherProps }: Props) => {
  const pagination = useContext(Context)
  // @ts-expect-error TS(2339) FIXME: Property 'absolute' does not exist on type 'unknow... Remove this comment to see the full error message
  const { absolute } = pagination
  const { _parentKey: parentKey, _onChangeAbsoluteCount: onChangeAbsoluteCount } = absolute
  const nestedParentKey = getPageKey(pageIndex, parentKey)
  const nestedPageCount = React.Children.count(children)

  useEffect(() => {
    if (onChangeAbsoluteCount) onChangeAbsoluteCount(nestedParentKey, nestedPageCount)
  }, [nestedPageCount, onChangeAbsoluteCount, nestedParentKey])

  const newAbsoluteContext = useMemo(
    () => ({
      ...absolute,
      _parentKey: nestedParentKey,
    }),
    [absolute, nestedParentKey],
  )

  // @ts-expect-error TS(2339) FIXME: Property 'navigationComponent' does not exist on t... Remove this comment to see the full error message
  if (hasNested || !active) otherProps.navigationComponent = false

  return (
    <Page pageIndex={pageIndex} active={active} containerComponent={false} alwaysRender>
      <AbsoluteWrapper
        parentPagination={pagination}
        absolutePagination={newAbsoluteContext}
        // @ts-expect-error TS(2322) FIXME: Type '{ children: any; parentPagination: unknown; ... Remove this comment to see the full error message
        active={active}
        {...otherProps}
      >
        {children}
      </AbsoluteWrapper>
    </Page>
  )
}

PagesWrapper.defaultProps = {
  children: undefined,
  hasNested: false,
}

PagesWrapper.displayName = 'Paginator.Nested.PagesWrapper'

export default Object.assign(PagesWrapper, {
  WithQueryParam: withQueryParam(PagesWrapper),
})
