import { startsWith } from 'lodash'

const classNameKeys = [
  'appear',
  'appearActive',
  'appearDone',
  'enter',
  'enterActive',
  'enterDone',
  'exit',
  'exitActive',
  'exitDone',
]

const extractTransitionClasses = ({ styles, className, appearKey }: any) => {
  let capitalizedAppearKey: any
  if (appearKey) {
    capitalizedAppearKey = appearKey.charAt(0).toUpperCase() + appearKey.substring(1)
  }

  return classNameKeys.reduce((result, key) => {
    const capitalizedKey = key.charAt(0).toUpperCase() + key.substring(1)
    const classSpecificKey = `${className}${capitalizedKey}`
    const transitionClassName = styles[classSpecificKey]

    if (transitionClassName !== undefined) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[key] = transitionClassName
    } else if (capitalizedAppearKey && startsWith(key, 'appear')) {
      const enterKey = classSpecificKey.replace('Appear', capitalizedAppearKey)

      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[key] = styles[enterKey]
    }

    return result
  }, {})
}

export default extractTransitionClasses
