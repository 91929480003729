import { useField } from 'formik'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import React from 'react'
import Form from 'react-bootstrap/Form'

import { requireAtLeastOneOf } from '../../utilities/propTypes'

// @ts-expect-error TS(7023) FIXME: 'formatError' implicitly has return type 'any' bec... Remove this comment to see the full error message
const formatError = (error: any) => {
  if (isPlainObject(error)) return formatError(Object.values(error))
  if (isArray(error)) return error.join(' - ')

  return error
}

export interface ErrorMessageProps {
  alwaysRender?: boolean
  children?: React.ReactNode
  error?: string
  name?: any // TODO: requireAtLeastOneOf(PropTypes.string, 'error', 'children')
}

const ErrorMessage = ({ name, error: errorProp, children, alwaysRender }: ErrorMessageProps) => {
  const [, meta] = useField(name)
  const { error: formikError, touched } = meta

  const error = errorProp || formikError

  if (alwaysRender !== true && (!error || !touched)) return null

  return (
    <Form.Control.Feedback type="invalid" data-cy="validation-error">
      {formatError(error)}
      {children}
    </Form.Control.Feedback>
  )
}

ErrorMessage.displayName = 'Form.ErrorMessage'

ErrorMessage.defaultProps = {
  alwaysRender: undefined,
  children: undefined,
  error: undefined,
  name: undefined,
}

export default ErrorMessage
