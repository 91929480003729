import { partition, fromPairs } from 'lodash'

const extractProps = (props: any, childPropKeys: any) => {
  const [extractedProps, remainingProps] = partition(Object.entries(props), ([key, _value]) =>
    childPropKeys.includes(key),
  )

  return [fromPairs(extractedProps), fromPairs(remainingProps)]
}

export const htmlElementPropKeys = ['id', 'className', 'style', 'title']

export default extractProps
