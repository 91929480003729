import { useEffect } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useLocation } from 'react-router-dom'

import pageview from '../../../../utilities/pageview'

const usePageView = (enabled = true) => {
  const location = useLocation()

  useEffect(() => {
    if (enabled) pageview(location)
  }, [location, enabled])
}

export default usePageView
