import { createSlice } from '@reduxjs/toolkit'

import { initialUserLoad, signOutUser } from '../user/actions'
import { userSignedOut } from '../user/slice'

export const initialState = true

// @ts-expect-error TS(2345) FIXME: Argument of type '{ name: "current.loading"; initi... Remove this comment to see the full error message
export const loadingSlice = createSlice({
  name: 'current.loading',
  initialState,
  extraReducers: {
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [initialUserLoad.rejected]: () => false,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [initialUserLoad.fulfilled]: () => false,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [signOutUser.fulfilled]: () => false,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [userSignedOut]: () => false,
  },
})

export default loadingSlice
