import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import useInterval from '../../hooks/useInterval'
import Text from '../Text'

import List from './List'
import Upload from './Upload'
import useGetDocumentsApi from './useGetDocumentsApi'

const refreshInterval = 900000

export interface Props {
  onUpload?(files: object): void
}

const DocumentsForm = ({ onUpload, ...otherProps }: Props) => {
  const { documents, loading, refresh } = useGetDocumentsApi()

  const handleUpload = (files: object) => {
    if (onUpload) onUpload(files)

    refresh()
  }

  useInterval(refresh, {
    duration: refreshInterval,
  })

  return (
    <>
      <Row>
        <Col>
          <Text as="h4" align="center" className="mb-3">
            Upload ny dokumentation
          </Text>
          <Text as="p" align="center">
            Her kan du uploade dokumentation til Norm Invest eller Saxo Bank.
          </Text>
          {/* @ts-expect-error TS(2741) FIXME: Property 'email' is missing in type '{ onUpload: (... Remove this comment to see the full error message */}
          <Upload onUpload={handleUpload} {...otherProps} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Text as="h4" align="center" className="mt-5 mb-3">
            Dine dokumenter
          </Text>
          <List documents={documents} loading={loading} />
        </Col>
      </Row>
    </>
  )
}

DocumentsForm.defaultProps = {
  onUpload: undefined,
}

DocumentsForm.Upload = Upload
DocumentsForm.List = List

export { default as useStoreDocumentsApi } from './Upload/useStoreDocumentsApi'
export { default as useUploadDocumentApi } from './Upload/useUploadDocumentApi'
export default DocumentsForm
