import React from 'react'

import SectionContainer from '../SectionContainer'

export interface Props {
  children?: React.ReactNode
}

const Wrapper = ({ children }: Props) => <SectionContainer>{children}</SectionContainer>

Wrapper.defaultProps = {
  children: undefined,
}

Wrapper.displayName = 'PaginatedSection.Wrapper'

export default Wrapper
