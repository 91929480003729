import classNames from 'classnames'
import { useFormikContext } from 'formik'
import queryString from 'query-string'
import React from 'react'

import useQuery from '../../hooks/useQuery'
import { BaffleButtonLink } from '../IconButtonLink'
import Transition from '../Transition'

export interface Props {
  allowLogin: boolean
  children?: React.ReactNode
}

const Footer = ({ allowLogin, children }: Props) => {
  const redirectTo = useQuery('redirectTo')
  const {
    values: { username },
  } = useFormikContext<{ username: string }>()

  return (
    <div
      className={classNames('d-flex justify-content-center', {
        'flex-column-reverse justify-content-lg-between align-items-center flex-lg-row': children,
      })}
    >
      {children}
      <Transition.Expand in={allowLogin} key="formFooter">
        <BaffleButtonLink
          variant="link"
          className={classNames({ 'pr-lg-0': children })}
          to={{
            pathname: '/brugere/adgangskode/glemt',
            search: `?${queryString.stringify({
              // eslint-disable-next-line camelcase
              redirect_to: redirectTo,
              email: username,
            })}`,
          }}
          type={undefined}
        >
          Glemt adgangskode?
        </BaffleButtonLink>
      </Transition.Expand>
    </div>
  )
}

Footer.defaultProps = {
  children: undefined,
}

export default Footer
