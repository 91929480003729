import classNames from 'classnames'
import React from 'react'

import { inlineStyle } from '../../utilities/propTypes'

import styles from './index.module.scss'
import { ReactComponent as Investments } from './InvestmentsOnly.inline.svg'
import { ReactComponent as Logo } from './Logo.inline.svg'
import { ReactComponent as Nord } from './Nord.inline.svg'

export interface Props {
  className?: string
  investmentsOnly?: boolean
  nordOnly?: boolean
  size?: number
  style?: any // TODO: inlineStyle()
}

const NordLogo = ({ className, investmentsOnly, nordOnly, size, style, ...otherProps }: Props) => {
  let Component = Logo
  if (nordOnly) Component = Nord
  if (investmentsOnly) Component = Investments

  const styleProp = { ...style }
  if (size) styleProp.fontSize = `${size}rem`

  return (
    <Component className={classNames(styles.logo, className)} style={styleProp} {...otherProps} />
  )
}

NordLogo.defaultProps = {
  className: undefined,
  investmentsOnly: undefined,
  nordOnly: undefined,
  size: 1,
  style: undefined,
}

export default NordLogo
