import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import { selectHandleUnauthorizedError } from '../../../../store/api/errorSlice'
import { userSignedOut } from '../../../../store/current/user'

import ReduxProviderContext from './ReduxProviderContext'
import useErrorBoundary from './useErrorBoundary'
import useInitialUserLoad from './useInitialUserLoad'

export interface Props {
  children: React.ReactNode
  loadPortfolios?: boolean | string
  setPortfolioIdOnLoad?: boolean
  shouldAwaitInitialUserLoad?: boolean
}

const InnerReduxProxy = ({
  children,
  loadPortfolios,
  setPortfolioIdOnLoad,
  shouldAwaitInitialUserLoad,
}: Props) => {
  useErrorBoundary()
  useInitialUserLoad({
    loadPortfolios,
    setPortfolioId: setPortfolioIdOnLoad,
    shouldAwaitInitialUserLoad,
  })
  const history = useHistory()
  const dispatch = useDispatch()
  const handleUnauthorizedError = useSelector(selectHandleUnauthorizedError)

  const onUnauthorizedError = useCallback(() => {
    const {
      location: { pathname: redirectToPath },
    } = history

    dispatch(userSignedOut())
    history.push(`/brugere/logind?timeout=true&redirect_to=${redirectToPath}`)
  }, [dispatch, history])

  useEffect(() => {
    if (handleUnauthorizedError) onUnauthorizedError()
  }, [handleUnauthorizedError, onUnauthorizedError])

  const context = useMemo(
    () => ({
      onUnauthorizedError,
      loadPortfolios,
      setPortfolioIdOnLoad,
    }),
    [loadPortfolios, setPortfolioIdOnLoad, onUnauthorizedError],
  )

  return <ReduxProviderContext.Provider value={context}>{children}</ReduxProviderContext.Provider>
}

InnerReduxProxy.defaultProps = {
  loadPortfolios: true,
  setPortfolioIdOnLoad: true,
  shouldAwaitInitialUserLoad: false,
}

export default InnerReduxProxy
