import Sustainability from '@nord/ui/src/components/Sustainability'
import React from 'react'
import Card from 'react-bootstrap/Card'

import Page from '../../../../components/Page'

type Props = {
  onComplete: (...args: any[]) => any
  onAcceptLowerSustainabilityPreferenceChange: (...args: any[]) => any
}

const SustainabilityLevelWarning = ({
  onComplete,
  onAcceptLowerSustainabilityPreferenceChange,
}: Props) => {
  const handleSubmit = ({ acceptLowerSustainabilityPreference }: any) => {
    onAcceptLowerSustainabilityPreferenceChange(acceptLowerSustainabilityPreference)
    onComplete()
  }

  return (
    <Page>
      <Card>
        <Card.Body>
          <Sustainability.Warning
            // @ts-expect-error TS(2322) FIXME: Type '{ onSubmit: ({ acceptLowerSustainabilityPref... Remove this comment to see the full error message
            onSubmit={handleSubmit}
            submitButtonText="Fortsæt"
            withSubmitButton
          />
        </Card.Body>
      </Card>
    </Page>
  )
}

export default SustainabilityLevelWarning
