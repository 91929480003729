import { createAsyncThunk } from '@reduxjs/toolkit'
import pick from 'lodash/pick'

import getApiClient from '../../utilities/getApiClient'

const apiClient = getApiClient()

const serializeError = (error: any) =>
  pick(error, [
    'name',
    'message',
    'stack',
    'code',
    'isAxiosError',
    'response.data',
    'response.status',
    'response.statusText',
  ])

const apiRequestCall = createAsyncThunk(
  'apiRequestCall',
  async (
    data: {
      method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
      url: string
      data?: object
      params?: object
      withCredentials: boolean
      errorHandling?: unknown
    },
    { rejectWithValue },
  ) => {
    const { errorHandling, ...requestData } = data

    try {
      const { data: responseData } = await apiClient.request(requestData)

      return responseData
    } catch (error) {
      const serializedError = serializeError(error)

      // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
      return rejectWithValue(serializedError, { errorHandling })
    }
  },
)
export default apiRequestCall
