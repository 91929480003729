import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { object, boolean } from 'yup'

import BooleanInput from '../../BooleanInput'
import Form from '../../Form'
import Text from '../../Text'

const validationSchema = object().shape({
  acceptLowerSustainabilityPreference: boolean().required().accepted(),
})

const labels = {
  acceptLowerSustainabilityPreference: 'Jeg har læst og bekræfter',
}

const fields = Object.keys(labels)

const Header = () => (
  <Row>
    <Col xs={12}>
      <Text as="h4" size="xxxl">
        Bæredygtighedspræferencer
      </Text>
    </Col>
  </Row>
)

export interface WarningProps {
  headerComponent?: React.ReactElement
  submitButtonProps?: any
  submitButtonText?: string
  withSubmitButton?: boolean
  wrapperComponent?: React.ReactElement
}

const Warning = ({
  wrapperComponent: WrapperComponent,
  headerComponent: HeaderComponent,
  withSubmitButton,
  submitButtonText,
  submitButtonProps,
  ...otherProps
}: WarningProps) => (
  // @ts-expect-error TS(2604) FIXME: JSX element type 'WrapperComponent' does not have ... Remove this comment to see the full error message
  <WrapperComponent
    fields={fields}
    validationSchema={validationSchema}
    labelDescriptions={labels}
    {...otherProps}
  >
    {/* @ts-expect-error TS(2604) FIXME: JSX element type 'HeaderComponent' does not have a... Remove this comment to see the full error message */}
    <HeaderComponent />
    <Row>
      <Col xs={12}>
        <Text as="p">
          Norm Invests tilgang til investering er baseret på investering udelukkende i brede
          indeksfonde med lave omkostninger, som på den måde gør det muligt at få et markedsafkast.
        </Text>
        <Text as="p">
          Du har tilkendegivet, at det er vigtigt for dig, at du investerer i miljømæssigt
          bæredygtige aktiviteter samt angivet, hvilke miljømål du interesserer dig særligt for. På
          nuværende tidspunkt har Norm Invest desværre ikke mulighed for at kombinere investering i
          brede indeksfonde med et eller flere af de miljømål, der er vigtige for dig, idet Norm
          Invest ikke har fundet indeksfonde med et snævert fokus på miljømål, som passer ind i Norm
          invests investeringstilgang.
        </Text>
        <Text as="p" weight="bold">
          Trods en intention om at mine investeringer aktivt skal bidrage til indfrielse af et eller
          flere miljømål ønsker jeg min portefølje investeret igennem Norm invest, idet Norm invests
          tilgang til investering med fokus på markedsafkast og lave omkostninger er vigtigere for
          mig end at min investering aktivt bidrager til indfrielse et miljømål. Som en følge heraf
          bekræfter jeg hermed, at mine præferencer for porteføljen i forhold til bæredygtig
          investering er en investering, der generelt fremmer miljømæssige eller samfundssociale
          mål.
        </Text>
      </Col>
    </Row>
    <Row>
      <Col xs={12} className="text-center">
        <Form.FieldGroup name="acceptLowerSustainabilityPreference" label={false}>
          {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ id, name, label, type, onChange, onValu... Remove this comment to see the full error message */}
          <Form.Field inputComponent={BooleanInput} asFormControl />
        </Form.FieldGroup>
        {withSubmitButton && (
          <div className="d-flex justify-content-center">
            <Form.SubmitButton variant="primary" {...submitButtonProps}>
              {submitButtonText}
            </Form.SubmitButton>
          </div>
        )}
      </Col>
    </Row>
  </WrapperComponent>
)

Warning.defaultProps = {
  headerComponent: Header,
  submitButtonProps: {},
  submitButtonText: 'Godkend',
  withSubmitButton: false,
  wrapperComponent: Form,
}

export default Warning
