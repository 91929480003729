import { useFormikContext } from 'formik'
import type { ReactNode } from 'react'
import React, { useContext, useRef, useCallback, useEffect } from 'react'
import { ValidationError } from 'yup'

import useAutoUpdateRef from '../../../hooks/useAutoUpdateRef'
import { useFieldsList } from '../../Form'
import Page from '../Page'
import usePageTransition from '../usePageTransition'
import usePaginationContext from '../usePaginationContext'

import PaginatedFormContext from './PaginatedFormContext'

export interface FormPageProps {
  active?: boolean
  children?: ReactNode
  fields: string[]
  labelDescriptions?: any
  pageIndex?: number
  validationSchema?: any
  onSubmit?(...args: any[]): any
}

const FormPage = ({
  onSubmit,
  pageIndex,
  fields: fieldsProp,
  labelDescriptions,
  validationSchema,
  ...otherProps
}: FormPageProps) => {
  const { setFieldValue, values } = useFormikContext()
  const fields = useFieldsList({
    fields: fieldsProp,
    labelDescriptions,
    validationSchema,
  })
  const { active } = usePageTransition(pageIndex)
  const paginationContext = usePaginationContext(false)
  const paginationContextRef = useRef(paginationContext)
  paginationContextRef.current = paginationContext
  // @ts-expect-error TS(2339) FIXME: Property 'onFinalSubmit' does not exist on type '{... Remove this comment to see the full error message
  const { onChangeFormProps, onFinalSubmit } = useContext(PaginatedFormContext)
  const { setTouched } = useFormikContext()
  const valuesRef = useAutoUpdateRef(values)

  const handleSubmit = useCallback(
    async (newValues: any, formik: any, extraArgs: any, { skipNext = false } = {}) => {
      const currentPaginationContext = paginationContextRef.current
      const { onNext, count, onChange } = currentPaginationContext
      const lastPage = pageIndex === count - 1
      if (onSubmit)
        await onSubmit(newValues, formik, {
          pagination: currentPaginationContext,
        })

      if (lastPage) {
        try {
          await onFinalSubmit(newValues, formik, {
            pagination: currentPaginationContext,
            ...extraArgs,
          })

          if (!skipNext) {
            onNext()
          }
        } catch (error) {
          // @ts-expect-error TS(2339) FIXME: Property 'pageIndex' does not exist on type 'unkno... Remove this comment to see the full error message
          const { pageIndex: invalidPageIndex } = error
          onChange(invalidPageIndex + 1)

          if (error instanceof ValidationError) {
            return {
              data: {
                errors: error.inner.reduce((errors, inner) => {
                  if (!inner.path) return errors
                  errors[inner.path] = inner.errors

                  return errors
                }, {} as Record<string, string[]>),
              },
            }
          }
        }
      } else if (!skipNext) {
        setTouched({}, false)
        onNext()
      }

      return undefined
    },
    [pageIndex, onSubmit, onFinalSubmit, setTouched],
  )

  useEffect(() => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onChangeFormProps(pageIndex, {
      fields,
      labelDescriptions,
      validationSchema,
      onSubmit: handleSubmit,
    })
  }, [
    onChangeFormProps,
    handleSubmit,
    pageIndex,
    fields,
    labelDescriptions,
    validationSchema,
    setFieldValue,
    valuesRef,
  ])

  return <Page pageIndex={pageIndex} active={active} {...otherProps} />
}

FormPage.displayName = 'Paginator.Form.Page'

export default FormPage
