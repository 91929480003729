import { createAsyncThunk } from '@reduxjs/toolkit'

import apiRequestCall from '../../api/apiRequestCall'

export const fetchPortfolios = createAsyncThunk(
  'current.portfolios/fetchAll',
  (options, { dispatch }) => {
    // @ts-expect-error TS(2339) FIXME: Property 'filter' does not exist on type 'void'.
    const { filter } = options

    return dispatch(
      apiRequestCall({
        method: 'GET',
        url: '/portfolios',
        params: {
          full: true,
          filter: { state: filter },
        },
        withCredentials: true,
      }),
    ).unwrap()
  },
)

export const fetchPortfolio = createAsyncThunk(
  'current.portfolios/fetch',
  (portfolioId, { dispatch }) =>
    dispatch(
      apiRequestCall({
        method: 'GET',
        url: `/portfolios/${portfolioId}`,
        params: { full: true },
        withCredentials: true,
      }),
    ).unwrap(),
)

export const updatePortfolio = createAsyncThunk(
  'current.portfolios/update',
  // @ts-expect-error TS(2339) FIXME: Property 'portfolioId' does not exist on type 'voi... Remove this comment to see the full error message
  ({ portfolioId, attributes }, { dispatch }) =>
    dispatch(
      apiRequestCall({
        method: 'PATCH',
        url: `/portfolios/${portfolioId}`,
        withCredentials: true,
        data: { portfolio: attributes },
      }),
    ).unwrap(),
)
