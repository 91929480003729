import React from 'react'

import Navigation from '../Navigation'

import NextButton from './NextButton'

export interface Props {
  nextButton?: React.ReactNode
}

const FormNavigation = ({ nextButton, ...otherProps }: Props) => {
  let newNextButton
  if (nextButton === false) newNextButton = false
  else if (nextButton) newNextButton = nextButton
  else newNextButton = <NextButton />

  return <Navigation nextButton={newNextButton} {...otherProps} />
}

FormNavigation.defaultProps = {
  nextButton: undefined,
}

FormNavigation.displayName = 'Paginator.Form.Navigation'

export default Object.assign(FormNavigation, {
  NextButton,
})
