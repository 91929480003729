import classNames from 'classnames'
import React from 'react'
import { Button, type ButtonProps } from 'react-bootstrap'

import styles from './Link.module.scss'

export const LinkDefaultAs = Button
export type LinkDefaultAsType = typeof LinkDefaultAs

export type LinkOwnProps<E extends React.ElementType> = {
  as?: E
}

export type LinkProps<E extends React.ElementType = React.ElementType> = ButtonProps &
  LinkOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof LinkOwnProps<E>>

const Link = ({ className, children, ...otherProps }: LinkProps) => (
  <Button
    variant="link"
    className={classNames(className, styles.headerLink, 'link-light', 'nav-link')}
    {...otherProps}
  >
    {children}
  </Button>
)

export default Link
