import classNames from 'classnames'
import isString from 'lodash/isString'
import upperFirst from 'lodash/upperFirst'
import React from 'react'

import { bootstrapVariant } from '../utilities/propTypes'

import styles from './Circle.module.scss'

const circleSizes = {
  xs: styles.circleSizeXs,
  sm: styles.circleSizeSm,
  md: styles.circleSizeMd,
  lg: styles.circleSizeLg,
  xl: styles.circleSizeXl,
}

const DEFAULT_VARIANT = 'light'

export interface CircleImageProps {
  as?: React.ReactElement
  className?: string
}

export const CircleImage = React.forwardRef<any, CircleImageProps>(
  ({ className, as: Component, ...otherProps }, ref) => (
    // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
    <Component className={classNames(className, styles.circleImage)} {...otherProps} ref={ref} />
  ),
)

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
CircleImage.defaultProps = {
  as: 'img',
  className: undefined,
}

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
CircleImage.displayName = 'CircleImage'

export interface CircleProps {
  borderless?: boolean
  className?: string
  filled?: boolean
  size?: string | number
  variant?: any // TODO: bootstrapVariant()
}

const Circle = React.forwardRef<any, CircleProps>(
  ({ variant, className, borderless, filled, size, ...otherProps }, ref) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const formattedSize = isString(size) ? circleSizes[size] : size
    const customStyle = {
      height: `${formattedSize}rem`,
      width: `${formattedSize}rem`,
    }

    return (
      <div
        className={classNames(styles.circle, styles[`circle${upperFirst(variant)}`], className, {
          [styles.circleBorderless]: borderless,
          [styles.circleFilled]: filled,
        })}
        style={customStyle}
        ref={ref}
        {...otherProps}
      />
    )
  },
)

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
Circle.defaultProps = {
  borderless: undefined,
  className: undefined,
  filled: undefined,
  size: 'md',
  variant: DEFAULT_VARIANT,
}

// @ts-expect-error TS(2339) FIXME: Property 'displayImage' does not exist on type '(p... Remove this comment to see the full error message
Circle.displayImage = 'Circle'

// @ts-expect-error TS(2339) FIXME: Property 'Image' does not exist on type '(props: C... Remove this comment to see the full error message
Circle.Image = CircleImage

export default Circle
