import Form from './Form'
import Navigation from './Navigation'
import Nested from './Nested'
import Page from './Page'
import PageContainer from './PageContainer'
import QueryParam from './QueryParam'
import Wrapper from './Wrapper'

const Paginator = {
  Wrapper,
  Page,
  PageContainer,
  Form,
  Nested,
  Navigation,
  QueryParam,
}

export { default as usePaginationContext } from './usePaginationContext'
export * from './QueryParam'
export * from './Form'

export default Paginator
