import { createReducer, combineReducers } from '@reduxjs/toolkit'

import loadingReducer, { initialState as initialLoading } from './loading'
import portfolioIdReducer, { initialState as initialPortfolioId } from './portfolioId'
import portfoliosReducer, { initialState as initialPortfolios } from './portfolios'
import userReducer, { initialState as initialUser } from './user'

const isNestedCurrentAction = (action: any) => action.type.startsWith('current')

const combinedReducer = combineReducers({
  loading: loadingReducer,
  user: userReducer,
  portfolioId: portfolioIdReducer,
  portfolios: portfoliosReducer,
})

const initialState = {
  loading: initialLoading,
  portfolioId: initialPortfolioId,
  portfolios: initialPortfolios,
  user: initialUser,
}

const createCurrentReducer = (extraReducer: any) =>
  createReducer(initialState, (builder) => {
    builder.addMatcher(isNestedCurrentAction, (state, action) => {
      let newState = state
      if (extraReducer) newState = { ...state, ...extraReducer(state, action) }

      return { ...newState, ...combinedReducer(newState, action) }
    })
  })

export default createCurrentReducer
