import {
  DndContext,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import classNames from 'classnames'
import React, { useState } from 'react'

import useBreakpointHandler from '../../hooks/useBreakpointHandler'

import { getDraggingData, calculateNewIndex } from './utilities'

export interface Props {
  children: React.ReactNode
  items: any
  onOrderChange: (...args: any[]) => any
}

const Wrapper = ({ items, onOrderChange, children }: Props) => {
  const isLargeAndUp = useBreakpointHandler('lg')

  const [activeItemId, setActiveItemId] = useState()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleCompleteDragging = () => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setActiveItemId(null)
  }

  // @ts-expect-error TS(7031) FIXME: Binding element 'activeId' implicitly has an 'any'... Remove this comment to see the full error message
  const handleDragStart = ({ active: { id: activeId } }) => {
    setActiveItemId(activeId)
  }

  const handleDragCancel = () => {
    handleCompleteDragging()
  }

  // Handle dragging between containers
  const handleDragOver = ({ active, over }: any) => {
    const { id: activeId } = active
    const { id: overId } = over
    const { activeContainer, overContainer, activeIndex, overIndex, activeItems, overItems } =
      getDraggingData({
        activeId,
        overId,
        items,
      })

    if (!activeContainer) return
    if (!overContainer) return
    if (activeContainer === overContainer) return // Dragging over the same container, so do nothing

    const newIndex = calculateNewIndex({ active, over, overIndex })
    const newActiveItems = [...activeItems].filter((item) => item !== active.id)
    const newOverItems = [...overItems]
    newOverItems.splice(newIndex, 0, items[activeContainer][activeIndex])

    const newOrderedItems = {
      ...items,
      [activeContainer]: newActiveItems,
      [overContainer]: newOverItems,
    }

    onOrderChange(newOrderedItems)
  }

  // Handle dragging in the same container
  // @ts-expect-error TS(7031) FIXME: Binding element 'activeId' implicitly has an 'any'... Remove this comment to see the full error message
  const handleDragEnd = ({ active: { id: activeId }, over: { id: overId } }) => {
    const { activeContainer, overContainer, activeIndex, overIndex } = getDraggingData({
      activeId,
      overId,
      items,
    })

    if (!overId) return handleCompleteDragging()
    if (!activeContainer) return handleCompleteDragging()
    if (!overContainer) return handleCompleteDragging()
    if (activeContainer !== overContainer) return undefined // Dragging over different container, so do nothing
    if (activeIndex === overIndex) return undefined // Not dragged far enough

    const newOrderedItems = {
      ...items,
      [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
    }

    onOrderChange(newOrderedItems)

    return handleCompleteDragging()
  }

  let activeChildren

  if (activeItemId) {
    // @ts-expect-error TS(2339) FIXME: Property 'props' does not exist on type 'string | ... Remove this comment to see the full error message
    React.Children.toArray(children).find(({ props: { children: itemsChildren } }) => {
      activeChildren = itemsChildren.find(({ props: { id } }: any) => id === activeItemId)

      return !!activeChildren
    })
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      // @ts-expect-error TS(2322) FIXME: Type '({ active: { id: activeId }, over: { id: ove... Remove this comment to see the full error message
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <div
        className={classNames('d-flex flex-column flex-lg-row', {
          'children-mx-3': isLargeAndUp,
          'children-my-3': !isLargeAndUp,
        })}
      >
        {children}
      </div>
      <DragOverlay>{activeChildren && React.cloneElement(activeChildren)}</DragOverlay>
    </DndContext>
  )
}

export default Wrapper
