import partition from 'lodash/partition'

export const ORDERED_COUNTRIES = ['DK', 'NO', 'SE', 'DE', 'GB']

let cachedCountryData: any

const sortItems = (items: any) => {
  const [orderedOptions, unorderedOptions] = partition(
    items,
    (item) => ORDERED_COUNTRIES.indexOf(item.code) >= 0,
  )

  const sortedOrderedItems = orderedOptions.sort(
    (a, b) => ORDERED_COUNTRIES.indexOf(a.code) - ORDERED_COUNTRIES.indexOf(b.code),
  )
  const sortedUnorderedItems = unorderedOptions.sort((a, b) => (a.country > b.country ? 1 : -1))

  return [...sortedOrderedItems, ...sortedUnorderedItems]
}

const loadCountryData = async () => {
  if (cachedCountryData === undefined) {
    cachedCountryData = await new Promise((resolve, rejectPromise) => {
      import('../configuration/data/countries')
        .then(({ default: data }) => {
          const sortedItems = sortItems(data)

          resolve(sortedItems)
        })
        .catch((error) => rejectPromise(error))
    })
  }

  return cachedCountryData
}

export default loadCountryData
