import callIntercom from './callIntercom'
import { setupScope, clearScope } from './sentry'

const setupUserData = (data: any) => {
  setupScope(data)
  callIntercom('update', data)
}

export const clearUserData = () => {
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  callIntercom('shutdown')
  clearScope()
}

export default setupUserData
