import classNames from 'classnames'
import { camelCase } from 'lodash'
import React from 'react'
import { CSSTransition } from 'react-transition-group'

import extractTransitionClasses from '../../utilities/extractTransitionClasses'
// import { inlineStyle } from '../../utilities/propTypes'

import styles from './Slide.module.scss'

const timeout = parseInt(styles.timeout, 10)

export interface Props {
  children: React.ReactNode
  in?: boolean
  className?: string
  delay?: number
  style?: any // TODO: inlineStyle()
  direction?: 'in-bottom' | 'in-top' | 'out-bottom' | 'out-top'
}

const Slide = ({
  children,
  className,
  delay,
  in: show,
  style,
  direction,
  ...otherProps
}: Props) => {
  const child = React.Children.only(children)

  return (
    <CSSTransition
      classNames={extractTransitionClasses({
        styles,
        className: 'slide',
        appearKey: 'enter',
      })}
      in={show}
      timeout={timeout}
      appear
      {...otherProps}
    >
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      {React.cloneElement(child, {
        className: classNames(
          // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
          child.props.className,
          styles[camelCase(`slide-${direction}`)],
          className,
        ),
        style: {
          transitionDelay: `${delay}ms`,
          // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
          ...child.props.style,
          ...style,
        },
      })}
    </CSSTransition>
  )
}

Slide.defaultProps = {
  className: undefined,
  delay: 0,
  direction: 'in-bottom',
  in: false,
  style: undefined,
}

export default Slide
