import { getConfig } from '@nord/ui/src/configuration'
import useIsSignedIn from '@nord/ui/src/hooks/useIsSignedIn'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import camelCase from 'lodash/camelCase'
import { useEffect } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

const webappUrl = getConfig('domains.app')

const statesToRedirect = ['rebalanced', 'rebalancingAccepted', 'readyForRiskProfileChange'] as const

function isStateToRedirect(state: string): state is (typeof statesToRedirect)[number] {
  return (statesToRedirect as readonly string[]).includes(state)
}

const redirectPathKeys = {
  start: (id: number) => `/portefoeljer/${id}/start`,
  rebalancingAccepted: (id: number) => `/portefoeljer/${id}/rebalancer/godkendt`,
  readyForRiskProfileChange: (id: number) => `/portefoeljer/${id}/ny-risikoprofil/godkendt`,
  rebalanced: (id: number) => `/portefoeljer/${id}/rebalancer/fuldfoert`,
} as const

const getRedirectPath = (state: string, id: number) => {
  const formattedState = isStateToRedirect(state) ? state : 'start'
  const redirectPath = redirectPathKeys[formattedState](id)

  return redirectPath
}

const useRedirect = () => {
  const history = useHistory()
  const isSignedIn = useIsSignedIn()

  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
  const { id: portfolioId, suitabilityState: snakeCaseSuitabilityState } =
    useObjectSelector(selectCurrentPortfolio)

  const suitabilityState = camelCase(snakeCaseSuitabilityState)

  useEffect(() => {
    if (!isSignedIn) return
    if (!suitabilityState) return

    if (suitabilityState === 'notAvailable') window.location.replace(webappUrl)

    const redirectPath = getRedirectPath(suitabilityState, portfolioId)

    if (window.location.pathname === redirectPath) return

    history.push(redirectPath)
  }, [history, isSignedIn, portfolioId, suitabilityState])
}

export default useRedirect
