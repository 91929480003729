import sortBy from 'lodash/sortBy'

import useApi, { useApiRequest } from '../../hooks/useApi'

const useGetDocumentsApi = (options = {}) => {
  const fetchDocuments = useApi('/onboarding/documents', {
    ...options,
    withCredentials: true,
  })

  const {
    data: documents,
    loading,
    request,
  } = useApiRequest(fetchDocuments, {
    autoCall: true,
  })

  const sortedDocuments = documents && sortBy(documents, ['folder', 'portfolio'])

  return {
    documents: sortedDocuments,
    loading,
    refresh: request,
  }
}

export default useGetDocumentsApi
