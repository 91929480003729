import React, { useMemo } from 'react'
import FormContext from 'react-bootstrap/FormContext'
import FormGroup from 'react-bootstrap/FormGroup'

export interface Props {
  as?: React.ReactElement
  children?: React.ReactNode
  controlId?: string
}

const Group = React.forwardRef<any, Props>(({ children, controlId, as, ...otherProps }, ref) => {
  const Component = as || FormGroup
  const context = useMemo(() => ({ controlId }), [controlId])

  return (
    <FormContext.Provider value={context}>
      {/* @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message */}
      <Component ref={ref} {...otherProps}>
        {children}
      </Component>
    </FormContext.Provider>
  )
})

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
Group.displayName = 'Form.Group'

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
Group.defaultProps = {
  as: undefined,
  children: undefined,
  controlId: undefined,
}

export default Group
