/* eslint-disable no-template-curly-in-string */
import type yup from 'yup'
import { addMethod, string } from 'yup'

function testNumerical(this: yup.StringSchema, message = 'Skal være et tal') {
  return this.test({
    name: 'numerical',
    message,
    params: {},
    exclusive: true,
    test: (value) => (value ? parseFloat(value).toString() === value.replace(/^0+/, '') : true),
  })
}

function testLength(this: yup.StringSchema, length: number, message = 'Skal være ${length} lang') {
  return this.test({
    name: 'length',
    message,
    params: {
      length,
    },
    exclusive: true,
    test: (value) => (value ? value.length === length : length === 0),
  })
}

addMethod(string, 'numerical', testNumerical)
addMethod(string, 'length', testLength)

declare module 'yup' {
  interface StringSchema {
    length(length: number, message?: string): StringSchema
    numerical(message?: string): StringSchema
  }
}
