import classNames from 'classnames'
import React from 'react'

import styles from './Container.module.scss'

export interface ContainerProps {
  as?: React.ReactElement
  children?: React.ReactNode
  className?: string
  footer?: React.ReactNode
  mobile?: boolean
}

const Container = ({
  className,
  children,
  footer,
  mobile,
  as: Component,
  ...otherProps
}: ContainerProps) => (
  // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
  <Component className={styles.navbarWrapper} {...otherProps}>
    <div
      className={classNames(className, styles.navbarContainer, {
        [styles.mobileNavbar]: mobile,
        'position-relative': footer,
      })}
    >
      {children}
    </div>
    {footer}
  </Component>
)

Container.defaultProps = {
  as: 'header',
  children: undefined,
  className: undefined,
  footer: undefined,
  mobile: undefined,
}

export default Container
