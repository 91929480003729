import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'

import { requireAtLeastOneOf } from '../../../utilities/propTypes'
import InnerHtml from '../../InnerHtml'

export interface Props {
  body?: string
  bodyHtml?: any // TODO: requireAtLeastOneOf(PropTypes.string, 'body')
  title: string
}

const InfoModal = ({ title, body, bodyHtml }: Props) => {
  const [showModal, setShowModal] = useState(false)

  const handleShowModal = () => setShowModal(true)
  const handleHideModal = () => setShowModal(false)

  return (
    <>
      <Col xs={12} lg={{ span: 8, offset: 2 }} className="d-flex justify-content-center mb-4">
        <Button variant="link" onClick={handleShowModal} className="btn-anchor">
          Læs mere
        </Button>
      </Col>
      <Modal show={showModal} onHide={handleHideModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {bodyHtml && <InnerHtml>{bodyHtml}</InnerHtml>}
          {body && <div>{body}</div>}
        </Modal.Body>
      </Modal>
    </>
  )
}

InfoModal.defaultProps = {
  body: undefined,
  bodyHtml: undefined,
}

export default InfoModal
