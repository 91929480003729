import Card from '@nord/ui/src/components/Card'
import ForgotPasswordForm from '@nord/ui/src/components/ForgotPasswordForm'
import React from 'react'

import Page from '../../../components/Page'

const ForgotPasswordPage = () => (
  <Page>
    <Card>
      <Card.Body>
        <ForgotPasswordForm />
      </Card.Body>
    </Card>
  </Page>
)

export default ForgotPasswordPage
