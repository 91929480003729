import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export interface Props {
  children?: React.ReactNode
  className?: string
}

const Container = ({ children, className }: Props) => (
  <Row>
    <Col xs={12}>
      <div className={classNames('d-flex', 'justify-content-center', 'my-grid', className)}>
        {children}
      </div>
    </Col>
  </Row>
)

Container.defaultProps = {
  children: undefined,
  className: undefined,
}

Container.displayName = 'Paginator.Navigation.Container'

export default Container
