import { useField } from 'formik'
import isFunction from 'lodash/isFunction'
import React, { useMemo } from 'react'

import chainEventHandler from '../../utilities/chainEventHandler'

import Control from './Control'

export interface Props {
  as?: React.ReactElement
  inputComponent?: React.ReactElement
  children?: React.ReactNode | ((...args: any[]) => any)
  name?: string
  onBlur?: (...args: any[]) => any
  onValueChange?: (...args: any[]) => any
  onChange?: (...args: any[]) => any
  useOnChange?: boolean
}

const Field = React.forwardRef<any, Props>(
  (
    { inputComponent, children, name, onValueChange, onChange, onBlur, useOnChange, ...otherProps },
    ref,
  ) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const [field, meta, helpers] = useField(name)
    const { onChange: _onChange, onBlur: handleBlur, ...otherField } = field
    const { setValue } = helpers
    const newField = useMemo(
      () => ({
        ...otherField,
      }),
      [otherField],
    )

    const handleChangeValue = useMemo(
      () =>
        chainEventHandler(onValueChange, (newValue: any) => {
          setValue(newValue)

          return newValue
        }),
      [onValueChange, setValue],
    )

    const handleChange = useMemo(
      () => chainEventHandler(onChange, (event: any) => event.target.value, handleChangeValue),
      [handleChangeValue, onChange],
    )

    const chainBlurEvents = useMemo(
      () => chainEventHandler(handleBlur, onBlur),
      [handleBlur, onBlur],
    )

    // @ts-expect-error TS(2339) FIXME: Property 'onChange' does not exist on type '{ valu... Remove this comment to see the full error message
    if (useOnChange) newField.onChange = handleChange
    // @ts-expect-error TS(2339) FIXME: Property 'onValueChange' does not exist on type '{... Remove this comment to see the full error message
    else newField.onValueChange = handleChangeValue

    // @ts-expect-error TS(2339) FIXME: Property 'onBlur' does not exist on type '{ value:... Remove this comment to see the full error message
    if (onBlur) newField.onBlur = chainBlurEvents
    // @ts-expect-error TS(2339) FIXME: Property 'onBlur' does not exist on type '{ value:... Remove this comment to see the full error message
    else newField.onBlur = handleBlur

    if (inputComponent) {
      const InputComponent = inputComponent

      return (
        // @ts-expect-error TS(2604) FIXME: JSX element type 'InputComponent' does not have an... Remove this comment to see the full error message
        <InputComponent {...newField} {...otherProps} ref={ref}>
          {children}
        </InputComponent>
      )
    }

    if (isFunction(children)) return children({ field: newField, meta, ref })

    return (
      // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; ref: Ref<any>; as?: R... Remove this comment to see the full error message
      <Control {...newField} {...otherProps} ref={ref}>
        {children}
      </Control>
    )
  },
)

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
Field.displayName = 'Form.Field'

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
Field.defaultProps = {
  as: undefined,
  children: undefined,
  inputComponent: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  onValueChange: undefined,
  useOnChange: false,
}

export default Field
