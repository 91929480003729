import rawStyles from '../assets/styles/export.module.scss'

export function sassMapToObject(map: string) {
  /**
   * The regex pattern does a lookahead to determine whether a comma is within a
   * pair of parantheses. If there is a closing parantheses after the comma, it
   * will not split on that comma.
   *
   * It is necessary because a Sass object's keys and values takes forms such as
   * - `("blue": #007bff, "gray": rgb(102, 102, 102))`
   * - `(xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)`
   */
  const re = /,(?=[^)]*(?:\(|$))/

  const entries = map
    .trim()
    .slice(1, -1)
    .split(re)
    .map((pair) => pair.trim().split(': '))
    .map(([key, value]) => [key.replace(/"/g, ''), value] as const)

  return Object.fromEntries(entries)
}

export const sassListToArray = (list: string) => list.replace(/[",]/g, '').split(' ')

const calculateBreakpoints = () =>
  Object.entries(sassMapToObject(rawStyles.breakpoints)).reduce((result, [breakpoint, value]) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    result[breakpoint] = parseInt(value, 10)

    return result
  }, {})

const breakpoints = calculateBreakpoints()
const breakpointKeys = Object.keys(breakpoints)

const styleConfiguration = {
  ...rawStyles,
  fontSizes: sassMapToObject(rawStyles.fontSizes),
  fontSizesDesktop: sassMapToObject(rawStyles.fontSizesDesktop),
  blues: sassMapToObject(rawStyles.blues),
  greens: sassMapToObject(rawStyles.greens),
  breakpoints,
  breakpointKeys,
  sizes: Object.keys(sassMapToObject(rawStyles.sizes)),
  themeColors: sassMapToObject(rawStyles.themeColors),
  grays: sassMapToObject(rawStyles.grays),
  colors: sassMapToObject(rawStyles.colors),
  darkVariants: sassListToArray(rawStyles.darkVariants),
  lightVariants: sassListToArray(rawStyles.lightVariants),
  variants: sassListToArray(rawStyles.variants),
}

export default styleConfiguration
