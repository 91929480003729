import React from 'react'

import type { PaginationPropType } from './PaginationPropType'
import Wrapper from './Wrapper'

export interface PageProps {
  children?: React.ReactNode
  showPagination?: boolean
  pagination: PaginationPropType
  wrapperComponent?: React.ReactElement
}

// TODO update styling:
// - dobbelt border radius
// - decrease padding on mobile
// - box shadow
const Page = ({
  children,
  pagination,
  showPagination,
  wrapperComponent: WrapperComponent,
  ...otherProps
}: PageProps) => {
  let child = null

  if (children) {
    child = React.Children.only(children)
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    child = React.cloneElement(child, { pagination })
  }

  return (
    // @ts-expect-error TS(2604) FIXME: JSX element type 'WrapperComponent' does not have ... Remove this comment to see the full error message
    <WrapperComponent pagination={pagination} {...otherProps}>
      {showPagination && (
        <div className="text-center font-size-xs text-muted">
          {`${pagination.number} / ${pagination.count}`}
        </div>
      )}

      {child}
    </WrapperComponent>
  )
}

Page.defaultProps = {
  children: undefined,
  showPagination: undefined,
  wrapperComponent: Wrapper,
}

Page.displayName = 'PaginatedSection.Page'

export default Page
