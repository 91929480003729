import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
import { stringify } from 'qs'

import type { CamelToSnakeCaseNested, SnakeToCamelCaseNested } from '../../types'
import { deepMapKeys } from '../../utilities/deepIterate'

export const formatResponseData = <T>(object: T): SnakeToCamelCaseNested<T> =>
  deepMapKeys(object, camelCase)

export const formatRequestData = <T>(object: T): CamelToSnakeCaseNested<T> =>
  deepMapKeys(object, snakeCase)

export const formatQueryParams = (object: any) => {
  const result = stringify(deepMapKeys(object, snakeCase), {
    encode: false,
    arrayFormat: 'brackets',
  })

  return result
}
