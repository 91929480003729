import * as Sentry from '@sentry/react'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import ErrorContext from './ErrorContext'
import ErrorPage from './ErrorPage'

const ErrorProxy = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'error' does not exist on type 'unknown'.
  const { error } = useContext(ErrorContext)

  useEffect(() => {
    if (!error) return

    if (error === true) {
      throw new Error('Uncaught error')
    } else {
      throw error
    }
  }, [error])

  return null
}

export interface ErrorBoundaryProps {
  children: React.ReactNode
}

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const [error, setError] = useState()

  const onError = useCallback((errorToCatch: any) => {
    setError(errorToCatch || true)
  }, [])

  const context = useMemo(
    () => ({
      error,
      onError,
    }),
    [error, onError],
  )

  return (
    <ErrorContext.Provider value={context}>
      <Sentry.ErrorBoundary fallback={ErrorPage} onError={onError}>
        <ErrorProxy />
        {children}
      </Sentry.ErrorBoundary>
    </ErrorContext.Provider>
  )
}

export default ErrorBoundary
