import PaginatedSection from '@nord/ui/src/components/PaginatedSection'
import QuestionSection from '@nord/ui/src/components/QuestionsSection'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import React, { useContext, useState } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import LoadingPage from '../../../../components/LoadingPage'
import PorfolioContext from '../../../../context/PortfolioContext'
import DebtTest from '../DebtTest'
import SustainabilityLevelWarning from '../SustainabilityLevelWarning'
import SustainabilityPreferencesSorting from '../SustainabilityPreferencesSorting'

const SuitabilityQuestions = () => {
  const history = useHistory()
  const [newInvestmentProfileId, setNewInvestmentProfileId] = useState()
  const [newInitialSustainabilityPreferenceLevel, setNewInitialSustainabilityPreferenceLevel] =
    useState()
  const [newSustainabilityPreferenceLevel, setNewSustainabilityPreferenceLevel] = useState()

  const [sustainabilityPreferencesOrder, setSustainabilityPreferencesOrder] = useState()

  const [acceptLowerSustainabilityPreference, setAcceptLowerSustainabilityPreference] = useState()

  const {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: portfolioId,
    // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
    riskScore,
    // @ts-expect-error TS(2339) FIXME: Property 'suitabilityFlow' does not exist on type ... Remove this comment to see the full error message
    suitabilityFlow,
    // @ts-expect-error TS(2339) FIXME: Property 'esg' does not exist on type 'unknown'.
    esg,
  } = useObjectSelector(selectCurrentPortfolio)

  // @ts-expect-error TS(2339) FIXME: Property 'onNewRiskScoreChange' does not exist on ... Remove this comment to see the full error message
  const { onNewRiskScoreChange, onNewEsgChange } = useContext(PorfolioContext)

  const getSuitabilityQuestions = useApi(
    `/portfolios/${portfolioId}/suitability_tests/investment_profiles`,
    {
      method: 'GET',
      withCredentials: true,
    },
  )

  const { data: questionsData, loading } = useApiRequest(getSuitabilityQuestions, {
    autoCall: !!portfolioId,
  })

  const {
    type: questionsType,
    version: questionsVersion,
    data: suitabilityQuestions,
  } = questionsData || {}

  const submitSuitabilityAnswers = useApi(
    `/portfolios/${portfolioId}/suitability_tests/investment_profiles`,
    {
      method: 'POST',
      withCredentials: true,
    },
  )

  const callSubmitSuitabilityTest = useApi(`/portfolios/${portfolioId}/suitability_tests`, {
    method: 'POST',
    withCredentials: true,
  })

  const { request: submitSuitabilityTest, loading: isSubmittingSuitabilityTest } =
    useApiRequest(callSubmitSuitabilityTest)

  const handleSuitabilityAnswersSubmit = async (answersData: any) => {
    const {
      data: {
        investmentProfileId,
        success,
        initialSustainabilityPreferenceLevel,
        sustainabilityPreferenceLevel,
      },
    } = await submitSuitabilityAnswers({
      suitabilityTest: {
        answers: answersData,
        version: questionsVersion,
        type: questionsType,
      },
    })

    setNewInvestmentProfileId(investmentProfileId)
    setNewInitialSustainabilityPreferenceLevel(initialSustainabilityPreferenceLevel)
    setNewSustainabilityPreferenceLevel(sustainabilityPreferenceLevel)

    if (!success) history.push(`/portefoeljer/${portfolioId}/egnethedstest/fejlet`)
  }

  const handleSuitabilityTestSubmit = async (data: any) => {
    const {
      data: { riskScore: newRiskScore, esg: newEsg, success },
    } = await submitSuitabilityTest({
      suitabilityTest: {
        investmentProfileId: newInvestmentProfileId,
        sustainabilityPreferencesOrder,
        acceptLowerSustainabilityPreference,
        ...data,
      },
    })

    const flowUrl = suitabilityFlow === 'rebalancing' ? 'rebalancer' : 'egnethed/fuldfoert'

    if (!success) return history.push(`/portefoeljer/${portfolioId}/egnethedstest/fejlet`)

    const riskScoreDiffers = newRiskScore !== riskScore
    const esgDiffers = newEsg !== esg

    if (riskScoreDiffers || esgDiffers) {
      onNewRiskScoreChange(newRiskScore)
      onNewEsgChange(newEsg)

      return history.push(`/portefoeljer/${portfolioId}/ny-risikoprofil`)
    }

    return history.push(`/portefoeljer/${portfolioId}/${flowUrl}`)
  }

  return loading || !suitabilityQuestions ? (
    <LoadingPage />
  ) : (
    <PaginatedSection hasNested>
      <QuestionSection
        questions={suitabilityQuestions}
        onAnswered={handleSuitabilityAnswersSubmit}
        onFailure={handleSuitabilityAnswersSubmit}
      />
      {newInitialSustainabilityPreferenceLevel &&
        newInitialSustainabilityPreferenceLevel === 'high' && (
          // @ts-expect-error TS(2741) FIXME: Property 'onComplete' is missing in type '{ onSust... Remove this comment to see the full error message
          <SustainabilityPreferencesSorting
            onSustainabilityPreferencesOrderChange={setSustainabilityPreferencesOrder}
          />
        )}
      {newSustainabilityPreferenceLevel && newSustainabilityPreferenceLevel === 'high' && (
        // @ts-expect-error TS(2741) FIXME: Property 'onComplete' is missing in type '{ onAcce... Remove this comment to see the full error message
        <SustainabilityLevelWarning
          onAcceptLowerSustainabilityPreferenceChange={setAcceptLowerSustainabilityPreference}
        />
      )}
      <DebtTest onSubmit={handleSuitabilityTestSubmit} isSubmitting={isSubmittingSuitabilityTest} />
    </PaginatedSection>
  )
}

export default SuitabilityQuestions
