const RISK_SCORE_DESCRIPTION = {
  1: ['Defensiv', 'Formuebevarelse er højeste prioritet'],
  5: ['Forsigtig', 'Mindre udsving er acceptable for et mindre afkast'],
  9: ['Balanceret', 'Gennemsnitlige udsving med mulighed for gennemsnitlige afkast'],
  13: ['Vækst', 'Moderate udsving med mulighed for et højere afkast'],
  17: ['Aggressiv', 'Høj volatilitet med mulighed for maksimale afkast'],
}

const getRiskScoreDescription = (score: any, titleOnly: any) => {
  const riskDescriptionIndex = Object.keys(RISK_SCORE_DESCRIPTION)
    .reverse()
    .find((key) => score >= key)

  // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const [title, description] = RISK_SCORE_DESCRIPTION[riskDescriptionIndex]

  if (titleOnly) return title

  return { title, description }
}

export default getRiskScoreDescription
