import pushToDataLayer from './pushToDataLayer'

const pageview = (location: any) => {
  const fullPath = location.pathname + location.search

  pushToDataLayer({
    event: 'pageview',
    path: fullPath,
  })
  pushToDataLayer({
    event: 'optimize.activate',
  })
}

export default pageview
