import React, { useCallback, useMemo, useState } from 'react'

import Paginator from '../Paginator'

import AnswersContext from './AnswersContext'
import Navigation from './Navigation'
import QuestionPage from './QuestionPage'

export interface Props {
  questions: string[]
  onAnswered?: (...args: any[]) => any
  onFailure?: (...args: any[]) => any
  paginatorComponent?: React.ReactElement
}

const QuestionsSection = ({
  questions,
  onAnswered,
  onFailure,
  paginatorComponent: PaginatorComponent,
  ...otherProps
}: Props) => {
  const [answers, setAnswers] = useState([])

  const handleChange = useCallback(
    (answer: any) => {
      const { number } = answer
      const newData = [...answers]
      // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
      newData[number - 1] = answer

      setAnswers(newData)

      return newData
    },
    [answers],
  )

  const handleAnswer = useCallback(
    (answer: any, lastQuestion = false) => {
      const newData = handleChange(answer)
      if (lastQuestion && onAnswered) onAnswered(newData)
    },
    [handleChange, onAnswered],
  )

  const handleFailure = useCallback(
    (answer: any) => {
      const newData = handleChange(answer)
      if (onFailure) onFailure(newData)
    },
    [handleChange, onFailure],
  )

  const context = useMemo(
    () => ({
      answers,
    }),
    [answers],
  )

  return (
    // @ts-expect-error TS(2740) FIXME: Type '{ answers: never[]; }' is missing the follow... Remove this comment to see the full error message
    <AnswersContext.Provider value={context}>
      {/* @ts-expect-error TS(2604) FIXME: JSX element type 'PaginatorComponent' does not hav... Remove this comment to see the full error message */}
      <PaginatorComponent navigationComponent={Navigation} {...otherProps}>
        {questions.map((question) => (
          <QuestionPage
            question={question}
            onAnswer={handleAnswer}
            onFailure={handleFailure}
            // @ts-expect-error TS(2339) FIXME: Property 'number' does not exist on type 'string'.
            key={question.number}
          />
        ))}
      </PaginatorComponent>
    </AnswersContext.Provider>
  )
}

QuestionsSection.defaultProps = {
  onAnswered: undefined,
  onFailure: undefined,
  paginatorComponent: Paginator.Wrapper,
}

export default QuestionsSection
