// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'airb... Remove this comment to see the full error message
import { childrenOf } from 'airbnb-prop-types'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { CSSTransition } from 'react-transition-group'

import extractTransitionClasses from '../utilities/extractTransitionClasses'

import { PageSwitchContext } from './PageSwitchTransition'
import styles from './SectionTransition.module.scss'

const defaultTimeout = parseInt(styles.timeout, 10)

export interface Props {
  children: any // TODO: childrenOf(PropTypes.node)
  className?: string
  timeout?: number
  appear?: boolean
}

const SectionTransition = ({ children, className, ...otherProps }: Props) => {
  const pageContext = useContext(PageSwitchContext)

  let direction = 'Left'
  // @ts-expect-error TS(2339) FIXME: Property 'forwards' does not exist on type '{ acti... Remove this comment to see the full error message
  if (pageContext?.forwards) direction = 'Right'

  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <CSSTransition
      classNames={extractTransitionClasses({
        styles,
        className: 'sectionTransition',
        appearKey: 'enter',
      })}
      {...otherProps}
    >
      <div className={classNames(className, styles[`sectionTransition${direction}`])}>
        {children}
      </div>
    </CSSTransition>
  )
}

SectionTransition.defaultProps = {
  appear: true,
  className: undefined,
  timeout: defaultTimeout,
}

export const fullPageTransitionDuration = defaultTimeout

export default SectionTransition
