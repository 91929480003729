import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Loader from 'react-feather/dist/icons/loader'

import IconButton from './IconButton'

const loadingIconProps = {
  icon: Loader,
  spin: true,
}

export interface Props {
  icon?: any // TODO: IconButton.propTypes.icon
  loading?: boolean
  disabled?: boolean
  children?: React.ReactNode
}

const LoadingButton = ({ icon, loading, disabled, children, ...buttonProps }: Props) => {
  let iconProps
  if (loading) {
    iconProps = loadingIconProps
  } else if (icon) {
    iconProps = icon
  }

  return (
    <IconButton icon={iconProps} disabled={disabled || loading} {...buttonProps}>
      {children}
    </IconButton>
  )
}

LoadingButton.defaultProps = {
  children: undefined,
  disabled: undefined,
  icon: undefined,
  loading: undefined,
}

export default LoadingButton
