import { numberToString } from '../../../utilities/numberFormatter'

const typeNames = {
  number: 'tal',
  string: 'bogstaver',
}

export const mixed = {
  default: () => 'Er ugyldig',
  required: () => 'Skal udfyldes',
  oneOf: ({ values }: { values: string[] }) => `Skal være en af følgende: ${values}`,
  notOneOf: ({ values }: { values: string[] }) => `Må ikke være en af følgende: ${values}`,
  notType: ({ type }: { type: string }) => {
    switch (type) {
      case 'date':
        return 'Ugyldig dato, skriv i formattet dd/mm/åååå'
      default:
        /* eslint-disable no-case-declarations */
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const typeName = typeNames[type]
        const message = `Må kun bestå af ${typeName}`
        /* eslint-enable no-case-declarations */

        return message
    }
  },
}

export const boolean = {
  accepted: () => 'Skal accepteres',
}

export const date = {
  min: ({ min }: { min: number }) => `Skal være senere end ${min}`,
  max: ({ max }: { max: number }) => `Skal være tidligere end ${max}`,
}

export const string = {
  min: ({ min }: { min: number }) => `Skal være mindst ${min} tegn`,
  max: ({ max }: { max: number }) => `Skal være mindst ${max} tegn`,
  email: () => 'Tjek at din email er skrevet korrekt',
  username: () => 'Tjek at din email er skrevet korrekt',
  numerical: () => 'Skal være et tal',
  length: ({ length }: { length: number }) => `Skal være ${length} lang`,
}

export const number = {
  min: ({ min }: { min: number }) => `Skal være mindst ${numberToString(min)}`,
  max: ({ max }: { max: number }) => `Må højest være ${numberToString(max)}`,
  positive: () => 'Skal være et positivt tal',
  negative: () => 'Skal være et negativt tal',
  integer: () => 'Skal være et helt tal',
}

export const array = {
  min: ({ min }: { min: number }) => `Skal vælge mindst ${numberToString(min)}`,
  max: ({ max }: { max: number }) => `Må højest vælge ${numberToString(max)}`,
}

const locale = {
  mixed,
  string,
  number,
  array,
}

export default locale
