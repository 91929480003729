import '@nord/ui/src/configuration/setup/yup'
import { getConfig } from '@nord/ui/src/configuration'
import { object, number } from 'yup'

const minimumInvestment = getConfig('numbers.minimumInvestment.simple')

const validationSchema = object().shape({
  debt: number().safeInteger().min(0).required(),
  investableAssets: number().required().safeInteger().min(minimumInvestment),
})

export default validationSchema
