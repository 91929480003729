import { createAsyncThunk } from '@reduxjs/toolkit'

import setupUserData, { clearUserData } from '../../../utilities/setupUserData'
import apiRequestCall from '../../api/apiRequestCall'
import { fetchPortfolios } from '../portfolios/actions'

export const fetchCurrentUser = createAsyncThunk(
  'current.user/fetch',
  async (options, { dispatch }) =>
    dispatch(
      apiRequestCall({
        method: 'GET',
        url: '/users/current',
        withCredentials: true,
        errorHandling: {
          ignore: {
            unauthorized: true,
          },
        },
        // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        ...options,
      }),
    ).unwrap(),
)

const setupUserAndLoadPortfolios = async ({
  loadPortfolios,
  setPortfolioId,
  userData,
  dispatch,
}: any) => {
  setupUserData(userData)

  if (loadPortfolios) {
    const portfoliosFilter = loadPortfolios === true ? 'any' : loadPortfolios
    const { payload: portfolios } = await dispatch(
      // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
      fetchPortfolios({ filter: portfoliosFilter, setPortfolioId }),
    )

    userData.portfolios = portfolios
  }

  return userData
}

export const initialUserLoad = createAsyncThunk(
  'current.user/initialLoad',
  // @ts-expect-error TS(2339) FIXME: Property 'loadPortfolios' does not exist on type '... Remove this comment to see the full error message
  async ({ loadPortfolios, setPortfolioId }, { dispatch }) => {
    const userData = await dispatch(
      // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
      fetchCurrentUser({
        errorHandling: {
          ignore: {
            unauthorized: true,
          },
        },
      }),
    ).unwrap()

    return setupUserAndLoadPortfolios({
      loadPortfolios,
      setPortfolioId,
      userData,
      dispatch,
    })
  },
)

export const signInUser = createAsyncThunk(
  'current.user/signIn',
  // @ts-expect-error TS(2339) FIXME: Property 'loadPortfolios' does not exist on type '... Remove this comment to see the full error message
  async ({ loadPortfolios = true, setPortfolioId = true, ...data }, { dispatch }) => {
    const userData = await dispatch(
      apiRequestCall({
        method: 'POST',
        url: '/users/sign_in',
        data,
        withCredentials: true,
        errorHandling: {
          ignore: {
            unauthorized: true,
          },
        },
      }),
    ).unwrap()

    return setupUserAndLoadPortfolios({
      loadPortfolios,
      setPortfolioId,
      userData,
      dispatch,
    })
  },
)

export const signOutUser = createAsyncThunk('current.user/signOut', async (_data, { dispatch }) => {
  const userData = await dispatch(
    apiRequestCall({
      method: 'DELETE',
      url: '/users/sign_out',
      withCredentials: true,
    }),
  ).unwrap()

  clearUserData()

  return userData
})
