// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useRouteMatch } from 'react-router-dom'

// eslint-disable-next-line consistent-return
const usePortfolioId = () => {
  const routeMatch = useRouteMatch('/portefoeljer/:id')

  if (routeMatch && routeMatch.params.id) return parseInt(routeMatch.params.id, 10)
}

export default usePortfolioId
