import React, { useContext } from 'react'

import SortingContext from './SortingContext'
import { getHint, getLabel } from './utilities'

export interface Props {
  value: string
}

const Item = ({ value }: Props) => {
  // @ts-expect-error TS(2339) FIXME: Property 'sustainabilityPreferences' does not exis... Remove this comment to see the full error message
  const { sustainabilityPreferences } = useContext(SortingContext)

  return (
    <div>
      {getLabel(sustainabilityPreferences, value)}
      <div className="font-size-sm text-secondary d-none d-lg-block">
        {getHint(sustainabilityPreferences, value)}
      </div>
    </div>
  )
}

export default Item
