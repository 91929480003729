import { useState, useEffect } from 'react'

import styleConfiguration from '../configuration/styleConfiguration'

import useWindowSize from './useWindowSize'

const { breakpoints } = styleConfiguration
const breakpointKeys = Object.keys(breakpoints)

// We need a fallback for SSR
const fallbackBreakpoint = 'xl'

const getBreakpoint = (width: any) => {
  const breakpoint = Object.keys(breakpoints)
    .reverse()
    .find((currentBreakpoint) => {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const value = breakpoints[currentBreakpoint]

      return width >= value
    })

  return breakpoint || fallbackBreakpoint
}

const useBreakpointHandler = (...breakpointLimits: any[]) => {
  const { width } = useWindowSize({ throttle: 100 })
  const [breakpoint, setBreakpoint] = useState(fallbackBreakpoint)

  useEffect(() => {
    const newBreakpoint = getBreakpoint(width)

    setBreakpoint(newBreakpoint)
  }, [width])

  const breakpointIndex = breakpointKeys.indexOf(breakpoint)

  switch (breakpointLimits.length) {
    case 0:
      return breakpoint
    case 1: {
      const breakpointLimit = breakpointLimits[0]
      const breakpointLimitIndex = breakpointKeys.indexOf(breakpointLimit)

      return breakpointIndex >= breakpointLimitIndex
    }

    default:
      return breakpointLimits.map((breakpointLimit) => {
        const breakpointLimitIndex = breakpointKeys.indexOf(breakpointLimit)

        return breakpointIndex >= breakpointLimitIndex
      })
  }
}

export default useBreakpointHandler
