import isFunction from 'lodash/isFunction'
import type { ReactNode } from 'react'
import React, { useEffect, useMemo, useState } from 'react'

import useQuery from '../../hooks/useQuery'
import chainEventHandler from '../../utilities/chainEventHandler'
import extractProps from '../../utilities/extractProps'
import PageSwitchTransition from '../PageSwitchTransition'
import SectionTransition from '../SectionTransition'

import FormNavigation from './FormNavigation'
import Navigation from './Navigation'
import NavigationContainer from './NavigationContainer'
import Page from './Page'
import type { PaginationPropType } from './PaginationPropType'

const TRANSITION_PROP_KEYS = ['beforePageChange', 'afterPageChange', 'appear', 'leave', 'timeout']

const calculatePaginationData = ({
  pageIndex,
  pageCount,
}: {
  pageIndex: number
  pageCount: number
}): PaginationPropType => ({
  index: pageIndex,
  number: pageIndex + 1,
  count: pageCount,
  first: pageIndex === 0,
  last: pageIndex === pageCount - 1,
})

export interface PaginatedSectionProps {
  pages?: React.ReactNode[]
  parentPagination?: {
    beforeCount: number
    count: number
  }
  children?: ReactNode
  hasNested?: boolean
  onPageChange?(data: unknown, previousData: unknown): void
  beforePageChange?(data: unknown): void
  afterPageChange?(data: unknown, previousData: unknown): void
  onComplete?(paginationData: unknown): void
  onBack?(paginationData: unknown): void
  pageCount?: number
  navigation?: boolean
  showPagination?: boolean
  navigationComponent?: React.ReactElement
  containerComponent?: React.ReactElement
  wrapperComponent?: React.ReactElement
  form?: boolean
  navigationProps?: {
    showOnLast?: boolean
    showOnFirst?: boolean
    nextButton?: React.ReactNode
    previousButton?: React.ReactNode
  }
  useQueryParam?: boolean
}

const PaginatedSection = ({
  parentPagination,
  children,
  onPageChange,
  beforePageChange,
  afterPageChange,
  onComplete,
  onBack,
  pageCount,
  navigation,
  form,
  showPagination,
  containerComponent,
  wrapperComponent,
  navigationComponent,
  navigationProps,
  hasNested,
  useQueryParam,
  ...otherProps
}: PaginatedSectionProps) => {
  const pages = useMemo(() => React.Children.toArray(children), [children])
  const [pageIndex, setPageIndex] = useState(0)
  let currentPage = pages[pageIndex]
  const finalPageCount = pageCount || pages.length
  const paginationData = calculatePaginationData({
    pageIndex,
    pageCount: finalPageCount,
  })

  const handleNextPage = () => {
    if (paginationData.last) {
      if (isFunction(onComplete)) onComplete(paginationData)
    } else {
      setPageIndex(pageIndex + 1)
    }
  }

  const handlePreviousPage = () => {
    if (paginationData.first) {
      if (isFunction(onBack)) onBack(paginationData)
    } else {
      setPageIndex(pageIndex - 1)
    }
  }

  const handleChangePage = useMemo(
    () => (newPageNumber: number) => {
      const newPageIndex = newPageNumber - 1

      if (newPageIndex >= 0 && newPageIndex < paginationData.count) setPageIndex(newPageNumber - 1)
    },
    [paginationData.count],
  )

  const handleOnPageChange = ({
    previousKey,
    forwards,
  }: {
    previousKey: number
    forwards: unknown
  }) => {
    if (onPageChange) {
      const previousPaginationData = calculatePaginationData({
        pageIndex: previousKey,
        pageCount: finalPageCount,
      })

      onPageChange(
        {
          ...paginationData,
          forwards,
        },
        previousPaginationData,
      )
    }
  }

  const { page: pageParam } = useQuery()

  useEffect(() => {
    if (useQueryParam) handleChangePage(pageParam)
  }, [useQueryParam, pageParam, handleChangePage])

  const handleBeforePageChange = () => {
    if (beforePageChange) beforePageChange(paginationData)
  }

  const handleAfterPageChange = ({
    previousKey,
    forwards,
  }: {
    previousKey: number
    forwards: unknown
  }) => {
    if (afterPageChange && pageCount) {
      const previousPaginationData = calculatePaginationData({
        pageIndex: previousKey,
        pageCount,
      })

      afterPageChange(
        {
          ...paginationData,
          forwards,
        },
        previousPaginationData,
      )
    }
  }

  if (parentPagination) {
    paginationData.absolute = {
      ...parentPagination,
      ...calculatePaginationData({
        pageIndex: parentPagination.beforeCount + pageIndex,
        pageCount: parentPagination.count,
      }),
    }
  } else {
    paginationData.absolute = { ...paginationData }
  }

  Object.assign(paginationData, {
    onNext: handleNextPage,
    onPrevious: handlePreviousPage,
    onChange: handleChangePage,
  })

  const [transitionExtraProps, extraProps] = extractProps(otherProps, TRANSITION_PROP_KEYS)

  const transitionProps = {
    pageKey: pageIndex,
    ...transitionExtraProps,
    onPageChange: handleOnPageChange,
    beforePageChange: handleBeforePageChange,
    afterPageChange: handleAfterPageChange,
  }

  // @ts-expect-error TS(2339) FIXME: Property 'props' does not exist on type 'string | ... Remove this comment to see the full error message
  const pagesData = useMemo(() => pages.map((page) => page.props.pageData), [pages])

  if (hasNested) {
    // @ts-expect-error TS(2339) FIXME: Property 'props' does not exist on type 'string | ... Remove this comment to see the full error message
    const pageCounts = pages.map((page) => page.props.pageCount)

    const nestedParentPagination = {
      beforeCount: pageCounts
        .slice(0, pageIndex)
        .reduce((sum, currentPageCount) => sum + currentPageCount, 0),
      count: pageCounts.reduce((sum, currentPageCount) => sum + currentPageCount, 0),
    }

    if (currentPage)
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      currentPage = React.cloneElement(currentPage, {
        // @ts-expect-error TS(2339) FIXME: Property 'props' does not exist on type 'string | ... Remove this comment to see the full error message
        ...currentPage.props,
        // @ts-expect-error TS(2339) FIXME: Property 'props' does not exist on type 'string | ... Remove this comment to see the full error message
        onComplete: chainEventHandler(handleNextPage, currentPage.props.onComplete),
        // @ts-expect-error TS(2339) FIXME: Property 'props' does not exist on type 'string | ... Remove this comment to see the full error message
        onBack: chainEventHandler(handlePreviousPage, currentPage.props.onBack),
        parentPagination: nestedParentPagination,
      })

    return (
      <PageSwitchTransition {...transitionProps}>
        <SectionTransition>{currentPage}</SectionTransition>
      </PageSwitchTransition>
    )
  }

  let containerProps = {}
  let ContainerComponent
  if (containerComponent !== undefined) {
    ContainerComponent = containerComponent
    containerProps = {
      pagination: paginationData,
      // @ts-expect-error TS(2339) FIXME: Property 'props' does not exist on type 'string | ... Remove this comment to see the full error message
      pageData: currentPage && currentPage.props.pageData,
      pagesData,
      ...extraProps,
    }
  } else if (form) {
    ContainerComponent = 'form'
    containerProps = { ...extraProps }
  } else {
    ContainerComponent = React.Fragment
  }

  console.log('navigationComponent', navigationComponent)
  console.log('form', form)
  let NavigationComponent
  if (navigationComponent !== undefined) {
    NavigationComponent = navigationComponent
  } else if (form) {
    NavigationComponent = FormNavigation
  } else {
    NavigationComponent = Navigation
  }

  const WrapperComponent = wrapperComponent || 'div'

  return (
    // @ts-expect-error TS(2604) FIXME: JSX element type 'ContainerComponent' does not hav... Remove this comment to see the full error message
    <ContainerComponent {...containerProps}>
      {/* @ts-expect-error TS(2604) FIXME: JSX element type 'WrapperComponent' does not have ... Remove this comment to see the full error message */}
      <WrapperComponent className="mb-grid">
        <PageSwitchTransition {...transitionProps}>
          <SectionTransition>
            <Page pagination={paginationData} showPagination={showPagination}>
              {currentPage}
            </Page>
          </SectionTransition>
        </PageSwitchTransition>
      </WrapperComponent>
      {navigation && (
        <NavigationContainer>
          {/* @ts-expect-error TS(2604) FIXME: JSX element type 'NavigationComponent' does not ha... Remove this comment to see the full error message */}
          <NavigationComponent pagination={paginationData} {...navigationProps} />
        </NavigationContainer>
      )}
    </ContainerComponent>
  )
}

PaginatedSection.defaultProps = {
  afterPageChange: undefined,
  beforePageChange: undefined,
  children: undefined,
  containerComponent: undefined,
  form: undefined,
  hasNested: undefined,
  navigation: true,
  navigationComponent: undefined,
  navigationProps: undefined,
  onBack: undefined,
  onComplete: undefined,
  onPageChange: undefined,
  pageCount: undefined,
  pages: undefined,
  parentPagination: undefined,
  showPagination: undefined,
  useQueryParam: false,
  wrapperComponent: undefined,
}

PaginatedSection.displayName = 'PaginatedSection'

export default PaginatedSection
