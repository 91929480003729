import { useContext } from 'react'

import Context from './Context'
import { getPaginationProperties } from './utilities'

const usePaginationContext = (useAbsolute = true) => {
  const context = useContext(Context)

  const paginationProperties = getPaginationProperties(context)

  if (useAbsolute) return { ...paginationProperties, ...paginationProperties.absolute }

  return paginationProperties
}

export default usePaginationContext
