const localeCode = 'da-DK'

const formatOptions = (options = {}) => {
  // @ts-expect-error TS(2339) FIXME: Property 'fractionDigits' does not exist on type '... Remove this comment to see the full error message
  const { fractionDigits, ...otherOptions } = options

  const result = {
    ...otherOptions,
  }

  if (fractionDigits !== undefined) {
    Object.assign(result, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
      ...otherOptions,
    })
  }

  return result
}

const buildNumberFormatter = (defaultOptions = {}) => {
  const formattedDefaultOptions = formatOptions(defaultOptions)

  return (number: any, customOptions = {}) =>
    Number(number).toLocaleString(localeCode, {
      ...formattedDefaultOptions,
      ...formatOptions(customOptions),
    })
}

export const numberToString = buildNumberFormatter()

export const numberToCurrency = buildNumberFormatter({
  style: 'currency',
  currency: 'DKK',
  fractionDigits: 0,
})

export const numberToPercent = buildNumberFormatter({
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
})

export const numbersToPercentRange = (startNumber: any, endNumber: any, customOptions = {}) => {
  const formattedStartNumber = numberToPercent(startNumber, customOptions)
  const formattedEndNumber = numberToPercent(endNumber, customOptions)

  // Use non-breaking spaces: \u00A0
  return `${formattedStartNumber}\u00A0-\u00A0${formattedEndNumber}`
}
