import React from 'react'

import PageWrapper from '../../PageWrapper'

export interface Props {
  children: React.ReactNode
}

const PageLayout = ({ children, ...otherProps }: Props) => (
  <PageWrapper {...otherProps}>{children}</PageWrapper>
)

PageLayout.displayName = 'ApplicationWrapper.Plugins.PageLayout'

export default PageLayout
