import throttle from 'lodash/throttle'
import { useState, useCallback, useRef, useEffect } from 'react'

import isSSR from '../utilities/isSSR'

import useWindowResizeHandler from './useWindowResizeHandler'

const getSize = () => {
  if (isSSR()) return {}

  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

const useWindowSize = (options = {}) => {
  // @ts-expect-error TS(2339) FIXME: Property 'throttle' does not exist on type '{}'.
  const { throttle: throttleTime } = options
  const [windowSize, setWindowSize] = useState(getSize())
  const throttledFunction = useRef()

  useEffect(() => {
    // @ts-expect-error TS(2322) FIXME: Type 'DebouncedFunc<() => void>' is not assignable... Remove this comment to see the full error message
    throttledFunction.current = throttle(() => {
      setWindowSize(getSize())
    }, throttleTime)
  }, [throttleTime])

  const handleResize = useCallback(() => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    throttledFunction.current()
  }, [])

  useWindowResizeHandler(handleResize)

  return windowSize
}

export default useWindowSize
