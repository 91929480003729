import { updatePortfolio, fetchPortfolio } from '../portfolios/actions'
import { changePortfolio } from '../portfolios/slice'

import { selectCurrentPortfolioId } from './selectors'

export const buildCurrentPortfolioAction =
  (action: any, options = {}) =>
  (inputValue: any) =>
  (dispatch: any, getState: any) => {
    // @ts-expect-error TS(2339) FIXME: Property 'buildPayload' does not exist on type '{}... Remove this comment to see the full error message
    const { buildPayload, includePortfolioId = true, payloadKey = 'attributes' } = options
    const state = getState()
    const portfolioId = selectCurrentPortfolioId(state)

    let actionPayload
    if (buildPayload === true) {
      actionPayload = inputValue
    } else if (buildPayload) {
      actionPayload = buildPayload({ state, portfolioId, inputValue })
    }

    if (includePortfolioId && actionPayload) {
      actionPayload = { portfolioId, [payloadKey]: actionPayload }
    } else if (includePortfolioId) {
      actionPayload = portfolioId
    }

    return dispatch(action(actionPayload))
  }

export const changeCurrentPortfolio = buildCurrentPortfolioAction(changePortfolio, {
  buildPayload: true,
})

export const updateCurrentPortfolio = buildCurrentPortfolioAction(updatePortfolio, {
  buildPayload: true,
})

export const fetchCurrentPortfolio = buildCurrentPortfolioAction(fetchPortfolio)
