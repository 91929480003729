import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import ForgotPasswordPage from './ForgotPasswordPage'
import LoginPage from './LoginPage'

const NotAuthPages = () => {
  const location = useLocation()
  const portfolioId = useSelector(selectCurrentPortfolioId)

  return (
    <Switch location={location}>
      <Route path="/brugere/logind" component={LoginPage} exact />
      <Route path="/brugere/adgangskode/glemt" component={ForgotPasswordPage} exact />
      <Redirect to={`/brugere/logind?portfolio_id=${portfolioId}`} />
    </Switch>
  )
}

export default NotAuthPages
