import Card from '@nord/ui/src/components/Card'
import Text from '@nord/ui/src/components/Text'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'
import Button from 'react-bootstrap/Button'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import Page from '../../../../components/Page'

const ScreeningError = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
  const { id: portfolioId, title } = useObjectSelector(selectCurrentPortfolio)

  return (
    <Page>
      <Card>
        <Card.Body>
          <Text as="h3" align="center">
            Du har ikke taget en investeringsplan
          </Text>
          <Text as="p" align="center" className="px-lg-5 mx-lg-5">
            Du har endnu ikke taget en investeringsplan for din portefølje {title}. For at kunne
            rådgive dig, skal du først tage en investeringsplan.
          </Text>
          <div className="d-flex justify-content-center mt-5">
            <Button as={Link} to={`/portefoeljer/${portfolioId}/egnethedstest`}>
              Investeringsplan
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Page>
  )
}

export default ScreeningError
