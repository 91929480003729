import isBoolean from 'lodash/isBoolean'
import React, { useMemo } from 'react'
import { FormCheck } from 'react-bootstrap'

import useUncontrollableProp from '../hooks/useUncontrollableProp'
import chainEventHandler from '../utilities/chainEventHandler'
import extractProps from '../utilities/extractProps'

import FieldLabel from './Form/FieldLabel'

const inputPropKeys = ['ref', 'isValid', 'isInvalid', 'isStatic', 'disabled', 'as']

export interface Props {
  asFormControl?: boolean
  checked?: boolean
  custom?: boolean
  id?: string
  label?: React.ReactNode
  name: string
  onChange?: (...args: any[]) => any
  onCheckedChange?: (...args: any[]) => any
  onValueChange?: (...args: any[]) => any
  type?: 'checkbox' | 'radio' | 'switch'
  value?: string | boolean
}

const BooleanInput = ({
  id,
  name,
  label,
  type,
  onChange,
  onValueChange,
  onCheckedChange,
  custom,
  asFormControl,
  value,
  checked,
  ...otherProps
}: Props) => {
  const [inputProps, wrapperProps] = extractProps(otherProps, inputPropKeys)

  const [internalChecked, handleChangeChecked, checkedIsControlled] = useUncontrollableProp(
    checked,
    // @ts-expect-error TS(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
    false,
    onCheckedChange,
  )

  const [internalValue, handleChangeValue, valueIsControlled] = useUncontrollableProp(
    value,
    // @ts-expect-error TS(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
    false,
    onValueChange,
  )
  const valueControlsChecked = !checkedIsControlled && valueIsControlled && isBoolean(value)

  const handleChange = useMemo(
    () =>
      chainEventHandler(onChange, (event: any) => {
        const { checked: newChecked, value: newValue } = event.target

        handleChangeChecked(newChecked)
        handleChangeValue(valueControlsChecked ? newChecked : newValue)
      }),
    [handleChangeChecked, handleChangeValue, onChange, valueControlsChecked],
  )

  const content = (
    <FormCheck custom={custom} id={id || name} type={type} {...wrapperProps}>
      <FormCheck.Input
        type={type === 'switch' ? 'checkbox' : type}
        name={name}
        onChange={handleChange}
        checked={valueControlsChecked ? internalValue : internalChecked}
        value={valueControlsChecked ? 'true' : internalValue}
        {...inputProps}
      />
      {/* @ts-expect-error TS(2739) FIXME: Type 'ForwardRefExoticComponent<FormCheckLabelProp... Remove this comment to see the full error message */}
      <FieldLabel label={label} name={name} component={FormCheck.Label} />
    </FormCheck>
  )

  if (asFormControl) return <div className="form-control form-control-boolean">{content}</div>

  return content
}

BooleanInput.defaultProps = {
  asFormControl: false,
  checked: undefined,
  custom: true,
  id: undefined,
  label: undefined,
  onChange: undefined,
  onCheckedChange: undefined,
  onValueChange: undefined,
  type: 'checkbox',
  value: undefined,
}

export default BooleanInput
