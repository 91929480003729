import { useState, useEffect, useMemo } from 'react'

import useDebouncedCallback from '../useDebouncedCallback'
import useDeepCompareMemoize from '../useDeepCompareMemoize'

export const defaultOptions = {
  autoCall: false,
  enableDebounce: false,
  debounceTimeout: 250,
}

const useApiRequest = <R = any>(sendRequest: any, options = {}) => {
  const { autoCall, enableDebounce, debounceTimeout, payload }: any = {
    ...defaultOptions,
    ...options,
  }
  const memoizedRequestData = useDeepCompareMemoize(payload)
  const [loading, setLoading] = useState(autoCall)

  const [requestState, setRequestState] = useState({
    success: undefined,
    data: undefined as R | undefined,
    error: undefined,
    called: false,
  })

  const callApi = useMemo(
    () => async (currentRequestData: any) => {
      const response = await sendRequest(currentRequestData)
      setRequestState({ ...response, called: true })
      setLoading(false)

      return response
    },
    [sendRequest],
  )

  let wrappedCallApi = callApi
  // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
  const debouncedApiCall = useDebouncedCallback(callApi, debounceTimeout)
  if (enableDebounce) wrappedCallApi = debouncedApiCall

  const startRequest = useMemo(
    () => (currentRequestData?: any) => {
      setLoading(true)

      return wrappedCallApi(currentRequestData || memoizedRequestData)
    },
    [memoizedRequestData, wrappedCallApi],
  )

  const cancelRequest = (message: any) => {
    // @ts-expect-error TS(2339) FIXME: Property 'cancel' does not exist on type '(current... Remove this comment to see the full error message
    if (wrappedCallApi.cancel) wrappedCallApi.cancel()
    if (sendRequest.cancel) sendRequest.cancel(message)

    setLoading(false)
  }

  useEffect(() => {
    if (!autoCall) return undefined

    startRequest()

    // @ts-expect-error TS(2339) FIXME: Property 'cancel' does not exist on type '(current... Remove this comment to see the full error message
    if (wrappedCallApi.cancel) return () => wrappedCallApi.cancel()

    return undefined
  }, [startRequest, wrappedCallApi, autoCall])

  return {
    ...requestState,
    loading,
    request: startRequest,
    cancel: cancelRequest,
  }
}

export default useApiRequest
