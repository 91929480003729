import React, { forwardRef, useContext, useState, useImperativeHandle } from 'react'

import AlertModal from '../../AlertModal'
import AnswersContext from '../AnswersContext'

export interface Props {
  title: string
  text: string
  condition?: {
    questionNumber: number
    answerIndex: number
  }
  answerIndex: number
  block?: boolean
  fail?: boolean
  onAccept: (...args: any[]) => any
  answer: string
  onFailure: (...args: any[]) => any
}

const WarningModal = forwardRef<any, Props>(
  ({ title, text, condition, answerIndex, block, fail, onAccept, answer, onFailure }, ref) => {
    // @ts-expect-error TS(2339) FIXME: Property 'answers' does not exist on type 'never[]... Remove this comment to see the full error message
    const { answers } = useContext(AnswersContext)
    const [showWarning, setShowWarning] = useState(false)

    useImperativeHandle(ref, () => ({
      validate: (answeredIndex: any) => {
        if (answeredIndex !== answerIndex) return false

        const { answerIndex: conditionAnswerIndex, questionNumber: conditionQuestionNumber } =
          condition || {}
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        if (condition && answers[conditionQuestionNumber - 1].answerIndex !== conditionAnswerIndex)
          return false

        setShowWarning(true)

        return true
      },
    }))

    const handleAcceptWarning = () => {
      if (fail) return onFailure(answer, answerIndex)

      setShowWarning(false)

      return onAccept(answer, answerIndex)
    }

    const handleHideWarning = () => setShowWarning(false)

    return (
      <AlertModal
        show={showWarning}
        acceptable={!block}
        onAccept={handleAcceptWarning}
        onHide={handleHideWarning}
        title={title}
        text={text}
      />
    )
  },
)

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
WarningModal.displayName = 'QuestionsSection.WarningModal'

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
WarningModal.defaultProps = {
  block: false,
  condition: undefined,
  fail: false,
}

export default WarningModal
