import pick from 'lodash/pick'
import React, { useContext, useCallback } from 'react'

import Form from '../../Form'
import usePaginationContext from '../usePaginationContext'

import PaginatedFormContext from './PaginatedFormContext'

export interface Props {
  children?: React.ReactNode
  externalContainerComponent?: React.ReactElement
  loading?: boolean
  onSubmit?: (...args: any[]) => any
}

const WrapperContainer = ({
  externalContainerComponent: ContainerComponent,
  children,
  onSubmit,
  loading,
  ...otherProps
}: Props) => {
  const pagination = usePaginationContext(false)
  const { index } = pagination
  const { formProps } = useContext(PaginatedFormContext)

  const handleSubmit = useCallback(
    async (newValues: any, formik: any) => {
      // @ts-expect-error TS(2538) FIXME: Type 'any' cannot be used as an index type.
      const { [index]: pageFormProps } = formProps
      const { onSubmit: pageOnSubmit, fields } = pageFormProps
      const pageValues = pick(newValues, fields)

      let wrapperResult
      if (onSubmit) {
        wrapperResult = await onSubmit(pageValues, formik, {
          values: newValues,
          pageFormProps,
          pagination: { ...pagination },
        })
      }

      const pageResult = await pageOnSubmit(pageValues, formik, newValues, {
        skipNext: !!wrapperResult?.data?.errors,
      })

      if (pageResult?.data?.errors) {
        return pageResult
      }

      if (wrapperResult?.data?.errors) {
        return wrapperResult
      }

      return undefined
    },
    [formProps, index, onSubmit, pagination],
  )

  let content = children
  // @ts-expect-error TS(2604) FIXME: JSX element type 'ContainerComponent' does not hav... Remove this comment to see the full error message
  if (ContainerComponent) content = <ContainerComponent>{children}</ContainerComponent>

  if (loading) return content

  return (
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    <Form {...otherProps} {...formProps[index]} onSubmit={handleSubmit}>
      {content}
    </Form>
  )
}

WrapperContainer.displayName = 'Paginator.Form.WrapperContainer'

WrapperContainer.defaultProps = {
  children: undefined,
  externalContainerComponent: undefined,
  loading: undefined,
  onSubmit: undefined,
}

export default WrapperContainer
