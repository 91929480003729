import Wrapper from '../Wrapper'

import withQueryParam from './withQueryParam'

const QueryParam = {
  Wrapper: withQueryParam(Wrapper),
}

export { default as usePageQueryParam } from './usePageQueryParam'
export { default as withQueryParam } from './withQueryParam'

export default QueryParam
