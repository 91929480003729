import NavigationContainer from './NavigationContainer'
import PaginatedSection from './PaginatedSection'
import Wrapper from './Wrapper'

// @ts-expect-error TS(2339) FIXME: Property 'NavigationContainer' does not exist on t... Remove this comment to see the full error message
PaginatedSection.NavigationContainer = NavigationContainer
// @ts-expect-error TS(2339) FIXME: Property 'Wrapper' does not exist on type '{ ({ pa... Remove this comment to see the full error message
PaginatedSection.Wrapper = Wrapper

export default PaginatedSection
