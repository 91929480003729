import classNames from 'classnames'
import React, { useState, useRef, useCallback } from 'react'

import useWindowEventHandler from '../../hooks/useWindowEventHandler'

import Container from './Container'
import styles from './StickyContainer.module.scss'

const topSpacing = 26 // (1.875 - 0.25)rem
const topHeight = 129

export interface StickyContainerProps {
  children?: React.ReactNode
  className?: string
  variant?: 'dark' | 'light'
} // 69 + 2 * 30

const StickyContainer = ({ className, children, variant, ...otherProps }: StickyContainerProps) => {
  const [showState, setShowState] = useState('top')
  const previousScrollPositionRef = useRef(0)

  const updateShowState = useCallback(() => {
    const previousScrollPosition = previousScrollPositionRef.current
    const currentScrollPosition = window.pageYOffset
    previousScrollPositionRef.current = currentScrollPosition
    const scrollUp = previousScrollPosition > currentScrollPosition

    if (
      currentScrollPosition <= topSpacing ||
      (showState === 'top' && currentScrollPosition <= topHeight)
    ) {
      setShowState('top')
    } else if (showState === 'top') {
      setShowState('initialHide')
    } else if (scrollUp) {
      setShowState('show')
    } else {
      setShowState('hide')
    }
  }, [showState])

  useWindowEventHandler('scroll', updateShowState)

  return (
    <div className="position-relative">
      <Container
        className={classNames(styles.stickyNavbar, variant, className, {
          [styles.isHidden]: showState === 'hide' || showState === 'initialHide',
          [styles.animateTransition]: showState !== 'initialHide',
          [styles.isFixed]: showState !== 'top',
          [styles.isOnTop]: showState === 'top',
        })}
        {...otherProps}
      >
        {children}
      </Container>
    </div>
  )
}

StickyContainer.defaultProps = {
  children: undefined,
  className: undefined,
  variant: 'dark',
}

export default StickyContainer
