import React from 'react'

import SectionContainer from '../SectionContainer'
import Text from '../Text'

import usePaginationContext from './usePaginationContext'

export interface PageContainerProps {
  children?: React.ReactNode
  showPagination?: ('relative' | 'absolute') | boolean
}

const PageContainer = ({ children, showPagination, ...otherProps }: PageContainerProps) => {
  const { number, count } = usePaginationContext(showPagination !== 'relative')

  return (
    <SectionContainer {...otherProps}>
      {showPagination && (
        <Text as="div" align="center" size="xs" variant="muted">
          <span data-cy="page-number">{number}</span> / {count}
        </Text>
      )}
      {children}
    </SectionContainer>
  )
}

PageContainer.defaultProps = {
  children: undefined,
  showPagination: undefined,
}

PageContainer.displayName = 'Paginator.PageContainer'

export default PageContainer
