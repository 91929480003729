import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export interface Props {
  children?: React.ReactNode
  acceptable?: boolean
  acceptButtonComponent?: React.ReactElement
  acceptButtonProps?: any
  acceptButtonText?: string
  onAccept?: (...args: any[]) => any
  onHide?: (...args: any[]) => any
  show?: boolean
  text?: string
  title: string
}

const AlertModal = ({
  onHide,
  title,
  text,
  children,
  acceptable,
  onAccept,
  show,
  acceptButtonText,
  acceptButtonComponent: AcceptButtonComponent,
  acceptButtonProps,
  ...modalProps
}: Props) => (
  <Modal {...modalProps} className="text-dark" onHide={onHide} show={show} centered>
    <Modal.Header closeButton>
      <Modal.Title className="w-100 font-size-xxxl" data-cy="modal-title">
        {title}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      {text}
      {children}
    </Modal.Body>

    <Modal.Footer className="justify-content-right">
      <Button
        variant={acceptable ? 'link' : 'primary'}
        onClick={onHide}
        data-cy="modal-back-button"
      >
        Tilbage
      </Button>

      {acceptable && (
        // @ts-expect-error TS(2604) FIXME: JSX element type 'AcceptButtonComponent' does not ... Remove this comment to see the full error message
        <AcceptButtonComponent variant="primary" onClick={onAccept} {...acceptButtonProps}>
          {acceptButtonText}
        </AcceptButtonComponent>
      )}
    </Modal.Footer>
  </Modal>
)

AlertModal.defaultProps = {
  acceptable: undefined,
  acceptButtonComponent: Button,
  acceptButtonProps: undefined,
  acceptButtonText: 'Jeg ønsker at fortsætte',
  children: undefined,
  onAccept: undefined,
  onHide: undefined,
  show: undefined,
  text: undefined,
}

export default AlertModal
