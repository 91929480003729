import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ReactIs from 'react-is'

import type { BaffleButtonProps } from './BaffleButton'
import BaffleButton from './BaffleButton'
import Icon from './Icon'

export type IconButtonProps<E extends React.ElementType> = BaffleButtonProps<E> & {
  buttonAs?: E
  download?: boolean
  iconPlacement?: 'first' | 'last'
  icon?: React.ReactElement | any
}

const IconButton = <E extends React.ElementType = React.ElementType>(
  { as, buttonAs, icon, iconPlacement, children, ...buttonProps }: IconButtonProps<E>,
  ref: React.ForwardedRef<HTMLElement>,
) => {
  let iconProps
  if (ReactIs.isValidElementType(icon)) {
    iconProps = { icon }
  } else {
    iconProps = icon
  }

  let content
  if (iconProps === undefined) {
    content = children
  } else if (iconPlacement === 'first') {
    content = (
      <>
        <Icon {...iconProps} /> {children}
      </>
    )
  } else {
    content = (
      <>
        {children} <Icon {...iconProps} />
      </>
    )
  }

  const Component = as || BaffleButton

  return (
    <Component ref={ref} as={buttonAs} {...buttonProps}>
      {content}
    </Component>
  )
}

IconButton.defaultProps = {
  buttonAs: undefined,
  download: undefined,
  icon: undefined,
  iconPlacement: undefined,
}

IconButton.displayName = 'IconButton'

export default React.forwardRef(IconButton)
