import { useEffect } from 'react'

import isSSR from '../utilities/isSSR'

const useWindowEventHandler = (event: any, eventHandler: any) => {
  useEffect(() => {
    if (isSSR()) return undefined

    window.addEventListener(event, eventHandler)

    return () => window.removeEventListener(event, eventHandler)
  }, [event, eventHandler])
}

export default useWindowEventHandler
