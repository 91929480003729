import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import IconButton from '../../IconButton'
import useInternalContext from '../useInternalContext'

export interface Props {
  children?: React.ReactNode
}

const NextButton = ({ children, ...otherProps }: Props) => {
  // @ts-expect-error TS(2339) FIXME: Property 'onNext' does not exist on type 'unknown'... Remove this comment to see the full error message
  const { onNext } = useInternalContext()

  return (
    <IconButton
      onClick={onNext}
      variant="primary"
      icon={ArrowRight}
      data-cy="next-button"
      filled
      {...otherProps}
    >
      {children}
    </IconButton>
  )
}

NextButton.defaultProps = {
  children: 'Næste',
}

NextButton.displayName = 'Paginator.Navigation.NextButton'

export default NextButton
