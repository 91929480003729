import { useEffect, useState } from 'react'

import isSSR from '../utilities/isSSR'

const useCurrentDevice = () => {
  const isServerSideRendering = isSSR()
  const [currentDevice, setCurrentDevice] = useState()

  useEffect(() => {
    if (isServerSideRendering) return

    import('current-device').then(({ default: importedCurrentDevice }) => {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'CurrentDeviceInterface' is not a... Remove this comment to see the full error message
      setCurrentDevice(importedCurrentDevice)
    })
  }, [isServerSideRendering])

  return currentDevice
}

export default useCurrentDevice
