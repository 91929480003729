import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import Form from '../../Form'

export interface Props {
  children?: React.ReactNode
}

const NextButton = ({ children, ...otherProps }: Props) => (
  <Form.SubmitButton
    // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; variant: string; icon... Remove this comment to see the full error message
    variant="primary"
    icon={ArrowRight}
    data-cy="next-button"
    filled
    {...otherProps}
  >
    {children}
  </Form.SubmitButton>
)

NextButton.defaultProps = {
  children: 'Næste',
}

NextButton.displayName = 'Paginator.Form.NextButton'

export default NextButton
