import DropIn from './DropIn'
import Expand from './Expand'
import Slide from './Slide'

const Transition = {
  DropIn,
  Expand,
  Slide,
}

export const DropInTransition = DropIn
export const ExpandTransition = Expand
export const SlideTransition = Slide

export default Transition
