import React, { useContext } from 'react'

import Sortable from '../../Sortable'

import Item from './Item'
import SortingContext from './SortingContext'

const OneColSorting = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'items' does not exist on type 'unknown'.
  const { items, setItems } = useContext(SortingContext)

  return (
    <Sortable.Wrapper items={items} onOrderChange={setItems}>
      <Sortable.List id="ordered" items={items.ordered}>
        {items.ordered.map((item: any) => (
          <Sortable.Item id={item} key={item} className="d-flex flex-row justify-content-between">
            <Item value={item} />
          </Sortable.Item>
        ))}
      </Sortable.List>
    </Sortable.Wrapper>
  )
}

export default OneColSorting
