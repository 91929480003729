import NordHeader from '@nord/ui/src/components/Header'
import LogOutButton from '@nord/ui/src/components/LogOutButton'
import { getConfig } from '@nord/ui/src/configuration'
import useIsSignedIn from '@nord/ui/src/hooks/useIsSignedIn'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import classNames from 'classnames'
import React from 'react'
import { Container } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import styles from './styles.module.scss'

const webappUrl = getConfig('urls.app.signIn')

const Header = () => {
  const isSignedIn = useIsSignedIn()
  // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type 'unknown'.
  const { email } = useObjectSelector(selectCurrentUser)

  const handleSignOut = () => {
    window.location.assign(`${webappUrl}?email=${email}`)
  }

  return (
    <NordHeader mobile>
      <Container className="d-flex justify-content-center" fluid>
        <Navbar
          role="banner"
          className="d-flex justify-content-between w-sm-100 w-lg-75 w-xl-50 w-100"
        >
          <NordHeader.Logo variant="light" />
          <Nav>
            {/* Hidden className is necessary to avoid the absolute positioned logo changing position when their is no content */}
            <Nav.Item className={classNames({ [styles.hidden]: !isSignedIn })}>
              {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ className, children, ...otherProps }: P... Remove this comment to see the full error message */}
              <LogOutButton as={NordHeader.Link} onSignOut={handleSignOut}>
                Log ud
              </LogOutButton>
            </Nav.Item>
          </Nav>
        </Navbar>
      </Container>
    </NordHeader>
  )
}

export default Header
