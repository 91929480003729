import classNames from 'classnames'
import React from 'react'
import { CSSTransition } from 'react-transition-group'

import useElementSize from '../../hooks/useElementSize'
import extractTransitionClasses from '../../utilities/extractTransitionClasses'

import styles from './Expand.module.scss'

const defaultTimeout = parseInt(styles.timeout, 10)

export interface Props {
  className?: string
  children: React.ReactNode
  delay?: number
  in?: boolean
  timeout?: number
}

const Expand = ({ children, delay, in: show, className, timeout, ...otherProps }: Props) => {
  const [sizeTargetRef, size] = useElementSize()
  // @ts-expect-error TS(2339) FIXME: Property 'height' does not exist on type '{}'.
  const { height } = size

  const wrapperStyle = {}

  // @ts-expect-error TS(2339) FIXME: Property 'height' does not exist on type '{}'.
  if (height && show) wrapperStyle.height = height
  // @ts-expect-error TS(2339) FIXME: Property 'transitionDuration' does not exist on ty... Remove this comment to see the full error message
  if (timeout) wrapperStyle.transitionDuration = `${timeout}ms`

  return (
    <CSSTransition
      classNames={extractTransitionClasses({
        styles,
        className: 'expand',
        appearKey: 'enter',
      })}
      in={show}
      timeout={timeout || defaultTimeout}
      appear
      {...otherProps}
    >
      <div className={classNames(className, styles.expand)} style={wrapperStyle}>
        {/* @ts-expect-error TS(2322) FIXME: Type '{}' is not assignable to type 'LegacyRef<HTM... Remove this comment to see the full error message */}
        <div ref={sizeTargetRef}>{children}</div>
      </div>
    </CSSTransition>
  )
}

Expand.defaultProps = {
  className: undefined,
  delay: 0,
  in: false,
  timeout: undefined,
}

export default Expand
