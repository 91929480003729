import File from './File'
import Phone from './Phone'

const Fields = {
  Phone,
  File,
}

export { default as PhoneField, initialValue as phoneInitialValue } from './Phone'

export default Fields
