import isNumber from 'lodash/isNumber'
import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect } from 'react'
import ReactResizeDetector from 'react-resize-detector'

import isSSR from '../../utilities/isSSR'
// import { inlineStyle } from '../../utilities/propTypes'

import convertStyle from './convertStyle'
import getWindowHeight from './getWindowHeight'

const getConvertedStyle = (style: any) => convertStyle(style, getWindowHeight())

export interface ViewPortDivProps {
  as?: React.ReactElement
  style?: any // TODO: inlineStyle()
  height?: string | number
  resizeDetectorProps?: any // TODO: PropTypes.shape(resizeDetectorPropTypes || {})
  minimum?: boolean
}

const ViewPortDiv = React.forwardRef<any, ViewPortDivProps>(
  ({ as: Component, style, height, resizeDetectorProps, minimum, ...otherProps }, ref) => {
    const [innerStyle, setInnerStyle] = useState({})

    const updateStyle = useCallback(() => {
      const heightAttribute = minimum ? 'minHeight' : 'height'

      let styleHeight
      if (isNumber(height)) {
        styleHeight = `${height}rvh`
      } else {
        styleHeight = height || (style || {})[heightAttribute]
      }

      if (styleHeight === undefined) styleHeight = '100rvh'

      const styleWithHeight = { ...style, [heightAttribute]: styleHeight }
      setInnerStyle(getConvertedStyle(styleWithHeight))
    }, [minimum, height, style])

    useEffect(() => {
      updateStyle()
    }, [updateStyle])

    return (
      <>
        <ReactResizeDetector
          refreshMode="debounce"
          refreshRate={100}
          onResize={updateStyle}
          targetDomEl={!isSSR() && window}
          nodeType={React.Fragment}
          handleHeight
          {...resizeDetectorProps}
        />
        {/* @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message */}
        <Component {...otherProps} style={innerStyle} ref={ref} />
      </>
    )
  },
)

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
// eslint-disable-next-line react/forbid-foreign-prop-types
const resizeDetectorPropTypes = ReactResizeDetector.propTypes
if (resizeDetectorPropTypes) resizeDetectorPropTypes.nodeType = PropTypes.elementType

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
ViewPortDiv.defaultProps = {
  as: 'div',
  height: undefined,
  minimum: undefined,
  resizeDetectorProps: undefined,
  style: undefined,
}

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
ViewPortDiv.displayName = 'ViewPortDiv'

export default ViewPortDiv
