const threshold = 1000
const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

const humanFileSize = (bytes: any) => {
  if (Math.abs(bytes) < threshold) {
    return `${bytes} B`
  }

  let amount = bytes
  let index = -1
  do {
    amount /= threshold
    index += 1
  } while (Math.abs(amount) >= threshold && index < units.length - 1)

  return `${amount.toFixed(1)} ${units[index]}`
}

export default humanFileSize
