import { createSlice } from '@reduxjs/toolkit'
import map from 'lodash/map'

import { fetchPortfolios } from '../portfolios/actions'
import { signOutUser } from '../user/actions'
import { userSignedOut } from '../user/slice'

export const initialState = null

export const portfolioIdSlice = createSlice({
  name: 'current.portfolioId',
  initialState,
  reducers: {
    setCurrentPortfolioId: (_state, { payload }) => payload,
    clearCurrentPortfolioId: () => initialState,
  },
  extraReducers: {
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [userSignedOut]: () => initialState,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [signOutUser.fulfilled]: () => initialState,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [fetchPortfolios.fulfilled]: (state: any, { payload: portfolios, meta: { arg } }: any) => {
      const { setPortfolioId } = arg || {}

      const portfolioIds = map(portfolios || [], 'id')
      const validPortfolioId = state && portfolioIds.includes(state)

      if (!validPortfolioId && setPortfolioId && portfolios && portfolios[0])
        return portfolios[0].id

      return state
    },
  },
})

export const { setCurrentPortfolioId, clearCurrentPortfolioId } = portfolioIdSlice.actions

export default portfolioIdSlice
