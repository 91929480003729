import Card from '@nord/ui/src/components/Card'
import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'

import MyAccountButton from '../../../../components/MyAccountButton'
import Page from '../../../../components/Page'
import PortfolioTitle from '../../../../components/PortfolioTitle'

const webappLink = getConfig('domains.app')
const getOnboardingDynamicUrl = getConfig('dynamicUrls.onboarding.investmentPlan')

const SuitabilityUpdateRejected = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'onboardingFlow' does not exist on type '... Remove this comment to see the full error message
  const { onboardingFlow, id: portfolioId } = useObjectSelector(selectCurrentPortfolio)

  const investmentPlanUrl = getOnboardingDynamicUrl(onboardingFlow, portfolioId)

  return (
    <Page>
      <Card>
        <Card.Body>
          <Text as="h3" align="center">
            Din portefølje forbliver uændret
          </Text>
          <PortfolioTitle />
          <Text as="p" align="center">
            Husk, at du altid kan
            <Text as="a" href={investmentPlanUrl}>
              {' '}
              ændre din risikoproﬁl{' '}
            </Text>
            .
          </Text>
          <Text as="p" align="center">
            Hvis der sker væsentlige ændringer i din økonomi, tidshorisont eller risikovillighed, så
            anbefaler vi, at du igen opdaterer din risikovurdering.
          </Text>
          <Text as="p" align="center">
            Du kan altid finde et link til det i vores{' '}
            <Text as="a" href={webappLink}>
              app
            </Text>{' '}
            under{' '}
            <Text as="a" href={`${webappLink}/portefoeljer/${portfolioId}`}>
              "Mere"
            </Text>
            .
          </Text>

          <div className="d-flex justify-content-center mt-5">
            <MyAccountButton />
          </div>
        </Card.Body>
      </Card>
    </Page>
  )
}

export default SuitabilityUpdateRejected
