import { useFormikContext } from 'formik'
import React from 'react'

import LoadingButton from '../LoadingButton'

const checkVisibleErrors = ({ isValid, errors, touched }: any, disableOnInvalid: any) => {
  if (!disableOnInvalid) return false
  if (disableOnInvalid === 'visible') return Object.keys(errors).some((field) => touched[field])

  return !isValid
}

export interface SubmitButtonProps {
  children: React.ReactNode
  disabled?: boolean
  disableOnInvalid?: any // TODO: PropTypes.oneOf([true, false, 'visible'])
  loading?: boolean
}

const SubmitButton = ({
  children,
  disableOnInvalid,
  disabled,
  loading,
  ...otherProps
}: SubmitButtonProps) => {
  const formikContext = useFormikContext()
  const { isSubmitting } = formikContext
  const hasVisibleErrors = checkVisibleErrors(formikContext, disableOnInvalid)

  return (
    <LoadingButton
      // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; type: string; loading... Remove this comment to see the full error message
      type="submit"
      loading={loading || isSubmitting}
      variant="light"
      disabled={disabled || hasVisibleErrors}
      {...otherProps}
    >
      {children}
    </LoadingButton>
  )
}

SubmitButton.displayName = 'Form.SubmitButton'

SubmitButton.defaultProps = {
  disabled: undefined,
  disableOnInvalid: 'visible',
  loading: false,
}

export default SubmitButton
