import React from 'react'

import chainFunctions from '../utilities/chainFunctions'

// We use the most basic markdown rules for formatting

const headerSplitRegex = /\n?(#+.+)$\n?/m
const headerReplaceRegex = /#+(.+)$/gm
const headerCountRegex = /#/g
const withHeaders = (value: any) =>
  value
    .split(headerSplitRegex)
    .map((part: any) => {
      const headerLevel = (part.match(headerCountRegex) || []).length

      if (!headerLevel) return part

      const headerTag = `h${headerLevel}`

      return part.replace(headerReplaceRegex, `<${headerTag}>$1</${headerTag}>`)
    })
    .join('')

const withParagraphs = (value: any) =>
  value
    .split('\n\n')
    .map((paragraph: any) => `<p>${paragraph}</p>`)
    .join('')

const withNewLines = (value: any) => value.split('\n').join('<br/>')

const toFormattedText = chainFunctions(withParagraphs, withHeaders, withNewLines)

export interface SimpleMarkdownTextProps {
  children?: string
}

const SimpleMarkdownText = ({ children }: SimpleMarkdownTextProps) => {
  if (!children) return null

  return (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{ __html: toFormattedText(children) }} />
  )
}

SimpleMarkdownText.defaultProps = {
  children: undefined,
}

export default SimpleMarkdownText
