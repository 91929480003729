import { createSlice } from '@reduxjs/toolkit'

import { updateFromApiResponse } from '../../helpers'

import { initialUserLoad, signInUser, signOutUser } from './actions'

export const initialState = {}

export const userSlice = createSlice({
  name: 'current.user',
  initialState,
  reducers: {
    updateUser: (state, { payload: { attributes } }) => ({
      ...state,
      ...attributes,
    }),
    userSignedOut: () => initialState,
    changeCurrentUser: (state, { payload }) => ({ ...state, ...payload }),
  },
  extraReducers: {
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [initialUserLoad.fulfilled]: updateFromApiResponse,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [signInUser.fulfilled]: updateFromApiResponse,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [signOutUser.fulfilled]: () => initialState,
  },
})

export const { userSignedOut, updateUser, changeCurrentUser } = userSlice.actions

export default userSlice
