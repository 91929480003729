import React from 'react'
import type { ButtonVariant } from 'react-bootstrap/esm/types'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import BaffleButton, { type BaffleButtonProps } from './BaffleButton'
import IconButton, { type IconButtonProps } from './IconButton'

export type BaffleButtonLinkProps<E extends React.ElementType> = BaffleButtonProps<E> & {
  as?: E
  to: string | { pathname: string; search: string }
}

export const BaffleButtonLink = <E extends React.ElementType = React.ElementType>({
  ...props
}: BaffleButtonLinkProps<E>) => <BaffleButton type={undefined} {...props} />

BaffleButtonLink.defaultProps = {
  as: Link,
}

export type IconButtonLinkProps<E extends React.ElementType> = IconButtonProps<E> & {
  to: string | { pathname: string; search: string }
  variant?: ButtonVariant
}

const IconButtonLink = <E extends React.ElementType = React.ElementType>({
  as,
  ...props
}: IconButtonLinkProps<E>) => {
  const Component = as || (BaffleButtonLink as React.ElementType)

  return <IconButton as={Component} {...props} />
}

IconButtonLink.defaultProps = {
  variant: undefined,
}

export default IconButtonLink
