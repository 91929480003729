import { useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import React from 'react'

export interface Props {
  children?: React.ReactNode
  className?: string
  id: string
  items: string[]
  placeholder?: string
}

const List = ({ id, items, placeholder, children, className, ...otherProps }: Props) => {
  const { setNodeRef } = useDroppable({
    id,
  })

  return (
    <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
      <div
        ref={setNodeRef}
        className={classNames('p-4 children-my-3 flex-1 border w-100', className)}
        {...otherProps}
      >
        {placeholder && isEmpty(items) && (
          <div className="text-secondary text-center d-flex h-100 align-items-center justify-content-center">
            {placeholder}
          </div>
        )}
        {children}
      </div>
    </SortableContext>
  )
}

List.defaultProps = {
  children: undefined,
  className: undefined,
  placeholder: undefined,
}

export default List
