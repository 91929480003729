import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import chainEventHandler from '../../../utilities/chainEventHandler'

import usePageQueryParam from './usePageQueryParam'

const withQueryParam = (WrappedComponent: any) => {
  const WrapperComponent = ({
    onPageChange,
    useAbsolute,
    history,
    useOnLoad,
    ...otherProps
  }: any) => {
    const [handlePageChangeWithQueryParam, initialPageNumber] = usePageQueryParam({
      history,
      useAbsolute,
      useOnLoad,
    })

    const handlePageChange = useMemo(
      () => chainEventHandler(handlePageChangeWithQueryParam, onPageChange),
      [handlePageChangeWithQueryParam, onPageChange],
    )

    return (
      <WrappedComponent
        {...otherProps}
        onPageChange={handlePageChange}
        initialPageNumber={initialPageNumber}
      />
    )
  }

  WrapperComponent.displayName = `withQueryParam(${
    WrappedComponent.displayName || WrappedComponent.name
  })`

  WrapperComponent.propTypes = {
    history: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    onPageChange: PropTypes.func,
    useAbsolute: PropTypes.bool,
    useOnLoad: PropTypes.bool,
  }

  WrapperComponent.defaultProps = {
    history: undefined,
    onPageChange: undefined,
    useAbsolute: undefined,
    useOnLoad: undefined,
  }

  return WrapperComponent
}

export default withQueryParam
