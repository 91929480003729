import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import BaffleButton from '../BaffleButton'

import type { PaginationPropType } from './PaginationPropType'

export interface NavigationProps {
  nextButton?: React.ReactNode
  pagination: PaginationPropType
  previousButton?: React.ReactNode
  showOnLast?: boolean
  showOnFirst?: boolean
}

const Navigation = ({
  nextButton,
  pagination: { onNext, onPrevious, absolute },
  previousButton,
  showOnFirst,
  showOnLast,
}: NavigationProps) => (
  <>
    {((absolute && !absolute.first) || showOnFirst) &&
      (previousButton || (
        <BaffleButton onClick={onPrevious} variant="link" className="text-light">
          Tilbage
        </BaffleButton>
      ))}
    {((absolute && !absolute.last) || showOnLast) &&
      (nextButton || (
        <BaffleButton onClick={onNext} variant="success" icon={ArrowRight} filled>
          Næste
        </BaffleButton>
      ))}
  </>
)

Navigation.defaultProps = {
  nextButton: undefined,
  previousButton: undefined,
  showOnFirst: false,
  showOnLast: false,
}

Navigation.displayName = 'PaginatedSection.Navigation'

export default Navigation
