import { createContext } from 'react'

const PaginatedFormContext = createContext({
  formProps: undefined,
  onChangeFormProps: undefined,
})

PaginatedFormContext.displayName = 'Paginator.Form.Context'

export default PaginatedFormContext
