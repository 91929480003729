import React from 'react'
import { components } from 'react-select'

import FlagIcon from './FlagIcon'

export interface Props {
  children: React.ReactNode
  data: {
    value: string
    code?: string
    displayValue?: string
  }
}

const SingleValue = ({ children, data, ...otherProps }: Props) => {
  const { value, code, displayValue } = data

  return (
    // @ts-expect-error TS(2740) FIXME: Type '{ children: (ReactNode | Element)[]; data: {... Remove this comment to see the full error message
    <components.SingleValue data={data} {...otherProps}>
      <FlagIcon countryCode={code || value} /> {displayValue || children}
    </components.SingleValue>
  )
}

export default SingleValue
