import classNames from 'classnames'
import isArray from 'lodash/isArray'
import PropTypes from 'prop-types'
import React from 'react'
import FormControl from 'react-bootstrap/FormControl'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Loader from 'react-feather/dist/icons/loader'

import useCurrentDevice from '../hooks/useCurrentDevice'
import chainEventHandler from '../utilities/chainEventHandler'
import isSSR from '../utilities/isSSR'

import Icon from './Icon'
import StyledReactSelect from './StyledReactSelect'

const MOBILE_OPTIONS_LIMIT = 10

const shouldUseBrowserInput = (currentDevice: any, options: any) => {
  if (currentDevice === undefined) return false

  // @ts-expect-error TS(2365) FIXME: Operator '>' cannot be applied to types 'boolean' ... Remove this comment to see the full error message
  return !isSSR() && !currentDevice.desktop() && options && !options.length > MOBILE_OPTIONS_LIMIT
}

const findOption = (options: any, value: any) => {
  if (!value || !isArray(options)) return null

  return options.find((option) => option.value === value) || null
}

export interface SelectProps {
  allowBrowserInput?: boolean
  defaultOptions?: OptionPropTypes[]
  defaultSelected?: OptionPropTypes
  noOptionsMessage?: (...args: any[]) => any
  onChange?: (...args: any[]) => any
  onSelect?: (...args: any[]) => any
  onValueChange?: (...args: any[]) => any
  options?: OptionPropTypes[]
  placeholder?: string
  selectedOption?: OptionPropTypes
  useBrowserInput?: boolean
  value?: string | OptionPropTypes
}

const Select = ({
  onChange,
  onValueChange,
  onSelect,
  options,
  defaultOptions,
  value,
  selectedOption: selectedOptionProp,
  defaultSelected,
  placeholder,
  allowBrowserInput,
  useBrowserInput,
  ...otherProps
}: SelectProps) => {
  const currentDevice = useCurrentDevice()
  const loading = !currentDevice

  let selectedOption = selectedOptionProp
  if (value && (!selectedOption || selectedOption.value !== value))
    selectedOption = findOption(options || defaultOptions, value)

  if (loading) return <Icon icon={Loader} spin />

  if (allowBrowserInput && (useBrowserInput || shouldUseBrowserInput(currentDevice, options))) {
    const handleInputChange = chainEventHandler((event: any) => {
      const {
        target: { value: newValue },
      } = event
      if (onValueChange) onValueChange(newValue)

      if (onSelect) {
        const option = findOption(options || defaultOptions, newValue)

        onSelect(option)
      }
    }, onChange)

    return (
      <FormControl
        bsPrefix="custom-select"
        as="select"
        onChange={handleInputChange}
        className={classNames({ 'text-muted': !value })}
        {...otherProps}
      >
        <option selected={!value} disabled>
          {placeholder}
        </option>
        {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
        {options.length > 0 &&
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          options.map(({ label, value: optionValue }) => (
            <option value={optionValue}>{label}</option>
          ))}
      </FormControl>
    )
  }

  const handleChange = (newOption: any) => {
    const { value: newValue } = newOption

    if (onSelect) onSelect(newOption)
    if (onValueChange) onValueChange(newValue)
  }

  return (
    <StyledReactSelect
      // @ts-expect-error TS(2322) FIXME: Type '{ noOptionsMessage?: ((...args: any[]) => an... Remove this comment to see the full error message
      onChange={handleChange}
      value={selectedOption}
      defaultValue={defaultSelected}
      options={options}
      defaultOptions={defaultOptions}
      placeholder={placeholder}
      {...otherProps}
    />
  )
}

type OptionPropTypes = {
  value?: any
  label?: string
}

Select.defaultProps = {
  allowBrowserInput: true,
  defaultOptions: undefined,
  defaultSelected: undefined,
  noOptionsMessage: () => 'Ingen muligheder',
  onChange: undefined,
  onSelect: undefined,
  onValueChange: undefined,
  options: undefined,
  placeholder: 'Vælg...',
  selectedOption: undefined,
  useBrowserInput: undefined,
  value: undefined,
}

export default Select
