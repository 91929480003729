import camelCase from 'lodash/camelCase'
import queryString from 'query-string'

import { deepMapKeys } from '../utilities/deepIterate'
import isSSR from '../utilities/isSSR'

const useQuery = (key?: string) => {
  if (isSSR()) return key ? null : {}

  const params = queryString.parse(window.location.search)
  const formattedParams = deepMapKeys(params, camelCase)

  if (key) return formattedParams[key]

  return formattedParams
}

export default useQuery
