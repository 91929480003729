import PropTypes from 'prop-types'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import BaffleButton from '../BaffleButton'

import Navigation from './Navigation'

export interface Props {
  nextButton?: React.ReactNode
}

const FormNavigation = ({ nextButton, ...otherProps }: Props) => (
  // @ts-expect-error TS(2741) FIXME: Property 'pagination' is missing in type '{ nextBu... Remove this comment to see the full error message
  <Navigation
    nextButton={
      nextButton || (
        <BaffleButton variant="success" icon={ArrowRight} type="submit" filled>
          Næste
        </BaffleButton>
      )
    }
    {...otherProps}
  />
)

FormNavigation.defaultProps = {
  // @ts-expect-error TS(2339) FIXME: Property 'undefined' does not exist on type 'typeo... Remove this comment to see the full error message
  nextButton: PropTypes.undefined,
}

FormNavigation.displayName = 'PaginatedSection.FormNavigation'

export default FormNavigation
