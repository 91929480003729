import { object, string } from 'yup'
import '../../configuration/setup/yup'

const username = string().email().required()
const password = string().min(6).max(128).required()

const emailValidationSchema = object().shape({
  username,
})

const fullValidationSchema = object().shape({
  username,
  password,
})

const validationSchema = (validatePassword = false) =>
  validatePassword ? fullValidationSchema : emailValidationSchema

export default validationSchema
