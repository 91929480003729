import isEqual from 'lodash/isEqual'
import { useRef } from 'react'

const useDeepCompareMemoize = <T>(value: T) => {
  const ref = useRef(value)

  if (!isEqual(value, ref.current)) ref.current = value

  return ref.current
}

export default useDeepCompareMemoize
