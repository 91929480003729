import { useLayoutEffect } from 'react'

const useToggleHtmlClass = (className: any, addClass = false) => {
  useLayoutEffect(() => {
    if (addClass === false) return undefined

    document.documentElement.classList.add(className)

    return () => {
      document.documentElement.classList.remove(className)
    }
  }, [className, addClass])
}

export default useToggleHtmlClass
