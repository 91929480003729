import React, { useMemo } from 'react'

import chainEventHandler from '../../../utilities/chainEventHandler'
import { calculateAbsolutePaginationData } from '../utilities'
import Wrapper from '../Wrapper'

const extendEventWithAbsolutePagination =
  (eventHandler: any, absolutePagination: any) => (current: any, previous: any) => {
    if (!eventHandler) return

    eventHandler(
      calculateAbsolutePaginationData(current, absolutePagination),
      calculateAbsolutePaginationData(previous, absolutePagination),
    )
  }

export interface AbsoluteWrapperProps {
  absolutePagination: any // TODO: absolutePaginationPropType()
  children?: React.ReactNode
  extraPagination?: any
  parentPagination?: any // TODO: paginationPropType()
  afterPageChange?: (...args: any[]) => any
  beforePageChange?: (...args: any[]) => any
  onBack?: (...args: any[]) => any
  onComplete?: (...args: any[]) => any
  onPageChange?: (...args: any[]) => any
}

const AbsoluteWrapper = ({
  parentPagination,
  absolutePagination,
  children,
  onComplete,
  onBack,
  onPageChange,
  beforePageChange,
  afterPageChange,
  extraPagination,
  ...otherProps
}: AbsoluteWrapperProps) => {
  const { onPrevious, onNext } = parentPagination || {}

  const newExtraPagination = useMemo(
    () => ({ ...extraPagination, absolute: absolutePagination }),
    [extraPagination, absolutePagination],
  )

  const handleOnComplete = useMemo(
    () => chainEventHandler(onComplete, onNext),
    [onComplete, onNext],
  )
  const handleOnBack = useMemo(() => chainEventHandler(onBack, onPrevious), [onBack, onPrevious])

  const handleOnPageChange = useMemo(
    () => extendEventWithAbsolutePagination(onPageChange, absolutePagination),
    [onPageChange, absolutePagination],
  )

  const handleBeforePageChange = useMemo(
    () => extendEventWithAbsolutePagination(beforePageChange, absolutePagination),
    [beforePageChange, absolutePagination],
  )

  const handleAfterPageChange = useMemo(
    () => extendEventWithAbsolutePagination(afterPageChange, absolutePagination),
    [afterPageChange, absolutePagination],
  )

  return (
    <Wrapper
      {...otherProps}
      extraPagination={newExtraPagination}
      onComplete={handleOnComplete}
      onBack={handleOnBack}
      onPageChange={handleOnPageChange}
      beforePageChange={handleBeforePageChange}
      afterPageChange={handleAfterPageChange}
    >
      {children}
    </Wrapper>
  )
}

AbsoluteWrapper.displayName = 'Paginator.Nested.AbsoluteWrapper'

export default AbsoluteWrapper
