import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { BrowserRouter, Link } from 'react-router-dom'

import RouterContext from './RouterContext'
import usePageView from './usePageView'

const InnerRouter = ({ enablePageView: enabled, children }: any) => {
  usePageView(enabled)

  return children
}

export interface RouterProps {
  children?: React.ReactNode
  enablePageView?: boolean
  linkComponent?: React.ReactElement
}

const Router = ({ linkComponent, enablePageView, children, ...otherProps }: RouterProps) => (
  <BrowserRouter {...otherProps}>
    <RouterContext.Provider value={linkComponent}>
      <InnerRouter enablePageView={enablePageView}>{children}</InnerRouter>
    </RouterContext.Provider>
  </BrowserRouter>
)

Router.defaultProps = {
  children: undefined,
  enablePageView: false,
  linkComponent: Link,
}

Router.displayName = 'ApplicationWrapper.Plugins.Router'

export default Router
