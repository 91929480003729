import { formatResponseData } from './formatData'

const handleError = (error: any, options = {}) => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'onError' does not exist on type '{}'.
    onError,
    // @ts-expect-error TS(2339) FIXME: Property 'onClientError' does not exist on type '{... Remove this comment to see the full error message
    onClientError,
    // @ts-expect-error TS(2339) FIXME: Property 'onServerError' does not exist on type '{... Remove this comment to see the full error message
    onServerError,
    // @ts-expect-error TS(2339) FIXME: Property 'onRequestError' does not exist on type '... Remove this comment to see the full error message
    onRequestError,
    // @ts-expect-error TS(2339) FIXME: Property 'onUnauthorizedError' does not exist on t... Remove this comment to see the full error message
    onUnauthorizedError,
    // @ts-expect-error TS(2339) FIXME: Property 'onTimeoutError' does not exist on type '... Remove this comment to see the full error message
    onTimeoutError,
    // @ts-expect-error TS(2339) FIXME: Property 'ignore' does not exist on type '{}'.
    ignore = {},
  } = options

  const {
    client: ignoreClientErrors = false,
    server: ignoreServerErrors = false,
    unauthorized: ignoreUnauthorizedErrors = false,
    timeout: ignoreTimeoutErrors = false,
  } = ignore

  if (onError) onError(error)

  if (!error.isAxiosError || !error.response) {
    if (onRequestError) onRequestError()
    throw new Error(error.name, { cause: error })
  }

  const { response, code } = error
  const { data, status } = response

  if (code === 'ECONNABORTED' && !ignoreServerErrors && onRequestError) onRequestError(error)

  const handleClientError = () => {
    if (ignoreClientErrors) return

    if (onClientError) onClientError(error)
    else if (onRequestError) onRequestError(error)
  }

  const handleServerError = () => {
    if (ignoreServerErrors) return

    if (onServerError) onServerError(error)
    else if (onRequestError) onRequestError(error)
  }

  const handleUnauthorizedError = () => {
    if (ignoreUnauthorizedErrors) return

    if (onUnauthorizedError) onUnauthorizedError(error)
    else handleClientError()
  }

  const handleTimeoutError = () => {
    if (ignoreTimeoutErrors) return

    if (onTimeoutError) onTimeoutError(error)
    else handleServerError()
  }

  if (status === 401) handleUnauthorizedError()
  else if (status >= 400 && status < 500) handleClientError()

  if (code === 'ECONNABORTED') handleTimeoutError()
  else if (status >= 500) handleServerError()

  return {
    ...response,
    data: formatResponseData(data),
    success: false,
    error,
  }
}

export default handleError
