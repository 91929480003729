import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectDisplayError } from '../../../../store/api/errorSlice'
import ErrorContext from '../../../ErrorBoundary/ErrorContext'

const useErrorBoundary = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'onError' does not exist on type 'unknown... Remove this comment to see the full error message
  const { onError } = useContext(ErrorContext) || {}
  const displayError = useSelector(selectDisplayError)

  useEffect(() => {
    if (displayError && onError) onError()
  }, [displayError, onError])
}

export default useErrorBoundary
