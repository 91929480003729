import classNames from 'classnames'
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Eye from 'react-feather/dist/icons/eye'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import EyeOff from 'react-feather/dist/icons/eye-off'

import FormControl from './Form/Control'
import Icon from './Icon'

export interface Props {
  isInvalid?: boolean
}

const PasswordInput = React.forwardRef<any, Props>(({ isInvalid, ...otherProps }, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleToggleShow = () => setShowPassword(!showPassword)

  return (
    <InputGroup className={classNames('mb-3', { 'is-invalid': isInvalid })}>
      <FormControl
        ref={ref}
        isInvalid={isInvalid}
        {...otherProps}
        // @ts-expect-error TS(2322) FIXME: Type '{ type: string; ref: Ref<any>; isInvalid: bo... Remove this comment to see the full error message
        type={showPassword ? 'text' : 'password'}
      />
      <InputGroup.Append>
        <Button
          variant="link"
          onClick={handleToggleShow}
          className={classNames({ 'text-danger': isInvalid }, 'link-dark')}
          // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'.
          tabIndex="-1"
        >
          <Icon className="input-icon" icon={showPassword ? EyeOff : Eye} />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  )
})

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
PasswordInput.displayName = 'PasswordInput'

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
PasswordInput.defaultProps = {
  isInvalid: undefined,
}

export default PasswordInput
