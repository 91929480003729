import FullScreenBackground from '@nord/ui/src/components/FullScreenBackground'
import FullScreenSection from '@nord/ui/src/components/FullScreenSection'
import useIsSignedIn from '@nord/ui/src/hooks/useIsSignedIn'
import useQuery from '@nord/ui/src/hooks/useQuery'
import { selectInitialLoading } from '@nord/ui/src/store/current/loading'
import {
  setCurrentPortfolioId,
  selectCurrentPortfolioId,
} from '@nord/ui/src/store/current/portfolioId'
import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import { useDispatch, useSelector } from 'react-redux'

import usePortfolioId from '../../hooks/usePortfolioId'
import AuthPages from '../../pages/AuthPages'
import NotAuthPages from '../../pages/NotAuthPages'
import NoPortfolioIdPage from '../../pages/NotAuthPages/NoPortfolioIdPage'
import Header from '../Header'
import LoadingPage from '../LoadingPage'

const PagesWrapper = () => {
  const dispatch = useDispatch()
  const isSignedIn = useIsSignedIn()
  const routeId = usePortfolioId()

  const isLoading = useSelector(selectInitialLoading)
  const portfolioId = useSelector(selectCurrentPortfolioId)

  const queryPortfolioId = parseInt(useQuery('portfolioId'), 10)

  useEffect(() => {
    if (routeId) dispatch(setCurrentPortfolioId(routeId))
    if (queryPortfolioId) dispatch(setCurrentPortfolioId(queryPortfolioId))
  }, [routeId, queryPortfolioId, dispatch])

  let content
  if (isLoading) content = <LoadingPage />
  else if (!portfolioId) content = <NoPortfolioIdPage />
  else if (isSignedIn) content = <AuthPages />
  else content = <NotAuthPages />

  return (
    <FullScreenSection topSection>
      <Header />
      <Container className="mb-5" fluid>
        {content}
      </Container>
    </FullScreenSection>
  )
}

export default PagesWrapper
