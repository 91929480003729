export const findContainer = (id: any, items: any) => {
  if (id in items) return id

  return Object.keys(items).find((key) => items[key].includes(id))
}

export const findContainers = ({ activeId, overId, items }: any) => ({
  activeContainer: findContainer(activeId, items),
  overContainer: findContainer(overId, items),
})

export const getDraggingData = ({ activeId, overId, items }: any) => {
  const { activeContainer, overContainer } = findContainers({
    activeId,
    overId,
    items,
  })

  const activeItems = items[activeContainer]
  const overItems = items[overContainer]

  const activeIndex = activeItems.indexOf(activeId)
  const overIndex = overItems.indexOf(overId)

  return {
    activeContainer,
    overContainer,
    activeIndex,
    overIndex,
    activeItems,
    overItems,
  }
}

export const activeIsBelowOver = ({ active, over }: any) =>
  over &&
  active.rect.current.translated &&
  active.rect.current.translated.top > over.rect.top + over.rect.height

export const calculateNewIndex = ({ active, over, overIndex }: any) => {
  const isBelowOverItem = activeIsBelowOver({ active, over })
  const modifier = isBelowOverItem ? 1 : 0
  const overContainerIsEmpty = overIndex < 0 // overIndex is -1 when overItems is empty

  return overContainerIsEmpty ? 0 : overIndex + modifier
}
