import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { numberToPercent } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'

import usePortfolioId from '../../hooks/usePortfolioId'

import PortfolioAllocationChart from './PortfolioAllocationChart'

const ChartOverview = () => {
  const portfolioId = usePortfolioId()
  // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
  const { riskScore, esg, simple } = useObjectSelector(selectCurrentPortfolio)

  const getDefaultPortfolioAllocation = useApi('/allocation', {
    method: 'GET',
  })

  const { loading: loadingDefaultPortfolioAllocation, data: defaultPortfolioAllocation } =
    useApiRequest(getDefaultPortfolioAllocation, {
      autoCall: !!riskScore,
      payload: { esg, bondsOnly: true, riskScore, simple },
    })

  const getCurrentPortfolioAllocation = useApi(`/portfolios/${portfolioId}/allocation`, {
    method: 'GET',
    withCredentials: true,
  })

  const { loading: loadingCurrentPortfolioAllocation, data: currentPortfolioAllocation } =
    useApiRequest(getCurrentPortfolioAllocation, {
      autoCall: !!riskScore,
      payload: {
        bondsOnly: true,
      },
    })

  return (
    <div className="d-flex justify-content-around">
      <div className="align-items-center">
        <Text as="p" align="center" className="mb-0">
          Din oprindelige portefølje
        </Text>
        {loadingDefaultPortfolioAllocation ? (
          <Loader className="text-center font-size-xl" />
        ) : (
          <>
            <PortfolioAllocationChart data={defaultPortfolioAllocation} />
            <div className="d-flex justify-content-center" data-cy="defaultAllocation">
              {defaultPortfolioAllocation.map(({ value, label }: any) => (
                <Text as="span" transform="capitalize" align="center" className="pr-2">
                  {numberToPercent(value, {
                    minimumFractionDigits: 0,
                  })}{' '}
                  {label}
                </Text>
              ))}
            </div>
          </>
        )}
      </div>
      <div className="justify-content-center">
        <Text as="p" align="center" className="mb-0">
          Din nuværende portefølje
        </Text>
        {loadingCurrentPortfolioAllocation ? (
          <div className="text-center font-size-xl">
            <Loader />
          </div>
        ) : (
          <>
            <PortfolioAllocationChart data={currentPortfolioAllocation} />
            <div className="d-flex justify-content-center" data-cy="currentAllocation">
              {currentPortfolioAllocation.map(({ value, label }: any) => (
                <Text as="span" transform="capitalize" align="center" className="pr-2">
                  {numberToPercent(value, {
                    minimumFractionDigits: 0,
                  })}{' '}
                  {label}
                </Text>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ChartOverview
