import classNames from 'classnames'
import { capitalize } from 'lodash'
import React from 'react'
import { Button } from 'react-bootstrap'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import RefreshCW from 'react-feather/dist/icons/refresh-cw'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import X from 'react-feather/dist/icons/x'

import humanFileSize from '../../utilities/humanFileSize'
import Circle from '../Circle'
import Icon from '../Icon'
import CircleNotch from '../Icon/customIcons/CircleNotch'

import styles from './index.module.scss'

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
const itemHeight = parseFloat(styles.itemHeight, 10)

const mainStatusTexts = {
  uploading: 'Uploader...',
  uploaded: 'Upload færdig',
  canceled: 'Upload annulleret',
  error: 'Fejl ved upload',
}

const subStatusTexts = {
  uploading: 'tryk for at annullere',
  uploaded: 'tryk for at fortryde',
  canceled: 'tryk for at prøve igen',
  error: 'tryk for at prøve igen',
}

const statusIcons = {
  uploading: CircleNotch,
  uploaded: X,
  canceled: RefreshCW,
  error: RefreshCW,
}

export interface Props {
  file: any
  onRemove: (...args: any[]) => any
  onRetryUpload: (...args: any[]) => any
  index: number
  stagger: number
}

const FileItem = ({ file, onRemove, onRetryUpload, index, stagger }: Props) => {
  const handleFileAction = (currentFile: any) => {
    switch (currentFile.status) {
      case 'uploading':
      case 'uploaded':
        onRemove(currentFile)
        break
      case 'canceled':
      case 'error':
        onRetryUpload(currentFile)
        break
      default:
        break
    }
  }

  return (
    <li
      className={styles.fileTransition}
      style={{
        transitionDelay: `${stagger}ms`,
        top: `${index * itemHeight}em`,
      }}
    >
      <div className={classNames(styles.file, styles[`fileStatus${capitalize(file.status)}`])}>
        <div className={styles.fileInfo}>
          <span className={styles.fileInfoMain}>{file.name}</span>
          <span className={styles.fileInfoSub}>{humanFileSize(file.size)}</span>
        </div>
        <div className={styles.fileStatus}>
          {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <span className={styles.fileStatusMain}>{mainStatusTexts[file.status]}</span>
          {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <span className={styles.fileStatusSub}>{subStatusTexts[file.status]}</span>
        </div>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; size: string; variant: ... Remove this comment to see the full error message */}
        <Circle size="xs" variant="dark" filled borderless>
          <Button
            variant="dark"
            className="btn-filled"
            onClick={() => handleFileAction(file)}
            title="Fjern"
          >
            {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
            <Icon icon={statusIcons[file.status]} spin={file.status === 'uploading'} />
          </Button>
        </Circle>
      </div>
    </li>
  )
}

export default FileItem
