import { useState, useCallback } from 'react'

import { getConfig } from '../../../configuration'
import useApi from '../../../hooks/useApi'

const otherDocumentationFolder = getConfig('documentationFolders.other.key')

const useStoreDocumentsApi = (email: any, options = {}) => {
  // @ts-expect-error TS(2339) FIXME: Property 'onError' does not exist on type '{}'.
  const { onError } = options
  const [failed, setFailed] = useState(false)
  const handleError = useCallback(
    (error: any) => {
      if (onError) onError(error)
      setFailed(true)
    },
    [onError],
  )
  const storeDocument = useApi('/uploads/store', {
    ...options,
    method: 'POST',
    errorHandling: {
      onError: handleError,
    },
  })
  const updateDocuments = useApi('/onboarding/documents', {
    ...options,
    method: 'PUT',
    withCredentials: true,
    errorHandling: {
      onError: handleError,
    },
  })
  const [loading, setLoading] = useState(false)

  const storeDocuments = useCallback(
    async (files: any) => {
      setFailed(false)
      setLoading(true)

      await Promise.all(
        Object.keys(files).map((fileName) =>
          storeDocument({
            name: `/${email}/temp/${fileName}`,
            path: `/${email}/${otherDocumentationFolder}`,
          }),
        ),
      )
      const response = await updateDocuments({ documentType: 'documentation' })

      setLoading(false)

      return response
    },
    [email, storeDocument, updateDocuments],
  )

  return {
    loading,
    storeDocuments,
    failed,
  }
}

export default useStoreDocumentsApi
