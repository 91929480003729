import { useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import React, { useContext } from 'react'
import Alert from 'react-bootstrap/Alert'

import FormContext from './FormContext'
import { formatErrorsForAlert } from './utilities'

export interface Props {
  errors?: string | any[]
}

const ErrorAlert = ({ errors, ...otherProps }: Props) => {
  const {
    errors: { base: baseError },
  } = useFormikContext<any>()
  // @ts-expect-error TS(2339) FIXME: Property 'error' does not exist on type 'unknown'.
  const { error } = useContext(FormContext)

  let content

  if (baseError) content = baseError
  if (error) content = error
  if (!isEmpty(errors)) content = errors

  if (!content) return null

  return (
    <Alert variant="danger" className="text-center" {...otherProps}>
      {formatErrorsForAlert(content)}
    </Alert>
  )
}

ErrorAlert.defaultProps = {
  errors: undefined,
}

export default ErrorAlert
