import Card from '@nord/ui/src/components/Card'
import Loader from '@nord/ui/src/components/Loader'
import React from 'react'

import Page from './Page'

const LoadingPage = () => (
  <Page>
    <Card>
      <Card.Body>
        <Loader className="font-size-xl text-center" />
      </Card.Body>
    </Card>
  </Page>
)

export default LoadingPage
