import classNames from 'classnames'
import React from 'react'
import { Nav } from 'react-bootstrap'

import { getConfig } from '../../configuration'
import NordLogo from '../NordLogo'

import styles from './Logo.module.scss'

const websiteFrontpageUrl = getConfig('urls.website.frontpage')

export interface Props {
  mobile?: boolean
  variant?: 'dark' | 'light'
}

const Logo = ({ mobile, variant, ...otherProps }: Props) => (
  <div
    className={classNames({
      [styles.logoMobileContainer]: mobile,
    })}
  >
    <Nav.Link href={websiteFrontpageUrl} id="logo" className={`link-${variant}`}>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ alt: string; }' is not assignable to type ... Remove this comment to see the full error message */}
      <NordLogo alt="Norm logo" {...otherProps} />
    </Nav.Link>
  </div>
)

Logo.defaultProps = {
  mobile: undefined,
  variant: 'dark',
}

export default Logo
