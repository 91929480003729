import axios from 'axios'
import { useState, useCallback } from 'react'

import useApi from '../../../hooks/useApi'

const useUploadDocumentApi = (email: any, options = {}) => {
  // @ts-expect-error TS(2339) FIXME: Property 'onError' does not exist on type '{}'.
  const { onError } = options

  const [failed, setFailed] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleError = useCallback(
    (error: any) => {
      if (onError) onError(error)
      setFailed(true)
    },
    [onError],
  )

  const getSignedUrl = useApi('/uploads/sign', {
    ...options,
    errorHandling: {
      onError: handleError,
    },
  })

  const uploadDocument = useCallback(
    async (file: any) => {
      setFailed(false)
      setLoading(true)

      const encodedEmail = encodeURIComponent(email)
      const encodedFileName = encodeURIComponent(file.name)
      const encodedFileType = encodeURIComponent(file.type)

      const {
        data: { signedUrl },
      } = await getSignedUrl({
        path: `/${encodedEmail}/temp`,
        name: encodedFileName,
        contentType: encodedFileType,
      })

      const response = await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
          'x-amz-acl': 'private',
        },
      })

      setLoading(false)

      return response
    },
    [email, getSignedUrl],
  )

  return {
    uploadDocument,
    loading,
    failed,
  }
}

export default useUploadDocumentApi
