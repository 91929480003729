import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import transform from 'lodash/transform'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { getConfig } from '../../../configuration'
import { requireAtLeastOneOf } from '../../../utilities/propTypes'
import Loader from '../../Loader'
import Text from '../../Text'

const documentationFolders = getConfig('documentationFolders')

const documentationFolderNamesByKey = transform(
  documentationFolders,
  (result, documentationFolder) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    result[documentationFolder.key] = documentationFolder.name
  },
  {},
)

export interface Props {
  documents?: {
    filename: string
    url: string
    folder: string
  }[]
  loading?: any // TODO: requireAtLeastOneOf(PropTypes.bool, 'documents')
}

const List = ({ documents, loading }: Props) => {
  if (loading && !documents) return <Loader className="text-center" />

  return isEmpty(documents) ? (
    <Text as="p" align="center">
      Der er ingen dokumenter at vise.
    </Text>
  ) : (
    <>
      <Text as="p" align="center">
        Dette er alle dine dokumenter, der er uploadet i forbindelse med oprettelse mm. Du kan altid
        anmode om at få dine dokumenter slettet ved at kontakte os.
      </Text>
      <ul className="list-unstyled">
        <Row as="li" className="border-bottom">
          <Col>
            <Text as="div" align="left" transform="uppercase" variant="secondary">
              Titel
            </Text>
          </Col>
          <Col>
            <Text as="div" align="right" transform="uppercase" variant="secondary">
              Type
            </Text>
          </Col>
        </Row>
        {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
        {documents.map(({ filename, folder, url }) => (
          <Row key={url} as="li" className="border-bottom py-3">
            <Col className="overflow-hidden text-truncate d-flex flex-row align-items-center">
              <Text
                as="a"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                align="left"
                className={classNames('mb-0', { disabled: loading })}
              >
                {filename}
              </Text>
              {loading && <Loader className="ml-2" />}
            </Col>
            <Col>
              <Text as="div" align="right" className="mb-0">
                {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                {documentationFolderNamesByKey[folder] || folder}
              </Text>
            </Col>
          </Row>
        ))}
      </ul>
    </>
  )
}

List.displayName = 'DocumentsForm.List'

List.defaultProps = {
  documents: undefined,
  loading: undefined,
}

export default List
