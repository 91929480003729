import classNames from 'classnames'
import React from 'react'
import { CSSTransition } from 'react-transition-group'

import extractTransitionClasses from '../../utilities/extractTransitionClasses'

import styles from './DropIn.module.scss'

const timeout = parseInt(styles.timeout, 10)

export interface Props {
  children: React.ReactNode
  in?: boolean
  className?: string
}

const DropIn = ({ children, in: show, className, ...otherProps }: Props) => {
  const child = React.Children.only(children)

  return (
    <CSSTransition
      classNames={extractTransitionClasses({
        styles,
        className: 'dropIn',
        appearKey: 'enter',
      })}
      in={show}
      timeout={timeout}
      appear
      {...otherProps}
    >
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      {React.cloneElement(child, {
        // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
        className: classNames(child.props.className, styles.dropIn, className),
      })}
    </CSSTransition>
  )
}

DropIn.defaultProps = {
  className: undefined,
  in: false,
}

export default DropIn
