/* global Intercom */

import Cookies from 'js-cookie'

import { snakeCaseKeys } from './deepIterate'

const formatUpdateData = ({
  firstName,
  lastName,
  email,
  createdAt,
  phone,
  phonePrefix,
  salesState,
  id,
  onboardingState,
  intercomIdentityVerificationHash,
  expectedInvestment,
  advisedRiskScore,
}: any) => {
  const utm = Cookies.getJSON('utm') || {}
  const { source, medium, campaign } = utm
  const unixTimestamp = Math.round(new Date(createdAt).getTime() / 1000)

  /* eslint-disable camelcase */
  return {
    user_id: id,
    user_hash: intercomIdentityVerificationHash,
    email,
    name: `${firstName} ${lastName}`,
    phone_from_web: `+${phonePrefix} ${phone}`,
    expected_investment_web: expectedInvestment,
    advised_risk_score: advisedRiskScore,
    created_at: unixTimestamp,
    utm_source: source,
    utm_medium: medium,
    utm_campaign: campaign,
    state: salesState,
    onboarding_status: onboardingState,
  }
  /* eslint-enable camelcase */
}

const formatData = (event: any, data: any) => {
  switch (event) {
    case 'update':
      return formatUpdateData(data)
    case 'trackEvent':
      return data
    default:
      return snakeCaseKeys(data)
  }
}

const callIntercom = (event: any, data: any) => {
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'Intercom'.
  if (typeof Intercom === 'undefined') return

  // @ts-expect-error TS(2304) FIXME: Cannot find name 'Intercom'.
  Intercom(event, formatData(event, data))
}

export default callIntercom
