import Card from '@nord/ui/src/components/Card'
import Text from '@nord/ui/src/components/Text'
import React from 'react'

import Page from '../../components/Page'

const NoPortfolioIdPage = () => (
  <Page>
    <Card>
      <Card.Body>
        <Text as="h3" align="center">
          Vi kunne ikke finde din portefølje
        </Text>
        <Text as="p" align="center">
          Vi har sendt dig en email angående rebalancering af din portefølje. Du skal bruge linket i
          emailen og logge ind, før at vi kan rebalancere din portefølje.
        </Text>
        <Text as="p" align="center">
          Har du fulgt linket i emailen, og ser du stadig denne side, så kontakt os med det samme,
          så hjælper vi dig videre.
        </Text>
      </Card.Body>
    </Card>
  </Page>
)

export default NoPortfolioIdPage
