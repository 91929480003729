import { useField } from 'formik'
import React, { useMemo } from 'react'

import chainEventHandler from '../../../utilities/chainEventHandler'
import { useUploadDocumentApi } from '../../DocumentsForm'
import FileDropzone, { formatFilesToObject } from '../../FileDropzone'
import FileInput from '../../FileInput'

export interface Props {
  dropzone?: boolean
  email: string
  name: string
  onChange?: (...args: any[]) => any
}

const File = ({ name, onChange, dropzone, email, ...otherProps }: Props) => {
  const [_field, _meta, { setValue }] = useField(name)
  const { uploadDocument } = useUploadDocumentApi(email)

  const handleChange = useMemo(
    () =>
      chainEventHandler((event: any) => {
        const {
          target: { files },
        } = event

        const formattedFiles = formatFilesToObject(Array.from(files))

        setValue(formattedFiles)
      }, onChange),
    [onChange, setValue],
  )

  const handleDropzoneChange = (newFiles: any) => {
    setValue(newFiles)
  }

  if (dropzone)
    return (
      <FileDropzone
        {...otherProps}
        // @ts-expect-error TS(2322) FIXME: Type '{ name: string; upload: (file: any) => Promi... Remove this comment to see the full error message
        name={name}
        upload={uploadDocument}
        onValueChange={handleDropzoneChange}
      />
    )

  // @ts-expect-error TS(2741) FIXME: Property 'upload' is missing in type '{ onChange: ... Remove this comment to see the full error message
  return <FileInput {...otherProps} onChange={handleChange} name={name} />
}

File.defaultProps = {
  dropzone: undefined,
  onChange: undefined,
}

File.displayName = 'Form.Fields.File'
export default File
