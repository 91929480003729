import classNames from 'classnames'
import { lowerCase } from 'lodash'
import React from 'react'

import 'flag-icons/sass/flag-icons.scss'

export interface Props {
  countryCode: string
  className?: string
}

const FlagIcon = ({ className, countryCode, ...otherProps }: Props) => (
  <span className={classNames('fi', `fi-${lowerCase(countryCode)}`, className)} {...otherProps} />
)

FlagIcon.defaultProps = {
  className: undefined,
}

export default FlagIcon
