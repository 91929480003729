import { createSlice } from '@reduxjs/toolkit'

import handleError from '../../hooks/useApi/handleError'

import apiRequestCall from './apiRequestCall'

const initialState = {
  displayError: false,
  handleUnauthorizedError: false,
}

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {},
  extraReducers: {
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [apiRequestCall.rejected]: (state: any, { payload: error, meta }: any) => {
      const { errorHandling } = meta

      handleError(error, {
        ...errorHandling,
        onRequestError: () => {
          state.displayError = true
        },
        onUnauthorizedError: () => {
          state.handleUnauthorizedError = true
        },
      })
    },
  },
})

// @ts-expect-error TS(2339) FIXME: Property 'displayError' does not exist on type 'Ca... Remove this comment to see the full error message
export const { displayError, handleUnauthorizedError } = errorSlice.actions

export const selectDisplayError = (state: any) => state.error.displayError
export const selectHandleUnauthorizedError = (state: any) => state.error.handleUnauthorizedError

export const errorReducer = errorSlice.reducer
