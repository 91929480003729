import identity from 'lodash/identity'
import type { ChangeEvent } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import { FormControl } from 'react-bootstrap'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import TextMaskInput from 'react-text-mask'

import chainEventHandler from '../utilities/chainEventHandler'
// import { requireOneOf } from '../utilities/propTypes'

// @ts-expect-error TS(7031) FIXME: Binding element 'Component' implicitly has an 'any... Remove this comment to see the full error message
const defaultRender = (ref: any, { as: Component, ...otherProps }) => (
  <Component ref={ref} {...otherProps} />
)

export interface MaskedInputProps {
  className?: string
  as?: React.ElementType
  createMask?: (...args: any[]) => any
  createPipe?: (...args: any[]) => any
  defaultValue?: React.ReactNode
  formatter?: (...args: any[]) => any
  mask?: any // TODO: requireOneOf( PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])), 'createMask' )
  maskOptions?: any
  onBlur?: (...args: any[]) => any
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onValueChange?: (value: number) => void
  parser?: (...args: any[]) => any
  pipe?: (...args: any[]) => any
  pipeOptions?: any
  render?: (...args: any[]) => any
  value?: React.ReactNode
}

const MaskedInput = ({
  value,
  defaultValue,
  onChange,
  onValueChange,
  onBlur,
  formatter = identity,
  parser = identity,
  pipe,
  createPipe,
  pipeOptions,
  mask,
  createMask,
  maskOptions,
  ...otherProps
}: MaskedInputProps) => {
  const [internalValues, setInternalValues] = useState(() => ({
    parsedValue: defaultValue || value,
    inputValue: formatter(defaultValue || value),
  }))

  useEffect(() => {
    // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
    if (internalValues.parsedValue.toString() === value.toString()) return

    setInternalValues({ parsedValue: value, inputValue: formatter(value) })
  }, [value, formatter, internalValues.parsedValue])

  const handleValueChange = chainEventHandler((inputValue: string) => {
    const parsedValue = parser(inputValue)
    setInternalValues({ parsedValue, inputValue })

    return parsedValue
  }, onValueChange)

  const handleChange = chainEventHandler((event: ChangeEvent<HTMLInputElement>) => {
    handleValueChange(event.target.value)
  }, onChange)

  const handleBlur = chainEventHandler(onBlur)

  const memoizedPipe = useMemo(
    () => createPipe && createPipe(pipeOptions),
    [createPipe, pipeOptions],
  )

  const memoizedMask = useMemo(
    () => createMask && createMask(maskOptions),
    [createMask, maskOptions],
  )

  const formattedDefaultValue = useMemo(() => formatter(defaultValue), [formatter, defaultValue])

  return (
    <TextMaskInput
      value={internalValues.inputValue}
      defaultValue={formattedDefaultValue}
      onChange={handleChange}
      onBlur={handleBlur}
      pipe={pipe || memoizedPipe}
      mask={mask || memoizedMask}
      {...otherProps}
    />
  )
}

MaskedInput.defaultProps = {
  as: FormControl,
  defaultValue: '',
  render: defaultRender,
  value: '',
}

export default MaskedInput
