import React from 'react'
import { useDispatch } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory, Link } from 'react-router-dom'

import { signOutUser } from '../../store/current/user'

export interface Props {
  as?: React.ReactElement
  children?: React.ReactNode
  onSignOut?: (...args: any[]) => any
}

const LogOutButton = ({ children, onSignOut, as: Component, ...otherProps }: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleSignOut = async (event: any) => {
    event.preventDefault()

    await dispatch(signOutUser())

    if (onSignOut) onSignOut()
    else history.push('/brugere/logind')
  }

  return (
    // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
    <Component onClick={handleSignOut} {...otherProps}>
      {children}
    </Component>
  )
}

LogOutButton.defaultProps = {
  as: Link,
  children: 'Log ud',
  onSignOut: undefined,
}

export default LogOutButton
