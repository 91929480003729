import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Text from '../../Text'

export interface Props {
  question: string
  questionIndicator: number
}

const QuestionField = ({ questionIndicator, question }: Props) => (
  <Row>
    <Col>
      <Text as="h3" size="xxl" className="mt-2 mb-4" data-cy="section-title">
        {questionIndicator}. {question}
      </Text>
    </Col>
  </Row>
)

export default QuestionField
