import { useFormikContext } from 'formik'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import useApi, { useApiRequest } from '../../hooks/useApi'
import Form from '../Form'
import IconButton from '../IconButton'

const alertTypes = {
  emailNotFound: {
    type: 'Email not found',
    variant: 'danger',
    message: 'E-mail ikke fundet.',
  },
  passwordCreated: (email: string) => ({
    type: 'Password created',
    variant: 'success',
    message: `Du mangler at vælge en adgangskode før du kan forsætte oprettelsen. Vi har sendt en e-mail til ${email}, med et link til at oprette din adgangskode.`,
  }),
}

export interface Props {
  alert: Partial<{ type: string }>
  allowLogin: boolean
  onChangeAlert(alert: Partial<{ type: string; variant: string; message: string }>): void
  onChangeAllowLogin(arg: boolean): void
}

const EmailInput = forwardRef<unknown, Props>(
  ({ alert, allowLogin, onChangeAlert, onChangeAllowLogin }, ref) => {
    const {
      values: { username: email },
    } = useFormikContext<{ username: string }>()
    const fieldRef = useRef()

    const checkEmail = useApi(`/users/emails/${email}`, {
      method: 'GET',
      errorHandling: {
        ignore: {
          client: true,
        },
      },
    })

    const createPassword = useApi('/users/password', {
      method: 'POST',
    })

    const { request: callEmailCheck, loading: loadingEmailCheck } = useApiRequest(checkEmail)

    const { request: callCreatePassword, loading: loadingCreatePassword } = useApiRequest(
      createPassword,
      {
        payload: {
          user: { email },
        },
      },
    )

    const handleEmailCheck = async () => {
      const { data, success } = await callEmailCheck()

      if (!success) {
        onChangeAlert(alertTypes.emailNotFound)

        onChangeAllowLogin(false)

        return
      }

      const { hasPassword } = data

      if (hasPassword) {
        onChangeAlert({})
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        fieldRef.current.blur()
        onChangeAllowLogin(true)

        return
      }

      const { success: createPasswordSuccess } = await callCreatePassword()

      if (createPasswordSuccess) {
        onChangeAlert(alertTypes.passwordCreated(email))
        onChangeAllowLogin(false)
      }
    }

    useImperativeHandle(ref, () => ({
      handleEmailCheck,
    }))

    const handleBlur = () => {
      if (allowLogin) handleEmailCheck()
    }

    if (alert.type === 'Password created') return null

    return (
      <>
        <Form.FieldGroup loading={loadingEmailCheck !== loadingCreatePassword} name="username">
          <Form.Field
            // @ts-expect-error TS(2322) FIXME: Type '{ disabled: boolean; onBlur: () => void; ref... Remove this comment to see the full error message
            disabled={loadingEmailCheck !== loadingCreatePassword}
            onBlur={handleBlur}
            ref={fieldRef}
            autoFocus={!allowLogin}
          />
        </Form.FieldGroup>
        {!allowLogin && (
          <div className="d-flex justify-content-center">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; as: (pr... Remove this comment to see the full error message */}
            <Form.SubmitButton variant="primary" as={IconButton} icon={ArrowRight}>
              Næste
            </Form.SubmitButton>
          </div>
        )}
      </>
    )
  },
)

export default EmailInput
