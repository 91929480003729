// import { childrenOf } from 'airbnb-prop-types'
import { isFunction, isUndefined } from 'lodash'
import React, { useMemo, useRef, useState } from 'react'
import { SwitchTransition, Transition } from 'react-transition-group'

import useToggle from '../hooks/useToggle'
import useWindowEventHandler from '../hooks/useWindowEventHandler'

export const PageSwitchContext = React.createContext({
  active: false,
  firstLoad: true,
})

const ENTER_STATES = ['entering', 'entered']

export interface Props {
  afterPageChange?: (...args: any[]) => any
  appear?: boolean
  beforePageChange?: (...args: any[]) => any
  children: any // TODO: childrenOf(PropTypes.node)
  className?: string
  leave?: boolean
  onPageChange?: (...args: any[]) => any
  pageKey: string | number
  timeout?: number
}

// TODO: Do not start transition on anchor tag links (like #header)
// TODO: Do not scroll to top on page change
// TODO: Scroll to anchor tag links after page change
const PageSwitchTransition = ({
  children,
  pageKey,
  onPageChange,
  beforePageChange,
  afterPageChange,
  appear,
  leave,
  timeout,
  ...otherProps
}: Props) => {
  const child = React.Children.only(children)
  const internalTimeout = timeout || child.props.timeout
  const internalAppear = isUndefined(appear) ? child.props.appear : appear

  const previousPageKeyRef = useRef<string | number>()
  const [appearing, toggleAppearing] = useToggle(internalAppear)
  const [active, setActive] = useState(internalAppear)
  const [leaving, toggleLeaving] = useToggle(false)

  const previousPageKey = previousPageKeyRef.current
  const forwards = previousPageKey === undefined || pageKey >= previousPageKey

  useWindowEventHandler('beforeunload', toggleLeaving)

  const handleBeforePageChange = () => {
    setActive(true)

    if (isFunction(beforePageChange))
      beforePageChange({
        key: pageKey,
      })
  }

  const handleOnPageChange = () => {
    if (isFunction(onPageChange))
      onPageChange({
        key: pageKey,
        previousKey: previousPageKey,
        forwards,
      })
  }

  const handleAfterPageChange = () => {
    // @ts-expect-error TS(2349) FIXME: This expression is not callable.
    if (appearing) toggleAppearing(false)

    if (isFunction(afterPageChange))
      afterPageChange({
        key: pageKey,
        previousKey: previousPageKey,
        forwards,
      })

    setActive(false)
    previousPageKeyRef.current = pageKey
  }

  const pageSwitchValue = useMemo(
    () => ({
      active,
      firstLoad: appearing,
      forwards,
    }),
    [active, appearing, forwards],
  )

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ active: any; firstLoad: boolean | (() => v... Remove this comment to see the full error message
    <PageSwitchContext.Provider value={pageSwitchValue}>
      <SwitchTransition>
        <Transition
          key={leaving ? 'leave' : pageKey}
          onExit={handleBeforePageChange}
          onEnter={handleOnPageChange}
          onEntered={handleAfterPageChange}
          timeout={internalTimeout}
          appear={internalAppear}
          {...otherProps}
        >
          {(state) =>
            React.cloneElement(child, {
              in: ENTER_STATES.includes(state),
            })
          }
        </Transition>
      </SwitchTransition>
    </PageSwitchContext.Provider>
  )
}

PageSwitchTransition.defaultProps = {
  afterPageChange: undefined,
  appear: undefined,
  beforePageChange: undefined,
  className: undefined,
  leave: undefined,
  onPageChange: undefined,
  timeout: undefined,
}

PageSwitchTransition.displayName = 'PageSwitchTransition'

export default PageSwitchTransition
