import { useField } from 'formik'
import React, { useMemo } from 'react'

import chainEventHandler from '../../../utilities/chainEventHandler'
import PhoneInput from '../../PhoneInput'
import Field from '../Field'

const defaultValueObject = Object.freeze({
  code: 'DK',
  prefix: '+45',
  value: '',
})
export const initialValue = Object.freeze({
  phone: defaultValueObject.value,
  phoneCode: defaultValueObject.code,
  phonePrefix: defaultValueObject.prefix,
  phoneObject: defaultValueObject,
})

export interface Props {
  defaultValue?: {
    code?: string
    prefix?: string
    value?: string
  }
  onCodeChange?: (...args: any[]) => any
  onNumberChange?: (...args: any[]) => any
  onPrefixChange?: (...args: any[]) => any
  onValueChange?: (...args: any[]) => any
}

const Phone = ({
  onValueChange,
  onCodeChange,
  onPrefixChange,
  onNumberChange,
  ...otherProps
}: Props) => {
  const [{ value: objectValue }, _objectMeta, { setValue: setObjectValue }] =
    useField('phoneObject')
  const [_codeField, _codeMeta, { setValue: setCodeValue }] = useField('phoneCode')
  const [_prefixField, _prefixMeta, { setValue: setPrefixValue }] = useField('phonePrefix')

  const handleCodeChange = useMemo(
    () =>
      chainEventHandler((newCode: any) => {
        setCodeValue(newCode)

        return newCode
      }, onCodeChange),
    [onCodeChange, setCodeValue],
  )

  const handlePrefixChange = useMemo(
    () =>
      chainEventHandler((newPrefix: any) => {
        setPrefixValue(newPrefix)

        return newPrefix
      }, onPrefixChange),
    [onPrefixChange, setPrefixValue],
  )

  const handleValueChange = useMemo(
    () =>
      chainEventHandler(onValueChange, (newValueObject: any) => {
        setObjectValue(newValueObject)

        return newValueObject.value
      }),
    [onValueChange, setObjectValue],
  )

  return (
    <Field
      {...otherProps}
      name="phone"
      onValueChange={handleValueChange}
      onCodeChange={handleCodeChange}
      onPrefixChange={handlePrefixChange}
      // @ts-expect-error TS(2739) FIXME: Type '{ ({ onChange, onNumberChange, onCodeChange,... Remove this comment to see the full error message
      as={PhoneInput.WithCountrySelect}
      value={objectValue}
    />
  )
}

Phone.initialValue = initialValue

Phone.displayName = 'Form.Fields.Phone'

Phone.defaultProps = {
  defaultValue: defaultValueObject,
  onCodeChange: undefined,
  onNumberChange: undefined,
  onPrefixChange: undefined,
  onValueChange: undefined,
}

export default Phone
