import clamp from 'lodash/clamp'
import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'

import Context from './Context'
import Navigation from './Navigation'
import TransitionWrapper from './TransitionWrapper'
import { calculatePaginationData, getPaginationProperties } from './utilities'

const calculateInitialPageIndex = (initialPageNumber: number | undefined, pageCount: number) =>
  clamp(initialPageNumber || 1, 1, pageCount) - 1

export interface WrapperProps {
  active?: boolean
  children?: React.ReactNode
  containerComponent?: React.ReactNode
  extraPagination?: object
  initialPageNumber?: number
  navigationComponent?: typeof Navigation
  pages?: React.ReactNode[]
  showPagination?: boolean
  transitionComponent?: typeof TransitionWrapper
  afterPageChange?(...args: unknown[]): unknown
  beforePageChange?(...args: unknown[]): unknown
  onBack?(...args: unknown[]): unknown
  onComplete?(...args: unknown[]): unknown
  onPageChange?(...args: unknown[]): unknown
}

const Wrapper = ({
  pages: pagesProp,
  children,
  onComplete,
  onBack,
  onPageChange,
  afterPageChange,
  beforePageChange,
  extraPagination,
  showPagination,
  initialPageNumber,
  active: parentActive = true,
  navigationComponent: NavigationComponent = Navigation,
  containerComponent: ContainerComponent = 'div',
  transitionComponent: TransitionComponent = TransitionWrapper,
  ...otherProps
}: WrapperProps) => {
  const pages = useMemo(() => pagesProp || React.Children.toArray(children), [children, pagesProp])
  const transitionRef = useRef()
  const { length: pageCount } = pages
  const initialPageIndex = calculateInitialPageIndex(initialPageNumber, pageCount)
  const [pageIndex, setPageIndex] = useState({
    current: initialPageIndex,
  })
  const pageIndexRef = useRef(pageIndex)
  pageIndexRef.current = pageIndex
  const paginationProperties = useMemo(
    () =>
      calculatePaginationData({
        pageIndex: pageIndex.current,
        pageCount,
      }),
    [pageCount, pageIndex],
  )
  const { number: pageNumber } = paginationProperties

  useEffect(() => {
    if (initialPageIndex !== 0) setPageIndex({ current: initialPageIndex })
  }, [initialPageIndex])

  const handleTransitionChange = useCallback(() => {
    const { current: currentPageIndex } = pageIndexRef
    // @ts-expect-error TS(2339) FIXME: Property 'next' does not exist on type '{ current:... Remove this comment to see the full error message
    const { current: oldPageIndex, next: newPageIndex } = currentPageIndex
    if (newPageIndex === undefined) return

    if (onPageChange) {
      const forwards = newPageIndex > currentPageIndex.current
      const newPaginationProperties = calculatePaginationData({
        pageIndex: newPageIndex,
        pageCount,
        forwards,
      })

      onPageChange(
        getPaginationProperties(newPaginationProperties),
        getPaginationProperties({
          forwards,
          ...paginationProperties,
        }),
      )
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type '{ current: any; previous: number... Remove this comment to see the full error message
    setPageIndex({ current: newPageIndex, previous: oldPageIndex })
  }, [onPageChange, pageCount, paginationProperties])

  const handleChangePage = useCallback(
    (newPageNumber: number) => {
      const newPageIndex = newPageNumber - 1

      if (newPageIndex < 0 || newPageIndex >= pageCount) return

      const { current: currentPageIndex } = pageIndexRef

      setPageIndex({
        ...currentPageIndex,
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ next: number; previous: number... Remove this comment to see the full error message
        next: newPageIndex,
        previous: currentPageIndex.current,
      })
    },
    [pageCount],
  )

  const handleNextPage = useCallback(() => {
    if (paginationProperties.last) {
      if (onComplete) onComplete()
    } else {
      handleChangePage(pageNumber + 1)
    }
  }, [handleChangePage, onComplete, pageNumber, paginationProperties])

  const handlePreviousPage = useCallback(() => {
    if (paginationProperties.first) {
      if (onBack) onBack()
    } else {
      handleChangePage(pageNumber - 1)
    }
  }, [handleChangePage, onBack, pageNumber, paginationProperties])

  const handleBeforePageChange = () => {
    if (!beforePageChange) return

    const newPaginationData = calculatePaginationData({
      // @ts-expect-error TS(2339) FIXME: Property 'next' does not exist on type '{ current:... Remove this comment to see the full error message
      pageIndex: pageIndexRef.current.next,
      pageCount,
    })

    beforePageChange(
      getPaginationProperties(newPaginationData),
      getPaginationProperties(paginationProperties),
    )
  }

  const handleAfterPageChange = () => {
    if (!afterPageChange) return

    const previousPaginationData = calculatePaginationData({
      // @ts-expect-error TS(2339) FIXME: Property 'previous' does not exist on type '{ curr... Remove this comment to see the full error message
      pageIndex: pageIndexRef.current.previous,
      pageCount,
    })
    const newPaginationData = calculatePaginationData({
      pageIndex: pageIndexRef.current.current,
      pageCount,
    })

    afterPageChange(
      getPaginationProperties(newPaginationData),
      getPaginationProperties(previousPaginationData),
    )
  }

  const paginationData = useMemo(() => {
    // @ts-expect-error TS(2339) FIXME: Property 'enterAnimation' does not exist on type '... Remove this comment to see the full error message
    const { enterAnimation, exitAnimation } = transitionRef.current || {}

    return {
      ...paginationProperties,
      onNext: handleNextPage,
      onPrevious: handlePreviousPage,
      onChange: handleChangePage,
      _pageIndex: pageIndex,
      _enterAnimation: enterAnimation,
      _exitAnimation: exitAnimation,
      _parentActive: parentActive,
      ...extraPagination,
    }
  }, [
    paginationProperties,
    handleNextPage,
    handlePreviousPage,
    handleChangePage,
    pageIndex,
    parentActive,
    extraPagination,
  ])

  const content = pages.map((page, index) =>
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    React.cloneElement(page, {
      pageIndex: index,
      active: parentActive && index === pageIndex.current,
      showPagination,
    }),
  )

  return (
    <Context.Provider value={paginationData}>
      {/* @ts-expect-error TS(2604) FIXME: JSX element type 'ContainerComponent' does not hav... Remove this comment to see the full error message */}
      <ContainerComponent {...otherProps}>
        <TransitionComponent
          ref={transitionRef}
          onTransitionChange={handleTransitionChange}
          beforePageChange={handleBeforePageChange}
          afterPageChange={handleAfterPageChange}
        >
          {/* @ts-expect-error TS(2793) FIXME: No overload matches this call. */}
          {content}
        </TransitionComponent>
        {NavigationComponent && <NavigationComponent />}
      </ContainerComponent>
    </Context.Provider>
  )
}

Wrapper.displayName = 'Paginator.Wrapper'

export default Wrapper
