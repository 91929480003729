import isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect } from 'react'

import loadCountryData from '../../utilities/loadCountryData'
import Select from '../Select'

import Option from './Option'
import SingleValue from './SingleValue'

const defaultOptionFormat = (item: any) => ({
  value: item.code,
  code: item.code,
  label: item.country,
})

const filterOptions = async (options: any, inputValue: any) => {
  const filteredOptions = options.filter((option: any) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase()),
  )

  return filteredOptions
}

const filterExcludedCountries = (excludedCountries: any, countries: any) =>
  countries.map((country: any) => {
    const excluded = excludedCountries.some((countryCode: any) => country.code === countryCode)

    if (excluded) {
      return {
        ...country,
        isDisabled: true,
        label: `${country.label} (ikke muligt i øjeblikket)`,
      }
    }

    return country
  })

const loadOptions = async ({ formatOption, callback, excludedCountries }: any) => {
  const data = await loadCountryData()

  const formattedOptions = data.map((item: any) => formatOption(item))

  const finalOptions = isEmpty(excludedCountries)
    ? formattedOptions
    : filterExcludedCountries(excludedCountries, formattedOptions)

  callback(finalOptions)
}

export interface CountrySelectProps {
  components?: any
  excludedCountries?: string[]
  optionFormat?: (...args: any[]) => any
}

const CountrySelect = ({
  components,
  optionFormat,
  excludedCountries,
  ...otherProps
}: CountrySelectProps) => {
  const [options, setOptions] = useState()

  useEffect(() => {
    loadOptions({
      formatOption: optionFormat,
      callback: setOptions,
      excludedCountries,
    })
  }, [excludedCountries, optionFormat])

  const handleLoadOptions = (inputValue: any) => filterOptions(options, inputValue)

  return (
    <Select
      // @ts-expect-error TS(2322) FIXME: Type '{ options: undefined; components: any; loadO... Remove this comment to see the full error message
      components={{ Option, SingleValue, ...components }}
      loadOptions={handleLoadOptions}
      tabIndex="-1"
      async
      {...otherProps}
      options={options}
    />
  )
}

CountrySelect.defaultProps = {
  components: undefined,
  excludedCountries: [],
  optionFormat: defaultOptionFormat,
}

export default CountrySelect
