// import { childrenOf } from 'airbnb-prop-types'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import useBreakpointHandler from '../hooks/useBreakpointHandler'

import styles from './FullScreenSection.module.scss'
import ViewPortDiv from './ViewPortDiv'

export const HEIGHTS = {
  full: 100,
  large: 75,
  half: 50,
  small: 25,
}

const getBreakpointValues = (height: any, breakpoints: any) => {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const initialResult = { xs: HEIGHTS[height] || height }

  return Object.entries(breakpoints).reduce((result, [breakpoint, breakpointHeight]) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    result[breakpoint] = HEIGHTS[breakpointHeight] || breakpointHeight || result[breakpoint]

    return result
  }, initialResult)
}

const getBreakpointClasses = (height: any, breakpointValues: any) => {
  const initialResult = {
    [`min-vh-${breakpointValues.xs}`]: breakpointValues.xs !== undefined,
  }

  return Object.entries(breakpointValues).reduce((result, [breakpoint, breakpointValue]) => {
    if (breakpoint === 'xs') {
      result[`min-vh-${breakpointValue}`] = breakpointValue !== undefined
    } else {
      result[`min-vh-${breakpoint}-${breakpointValue}`] = breakpointValue !== undefined
    }

    return result
  }, initialResult)
}

export interface FullScreenSectionProps {
  children?: any // TODO: childrenOf(PropTypes.node)
  topSection?: boolean
  className?: string
  height?: any // TODO: heightPropType()
  xs?: any // TODO: heightPropType()
  sm?: any // TODO: heightPropType()
  md?: any // TODO: heightPropType()
  lg?: any // TODO: heightPropType()
  xl?: any // TODO: heightPropType()
  mobileViewPort?: boolean
}

const FullScreenSection = React.forwardRef<any, FullScreenSectionProps>(
  (
    { children, className, topSection, height, xs, sm, md, lg, xl, mobileViewPort, ...otherProps },
    ref,
  ) => {
    const breakpoints = { xs, sm, md, lg, xl }
    const breakpoint = useBreakpointHandler()
    const breakpointValues = getBreakpointValues(height, breakpoints)

    const content = (
      <>
        {topSection && <div className={classNames(styles.buffer, styles.bufferTop)} />}
        <div className={classNames(styles.wrapper, className)}>{children}</div>
        {topSection && <div className={classNames(styles.buffer, styles.bufferBottom)} />}
      </>
    )

    if (mobileViewPort) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const breakpointValue = breakpointValues[breakpoint] || breakpointValues.xs || null

      return (
        <ViewPortDiv
          // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'ReactElem... Remove this comment to see the full error message
          as="section"
          className={classNames(styles.fullScreenSection)}
          ref={ref}
          height={breakpointValue}
          minimum
          {...otherProps}
        >
          {content}
        </ViewPortDiv>
      )
    }

    const cssClasses = getBreakpointClasses(height, breakpointValues)

    return (
      <section
        className={classNames(styles.fullScreenSection, cssClasses)}
        ref={ref}
        {...otherProps}
      >
        {content}
      </section>
    )
  },
)

const heightPropType = () =>
  PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(Object.keys(HEIGHTS))])

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
FullScreenSection.defaultProps = {
  children: undefined,
  className: undefined,
  height: undefined,
  lg: undefined,
  md: undefined,
  mobileViewPort: true,
  sm: undefined,
  topSection: undefined,
  xl: undefined,
  xs: undefined,
}

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
FullScreenSection.displayName = 'FullScreenSection'

export default FullScreenSection
