import debounce from 'lodash/debounce'
import { useRef, useState, useCallback, useMemo, useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

const getMargin = (style: any, side: any) => parseInt(style[`margin${side}`], 10) || 0

const getElementSize = (element: any, contentRect: any, boxSizing: any) => {
  const { width: contentWidth, height: contentHeight } = contentRect

  if (boxSizing === 'content')
    return {
      width: contentWidth,
      height: contentHeight,
    }

  const borderWidth = element.offsetWidth
  const borderHeight = element.offsetHeight

  if (boxSizing === 'border')
    return {
      width: borderWidth,
      height: borderHeight,
      contentWidth,
      contentHeight,
    }

  // @ts-expect-error TS(2774) FIXME: This condition will always return true since this ... Remove this comment to see the full error message
  const style = window.getComputedStyle ? getComputedStyle(element) : element.currentStyle

  let marginWidth = 0 + borderWidth
  let marginHeight = 0 + borderHeight
  marginWidth += getMargin(style, 'Left')
  marginWidth += getMargin(style, 'Right')
  marginHeight += getMargin(style, 'Top')
  marginHeight += getMargin(style, 'Bottom')

  return {
    width: marginWidth,
    height: marginHeight,
    contentWidth,
    contentHeight,
    borderWidth,
    borderHeight,
  }
}

const defaultOptions = {
  boxSizing: 'margin',
}

const useElementSize = (options = {}) => {
  const { boxSizing } = { ...defaultOptions, ...options }
  const observer = useRef()
  const elementRef = useRef()
  const [size, setSize] = useState({})

  const handleResize = useCallback(
    (element: any, contentRect: any) => {
      const newSize = getElementSize(element, contentRect, boxSizing)

      setSize(newSize)
    },
    [boxSizing],
  )

  const debouncedHandleResize = useMemo(
    () => debounce(handleResize, 1000, { leading: true }),
    [handleResize],
  )

  const callbackRef = useCallback((element: any) => {
    const previousElement = elementRef.current
    const currentObserver = observer.current
    elementRef.current = element

    if (!currentObserver || !previousElement) return

    // @ts-expect-error TS(2339) FIXME: Property 'unobserve' does not exist on type 'never... Remove this comment to see the full error message
    currentObserver.unobserve(previousElement)

    if (!element) return

    // @ts-expect-error TS(2339) FIXME: Property 'observe' does not exist on type 'never'.
    currentObserver.observe(element)
  }, [])

  useEffect(() => {
    if (typeof ResizeObserver === 'undefined') return undefined
    const newObserver = new ResizeObserver(([entry]) => {
      const callback = () => {
        const { target, contentRect } = entry
        debouncedHandleResize(target, contentRect)
      }

      if (window.requestAnimationFrame) window.requestAnimationFrame(() => callback())
      else callback()
    })

    // @ts-expect-error TS(2322) FIXME: Type 'ResizeObserver' is not assignable to type 'u... Remove this comment to see the full error message
    observer.current = newObserver

    const element = elementRef.current
    if (element) callbackRef(element)

    return () => newObserver.disconnect()
  }, [callbackRef, debouncedHandleResize])

  return [callbackRef, size]
}

export default useElementSize
