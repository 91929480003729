import { useEffect } from 'react'

import { getConfig } from '../configuration'
import callIntercom from '../utilities/callIntercom'

const intercomId = getConfig('keys.intercomId')
const defaultSessionDuration = 45 * 60 * 1000 // 45 minutes

const useIntercom = (enable = true) => {
  useEffect(() => {
    if (enable)
      callIntercom('boot', {
        appId: intercomId,
        sessionDuration: defaultSessionDuration,
      })
    // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
    else callIntercom('shutdown')
  }, [enable])

  return callIntercom
}

export default useIntercom
