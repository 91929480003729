import baffle, { type BaffleObject } from 'baffle'
import classNames from 'classnames'
import { isFunction, isObject, isString, isArray, trim, isNil } from 'lodash'
import flatten from 'lodash/flatten'
import initial from 'lodash/initial'
import React, { useRef, useEffect } from 'react'
import { Button, type ButtonProps } from 'react-bootstrap'

import chainEventHandler from '../utilities/chainEventHandler'

const BAFFLE_DURATION = 500

// Remove dash as it is breaking
const baffleExclude = [' ', '-', '?', '[', '{', '(']
let baffleCharacters =
  'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz~!@#$%^&*()-+=[]{}|;:,./<>?'
baffleExclude.forEach((excludedCharacter) => {
  baffleCharacters = baffleCharacters.replace(excludedCharacter, '')
})

export const BaffleButtonDefaultAs = 'button' as const
export type BaffleButtonDefaultAsType = typeof BaffleButtonDefaultAs

export interface BaffleButtonOwnProps<E extends React.ElementType> extends ButtonProps {
  as?: E
  filled?: boolean
  rel?: string
  transform?: 'uppercase'
}

export type BaffleButtonProps<E extends React.ElementType> = BaffleButtonOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof BaffleButtonOwnProps<E>>

const BaffleButton = <E extends React.ElementType = React.ElementType>(
  {
    onMouseEnter,
    onMouseLeave,
    children,
    disabled,
    filled,
    className,
    ...buttonProps
  }: BaffleButtonProps<E>,
  parentRef: React.ForwardedRef<HTMLElement>,
) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const baffleObjectRef = useRef<BaffleObject | null>(null)

  useEffect(() => {
    let baffleObject: BaffleObject | null = null
    if (buttonRef.current) baffleObject = baffle(buttonRef.current.querySelectorAll('.inner-text'))
    baffleObjectRef.current = baffleObject

    return () => {
      baffleObjectRef.current = null

      if (baffleObject) {
        baffleObject.stop()
        baffleObject.text((current) => current)
      }
    }
  }, [children, disabled])

  const baffleText = () => {
    if (baffleObjectRef.current && disabled !== true) {
      baffleObjectRef.current.reveal(BAFFLE_DURATION)
    }
  }

  const handleMouseEnter = chainEventHandler(() => baffleText(), onMouseEnter)
  const handleMouseLeave = chainEventHandler(() => baffleText(), onMouseLeave)

  const renderChildren = (childCollection: React.ReactNode) =>
    React.Children.map(childCollection, (child): React.ReactNode => {
      if (isNil(child)) {
        return null
      }

      if (isArray(child)) {
        return renderChildren(childCollection)
      }

      if (isString(child) && trim(child).length > 0) {
        const strings = child
          .split(' ')
          .map((string) => [<span className="inner-text text-nowrap">{string}</span>, ' '])

        return initial(flatten(strings))
      }

      if (React.isValidElement(child) && child.type === React.Fragment) {
        return renderChildren(child.props.children)
      }

      return child
    })

  const setRef = (node: HTMLButtonElement | null) => {
    buttonRef.current = node

    if (isFunction(parentRef)) {
      parentRef(node)
    } else if (isObject(parentRef)) {
      parentRef.current = node
    }
  }

  return (
    // @ts-expect-error TS(2322)
    <Button
      ref={setRef}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      disabled={disabled}
      className={classNames(className, {
        'btn-filled': filled,
      })}
      {...buttonProps}
    >
      {renderChildren(children)}
    </Button>
  )
}

BaffleButton.displayName = 'BaffleButton'

export default React.forwardRef(BaffleButton)
