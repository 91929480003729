import classNames from 'classnames'
import React from 'react'

import { environment } from '../../../../configuration/config'

import styles from './index.module.scss'

const { custom: customEnvironment, name: environmentName } = environment

export interface Props {
  children: React.ReactNode
  className?: string
  position?: 'top' | 'bottom'
}

const EnvironmentBar = ({ children, className, position, ...otherProps }: Props) => {
  if (environmentName === 'production') return children

  return (
    <>
      <div
        className={classNames(styles.environmentBar, className, {
          [styles.positionTop]: position === 'top',
          [styles.positionBottom]: position === 'bottom',
          [styles.development]: environmentName === 'development',
          [styles.staging]: environmentName === 'staging',
          [styles.test]: environmentName === 'test',
          [styles.unknown]: !['development', 'staging', 'test'].includes(environmentName),
        })}
        {...otherProps}
      >
        {customEnvironment}
        {environmentName !== customEnvironment && ` (${environmentName})`}
      </div>
      {children}
    </>
  )
}

EnvironmentBar.defaultProps = {
  className: undefined,
  position: 'top',
}

EnvironmentBar.displayName = 'ApplicationWrapper.Plugins.EnvironmentBar'

export default EnvironmentBar
