import classNames from 'classnames'
import type { ForwardedRef } from 'react'
import React from 'react'

// https://fettblog.eu/typescript-react-generic-forward-refs/
declare module 'react' {
  function forwardRef<T, P = Record<string, unknown>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

type Alignment = 'left' | 'center' | 'right' | 'justify'
type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'
type Transform = 'lowercase' | 'uppercase' | 'capitalize'
type Weight = 'lighter' | 'light' | 'normal' | 'bold' | 'bolder'

type BreakpointPropType = Size | { size: Size; alignment: Alignment }

export const TextDefaultAs = 'span' as const
export type TextDefaultAsType = typeof TextDefaultAs

export interface TextOwnProps<E extends React.ElementType> {
  children?: React.ReactNode
  as?: E
  className?: string
  div?: boolean
  variant?: string // TODO: bootstrapVariant({ extraVariants: ['white', 'muted', 'body', 'black-50', 'white-50'], })
  wrap?: boolean
  truncate?: boolean
  break?: boolean
  weight?: Weight
  italic?: boolean
  monospace?: boolean
  base?: boolean
  hide?: boolean
  reset?: boolean
  removeDecoration?: boolean
  transform?: Transform
  align?: Alignment
  size?: Size
  xs?: BreakpointPropType
  sm?: BreakpointPropType
  md?: BreakpointPropType
  lg?: BreakpointPropType
  xl?: BreakpointPropType
}

export type TextProps<E extends React.ElementType = React.ElementType> = TextOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof TextOwnProps<E>>

const Text = <E extends React.ElementType = TextDefaultAsType>(
  {
    children,
    as,
    className,
    div,
    variant,
    wrap,
    truncate,
    break: breakText,
    weight,
    italic,
    monospace,
    base,
    hide,
    reset,
    removeDecoration,
    transform,
    align,
    size,
    xs,
    sm,
    md,
    lg,
    xl,
    ...otherProps
  }: TextProps<E>,
  ref: ForwardedRef<HTMLElement>,
) => {
  const breakpoints = {
    xs,
    sm,
    md,
    lg,
    xl,
  }
  const cssClasses = {
    [`font-size-${size}`]: size !== undefined,
    [`text-${variant}`]: variant !== undefined,
    [`text-${align}`]: align !== undefined,
    [`text-${transform}`]: transform !== undefined,
    [`font-weight-${weight}`]: weight !== undefined,
    'text-wrap': wrap === true,
    'text-nowrap': wrap === false,
    'text-truncate': !!truncate,
    'text-break': !!breakText,
    'font-italic': !!italic,
    'text-monospace': !!monospace,
    'text-base': !!base,
    'text-hide': !!hide,
    'text-reset': !!reset,
    'text-decoration-none': !!removeDecoration,
  }

  Object.entries(breakpoints).forEach(([breakpoint, breakpointSize]) => {
    if (breakpoint === 'xs') {
      cssClasses[`font-size-${breakpointSize}`] = breakpointSize !== undefined
    } else {
      cssClasses[`font-size-${breakpoint}-${breakpointSize}`] = breakpointSize !== undefined
    }
  })

  const Component = as || TextDefaultAs
  const names = classNames(className, cssClasses) || undefined

  return (
    <Component ref={ref} className={names} {...otherProps}>
      {children}
    </Component>
  )
}

export default React.forwardRef(Text)
